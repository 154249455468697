import { ChangeDetectionStrategy, Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { ComponentFields, ComponentRendering } from '@sitecore-jss/sitecore-jss';
import { SitecoreComponent } from 'components/shared/sitecore';
import { IXMOptions } from 'core/interfaces';
import { CONFIG_TOKEN } from 'core/constants';
import { CimaToken, Cloudinary, WindowReference } from 'core/services';

const SPANISH_PATH: string = 'es.xfinity.com/mobile/learn/';
const ENGLISH_PATH: string = 'www.xfinity.com/mobile/learn/';

@Component({
    selector: 'xm-footer',
    styleUrls: [ './footer.scss' ],
    templateUrl: './footer.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketingFooterComponent extends SitecoreComponent implements OnInit {
    @Input() public rendering: ComponentRendering;

    public pageData: FooterPageData;
    public spanishLink: string;
    public englishLink: string;

    private config: IXMOptions;
    private cimaToken: CimaToken;

    constructor(injector: Injector, cimaToken: CimaToken, @Inject(CONFIG_TOKEN) config: IXMOptions) {
        super(injector);

        Object.assign(this, { cimaToken, config });
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<FooterPageData>(this.rendering.fields);

        // Convert into onSuccessHook when we have true SPA working.
        this.buildSpanishLinks();
        this.updateCopyrightYear();
    }

    public generateImageOptions(iconFields: ComponentFields): MediaImageOptions {
        const flattenedIconFields: CmsMediaOptionsImage = this.flattenFields<CmsMediaOptionsImage>(iconFields);

        return Cloudinary.generateMediaOptionsFromCms(flattenedIconFields);
    }

    public get showLanguageSwitchLinks(): boolean {
        return !this.cimaToken.isBusinessUser && Boolean(this.pageData.englishText || this.pageData.spanishText);
    }

    private buildSpanishLinks(): void {
        if (!WindowReference.isWindowAvailable) {
            return;
        }

        const href: string = window.location.href;
        const rootUrlPath: string = `${window.location.host}${this.config.BASE_URL}`;

        this.spanishLink = href.replace(rootUrlPath, SPANISH_PATH);
        this.englishLink = href.replace(rootUrlPath, ENGLISH_PATH);
    }

    private updateCopyrightYear(): void {
        const currentYear: number = new Date().getFullYear();
        this.pageData.copyright = this.pageData.copyright.replace('[Year]', currentYear.toString());
    }
}
