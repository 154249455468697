<section [ngClass]="{'center-content-modal': getFieldValue(data, 'centerContentModal')}">
    <xm-modal-close class="x-close-action" *ngIf="getFieldValue(data, 'close')" (onClose)="close('close')"></xm-modal-close>
    <h2 mat-dialog-title *scRichText="data.header"></h2>
    <div class="content">
        <xm-cms-text *ngFor="let description of data.descriptions" [field]="description.fields.paragraph"></xm-cms-text>
    </div>
    <div class="actions" *ngIf="hasActions">
        <xm-generic-link *ngIf="hasFieldOrEditable(data.linkDecorationOne)" class="cta"
            [field]="data.linkDecorationOne"></xm-generic-link>
        <xm-generic-link *ngIf="hasFieldOrEditable(data.linkDecorationTwo)" class="cta"
            [field]="data.linkDecorationTwo"></xm-generic-link>
        <xm-cms-text *ngIf="hasFieldOrEditable(data.ctaOne)" class="cta"
            [field]="data.ctaOne"></xm-cms-text>
        <xm-cms-text *ngIf="hasFieldOrEditable(data.ctaTwo)" class="cta"
            [field]="data.ctaTwo"></xm-cms-text>
    </div>
    <xm-modal-close class="actions" *ngIf="getFieldValue(data, 'additionalClose')" [field]="data.additionalClose" (onClose)="close('close')"></xm-modal-close>
</section>
