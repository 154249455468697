import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';

import { SitecoreComponent } from 'components/shared/sitecore';

@Component({
    selector: 'tout-module',
    styleUrls: [ './touts.scss' ],
    templateUrl: './touts.html'
})
export class MarketingToutsComponent extends SitecoreComponent implements OnInit {
    @Input() public rendering: ComponentRendering;

    @ViewChild(SwiperDirective) public swiper: SwiperDirective;

    public carouselSettings: SwiperConfigInterface = {
        slidesPerView: 'auto',
        spaceBetween: 0,
        centeredSlides: true,
        allowTouchMove: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            type: 'bullets',
            bulletElement: 'button'
        },
        breakpoints: {
            768: {
                spaceBetween: 0,
                centeredSlides: true,
                allowTouchMove: true
            },
            960: {
                spaceBetween: 0,
                centeredSlides: false,
                allowTouchMove: false
            }
        }
    };

    public pageData: MarketingToutPageData;
    public inputs: ToutInput;
    public sectionContainer: string;

    constructor(injector: Injector) {
        super(injector);
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<MarketingToutPageData>(this.rendering.fields);

        this.sectionContainer = this.pageData.sectionContainer || '';

        this.inputs = {
            isCarousel: this.pageData.enableCarousel,
            enableBusinessMessaging: this.pageData.enableBusinessMessaging,
            isCardMode: this.pageData.enableCardMode
        };
    }

    public placeholderLoaded(): void {
        if (this.swiper) {
            this.swiper.update();
        }
    }
}
