import { Component, Injector, Input, OnInit } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss';

import { SitecoreComponent } from 'components/shared/sitecore';

@Component({
    selector: 'marketing-home',
    styleUrls: [ './home.scss' ],
    templateUrl: './home.html'
})
export class MarketingHomeComponent extends SitecoreComponent implements OnInit {
    @Input() public rendering: ComponentRendering;

    public pages: HomePageData[] = [];

    constructor(injector: Injector) {
        super(injector);
    }

    public ngOnInit(): void {
        const pageData: HomePage = this.flattenFields<HomePage>(this.rendering.fields);

        if (pageData.pages) {
            pageData.pages.split(',').forEach((route: string) => {
                this.pages.push({
                    route,
                    name: this.convertRouteToName(route)
                });
            });
        }
    }

    private convertRouteToName(route: string): string {
        return route.split('-').map((part: string) => `${part[0].toUpperCase()}${part.substring(1)}`).join(' ');
    }
}
