import { StoreAction, XmAction } from 'core/store-actions';
import { Catalog } from './model/catalog';

export function catalogReducer(state: Catalog, action: XmAction<Catalog>): Catalog {
    switch (action.type) {
        case StoreAction.GET_CATALOG:
            return action.payload;
        default:
            return state;
    }
}
