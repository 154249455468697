import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { CONFIG_TOKEN } from 'core/constants';
import { IXMOptions } from 'core/interfaces';
import { UIRouter, UrlService } from '@uirouter/core';
import { Util } from 'core/utils/util';
import { WindowReference } from 'services/window';
import { XmStoreUtil } from '../store';
import * as DD from './constants';

@Injectable({
    providedIn: 'root'
})
export class Analytics implements OnDestroy {
    private active: boolean;
    private ANALYTICS_ACTIVE: boolean;
    private URL_ANALYTICS: string;
    private ENABLE_NEW_DATALAYER: boolean;
    private route: UrlService;
    private windowReference: WindowReference;
    private breakpoint: string;
    private subscriptions: Subscription[] = [];
    private uiRouter: UIRouter;

    constructor(@Inject(CONFIG_TOKEN) config: IXMOptions, route: UrlService, uiRouter: UIRouter, windowReference: WindowReference) {
        Object.assign(this, {
            ANALYTICS_ACTIVE: config.ANALYTICS_ACTIVE,
            URL_ANALYTICS: config.URL_ANALYTICS,
            ENABLE_NEW_DATALAYER: config.ENABLE_NEW_DATALAYER,
            route,
            uiRouter,
            windowReference
        });

        window.logAnalytics = false;
    }

    /* To load Analytics Script */
    public loadAnalytics(): Promise<void> {

        if (this.ENABLE_NEW_DATALAYER) {
            return Promise.resolve();
        }

        return new Promise((resolve: (value?: void | PromiseLike<void>) => void) => {
            this.active = Boolean(this.ANALYTICS_ACTIVE && this.URL_ANALYTICS);
            if (this.active) {
                window.digitalData = {
                    page: {
                        attribute: {
                            isPageLoadVariables: false
                        }
                    },
                    event: []
                };

                const script: HTMLScriptElement = window.document.createElement('script');
                script.type = 'text/javascript';
                script.src = this.URL_ANALYTICS;
                script.onload = this.onload.bind(this, resolve);
                script.onerror = this.onerror.bind(this, resolve);
                window.document.head.appendChild(script);
            } else {
                this.active = false;
                resolve();
            }
        });
    }

    /* Send Custom Pageload events */
    public updateCustomParams(customParams: TDigitalData, sendCustomLoadEvent?: boolean): void {
        if (this.ENABLE_NEW_DATALAYER) {
            return;
        }

        if (Object.keys(customParams).length) {
            Util.mergeDeepWith(window.digitalData, customParams);
        }

        if (sendCustomLoadEvent && this.canSendAnalytics) {
            window._trackPage();
        }
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    private onload(resolve: (value?: void | PromiseLike<void>) => void): void {
        this.logAnalytics('Launch script successfully loaded');

        // Set pageInfo and fire the track event on every route change
        this.subscriptions.push(XmStoreUtil.subscribe(this.uiRouter.globals.success$, () => {
            this.setPageInfo();
            this.firePageInfo();
        }));

        this.subscriptions.push(this.windowReference.breakpoint.subscribe((breakpoint: Breakpoint) => {
            this.breakpoint = breakpoint.currentBreakpoint;
        }));

        // Set the digitalData before route success on the initial load.
        // Adobe Target will read the pageName from the value being set here.
        this.setPageInfo();

        resolve();
    }

    private onerror(resolve: (value?: void | PromiseLike<void>) => void): void {
        this.logAnalytics('Launch has failed to load');
        resolve();
    }

    /* Overrides datalayer for pageInfo */
    private setPageInfo(): void {
        const digitalData: TDigitalData = window.digitalData = {
            page: Util.cloneProperties(DD.DEFAULT_PAGE)
        };

        digitalData.page.pageInfo.language = window.location.hostname === 'es.business.comcast.com' ? 'es' : 'en';

        const overrideDigitalData: TDigitalData = {
            page: {
                pageInfo: {
                    pageName: this.pipifiedRoute,
                    pageURL: window.location.href,
                    screenName: this.routeName && this.routeName.substring(1)
                },
                siteInfo: {
                    siteVersion: this.breakpoint
                }
            }
        };

        Util.mergeDeepWith(digitalData, overrideDigitalData);
        window.digitalData = digitalData;
    }

    private firePageInfo(): void {
        if (this.canSendAnalytics) {
            window._trackPage();
        }
    }

    /* Generate Pipified route names */
    private get pipifiedRoute(): string {
        const routeName: string = this.routeName;

        //eslint-disable-next-line vue/max-len
        return routeName ? `${DD.DEFAULT_PAGE.category.businessType}|${DD.DEFAULT_PAGE.category.siteType}|${DD.DEFAULT_PAGE.category.primaryCategory}|${DD.DEFAULT_PAGE.category.subCategory1}|${routeName.split('|').pop().replace('-', ' ')}` : `${DD.DEFAULT_PAGE.category.businessType}|${DD.DEFAULT_PAGE.category.siteType}|${DD.DEFAULT_PAGE.category.primaryCategory}|${DD.DEFAULT_PAGE.category.subCategory1}`;
    }
    /* Get page name */
    private get routeName(): string {
        const currentUrlArray: string[] = this.route.url().split('/');
        let toReturn: string = '';

        currentUrlArray.forEach((urlElement: string) => {
            // remove query parameter first if any
            let transformed: string = urlElement.split('?')[0];
            // remove access token from url
            transformed = transformed.split('#')[0];
            // lower-case each word
            transformed = transformed.toLocaleLowerCase();

            if (transformed) {
                toReturn += `|${transformed}`;
            }
        });

        return toReturn;
    }

    private logAnalytics(data: string): void {
        if (window.logAnalytics) {
            /* eslint-disable  no-console */
            console.log(data);
            /* eslint-enable  no-console */
        }
    }

    private get canSendAnalytics(): boolean {
        return this.active && typeof window._trackPage === 'function';
    }
}
