import { StoreAction, XmAction } from 'core/store-actions';
import { CmsPageData } from './model/cms';

export function cmsPageDataReducer(state: CmsPageData[] = [], action: XmAction<CmsPageData>): CmsPageData[] {
    switch (action.type) {
        case StoreAction.GET_CMS_PAGE_DATA:
            return [ ...state, action.payload ];
        default:
            return state;
    }
}
