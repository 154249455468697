import { Inject, Injectable } from '@angular/core';

import { CONFIG_TOKEN } from '../constants';
import { IXMOptions } from '../interfaces';
import { WindowReference } from './window';

@Injectable({
    providedIn: 'root'
})
export class Chat {
    private config: IXMOptions;

    constructor(@Inject(CONFIG_TOKEN) config: IXMOptions) {
        Object.assign(this, { config });
    }

    public openChat(): void {
        if (WindowReference.isWindowAvailable) {
            window.open(this.config.XFINITY_ASSISTANT, 'popup', 'left=10000,top=10000,width=360,height=640,resizable=no');
        }
    }
}
