<section *ngIf="pageData">
    <a class="card" [href]="productLink">
        <section>
            <div class="preorder"></div>
            <div class="five-g"></div>
            <div class="imagery" aria-hidden="true">
                <xm-media-image [imageOptions]="byodImageOptions"></xm-media-image>
            </div>
            <div class="detail">
                <div class="brand">{{ pageData.brand }}</div>
                <div class="name">{{ pageData.name }}</div>
            </div>
            <div class="price">
                <span>{{ pageData.price }}</span>
                <ng-container *ngIf="hasFieldOrEditable(rendering.fields.priceDescription)">
                    <span class="price-description" *scRichText="rendering.fields.priceDescription"></span>
                </ng-container>
            </div>
            <div class="promos" *ngIf="pageData.enablePromo">
                <div class="promo">
                    <xm-media-image class="promo-icon" [imageOptions]="prepaidImageOptions" size="width" center="vert"></xm-media-image>
                    <span class="promo-text">{{ promoLabel }}</span>
                </div>
            </div>
        </section>
    </a>
</section>
