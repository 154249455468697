import { ChildModel, ModelBase } from 'core/store/model-base';
import { PromotionType } from 'core/constants';
import { PromoEligibilityServices } from './promo-eligibility';

export interface PromoEligibleArgs {
    isDpp?: boolean;
    isBtg?: boolean;
    isPortIn?: boolean;
    hasXmpp?: boolean;
    isByod?: boolean;
}

export class Promotion extends ModelBase {
    public static storeName: string = 'promotion';
    public static idAttribute: string = 'id';

    public id: string;
    public value: number;
    public valueTotal: number;
    public percentValue: number;
    public type: PromotionType;
    public startDate: string;
    public endDate: string;
    public promotionDescription: string;
    public eligibilityServices: PromoEligibilityServices;

    public isPrepaid: boolean;
    public isPercentage: boolean;
    public isDollarAmount: boolean;
    public isExtendedPromo: boolean;
    public isSubsidy: boolean;
    public isExtendedPromoPCT: boolean;
    public isExtendedPromoDollar: boolean;
    public isExtendedPromoPhase2: boolean;
    public isPortInRequired: boolean;

    public static create<T extends ModelBase>(data: ApiResponse): T {
        data.eligibilityServices = data.eligibilityServices || {};

        data.isPrepaid = data.type === PromotionType.GIFT_CARD;
        data.isPercentage = data.type === PromotionType.PERCENTAGE;
        data.isDollarAmount = data.type === PromotionType.DOLLAR_AMOUNT;
        data.isExtendedPromo = data.type === PromotionType.EXTENDED_PROMO;
        data.isExtendedPromoPCT = data.type === PromotionType.EXTENDED_PROMO_PCT;
        data.isExtendedPromoDollar = data.type === PromotionType.EXTENDED_PROMO_DOLLAR;
        data.isSubsidy = data.type === PromotionType.SUBSIDY;
        data.isExtendedPromoPhase2 = data.isExtendedPromoPCT || data.isExtendedPromoDollar;

        data.isPortInRequired = data.eligibilityServices.portIn;

        return super.create<T>(data);
    }

    protected static get hasOne(): ChildModel[] {
        return [{
            attrName: 'eligibilityServices',
            model: PromoEligibilityServices
        }];
    }

    public replaceStringWithPromoValue(rawString: string): string {
        return rawString && rawString.replace('[PROMO_PCT]', `${this.percentValue}%`).replace('[PROMO_DOLLAR]', `$${this.valueTotal}`).replace('[PROMO_DOLLAR_MONTHLY]', `$${this.value}`);
    }

    public isPromoEligible(args: PromoEligibleArgs): boolean {
        const argsWithDefault: PromoEligibleArgs = {
            hasXmpp: args.hasXmpp === undefined ? true : args.hasXmpp,
            isDpp: args.isDpp === undefined ? true : args.isDpp,
            isBtg: args.isBtg === undefined ? true : args.isBtg,
            isByod: args.isByod === undefined ? true : args.isByod,
            isPortIn: args.isPortIn === undefined ? true : args.isPortIn
        };

        if (this.eligibilityServices.dpp && !argsWithDefault.isDpp) {
            return false;
        }

        if (this.eligibilityServices.btg && !argsWithDefault.isBtg) {
            return false;
        }

        if (this.eligibilityServices.unl && argsWithDefault.isBtg) {
            return false;
        }

        if (this.eligibilityServices.byod && !argsWithDefault.isByod) {
            return false;
        }

        if (this.eligibilityServices.newNum && argsWithDefault.isPortIn) {
            return false;
        }

        if (this.eligibilityServices.portIn && !argsWithDefault.isPortIn) {
            return false;
        }

        if (this.eligibilityServices.xmpp && !argsWithDefault.hasXmpp) {
            return false;
        }

        return true;
    }
}
