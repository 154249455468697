<section>
    <div class="side top" [ngStyle]="{ backgroundColor: pageData.leftBackgroundColor }">
        <div class="image">
            <xm-media-image *ngIf="leftImages" [imageOptions]="leftImages"></xm-media-image>
        </div>
        <div class="text">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.leftSmallTitle)">
                <div class="title" *scRichText="rendering.fields.leftSmallTitle"></div>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.leftHeader)">
                <h2 class="header" *scRichText="rendering.fields.leftHeader"></h2>
            </ng-container>
            <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.leftDescription)" class="details" [field]="rendering.fields.leftDescription"></xm-cms-text>
            <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.leftButton)" class="button" [field]="rendering.fields.leftButton"></xm-cms-text>
        </div>
    </div>
    <div class="side" [ngStyle]="{ backgroundColor: pageData.rightBackgroundColor }">
        <div class="image">
            <xm-media-image *ngIf="rightImages" [imageOptions]="rightImages"></xm-media-image>
        </div>
        <div class="text">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.rightSmallTitle)">
                <div class="title" *scRichText="rendering.fields.rightSmallTitle"></div>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.rightHeader)">
                <h2 class="header" *scRichText="rendering.fields.rightHeader"></h2>
            </ng-container>
            <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.rightDescription)" class="details" [field]="rendering.fields.rightDescription"></xm-cms-text>
            <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.rightButton)" class="button" [field]="rendering.fields.rightButton"></xm-cms-text>
        </div>
    </div>
</section>
