<section>
    <xm-modal-close (onClose)="close('close')"></xm-modal-close>
    <h3 class="small-title" *scRichText="data.title"></h3>
    <h2 class="header" mat-dialog-title *scRichText="data.header"></h2>
    <div class="steps">
        <div class="step">
            <div class="image">
                <xm-media-image *ngIf="stepOneImage" class="image" [imageOptions]="stepOneImage"></xm-media-image>
            </div>
            <p class="info" *scRichText="data.stepOne"></p>
        </div>
        <div class="step">
            <div class="image">
                <xm-media-image *ngIf="stepTwoImage" class="image" [imageOptions]="stepTwoImage"></xm-media-image>
            </div>
            <p class="info" *scRichText="data.stepTwo"></p>
        </div>
        <div class="step">
            <div class="image">
                <xm-media-image *ngIf="stepThreeImage" class="image" [imageOptions]="stepThreeImage"></xm-media-image>
            </div>
            <p class="info" *scRichText="data.stepThree"></p>
        </div>
    </div>
    <xm-cms-text *ngIf="data.stepDescription" class="step-description" [field]="data.stepDescription"></xm-cms-text>
    <div class="bar"></div>
    <h4 class="legal-title" *scRichText="data.legalTitle"></h4>
    <xm-cms-text class="legal-description" [field]="data.legalDescription"></xm-cms-text>
</section>
