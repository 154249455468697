<section>
    <div class="slide-section">
        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.title)">
            <div class="slide-title slide-info"><span *scRichText="rendering.fields.title"></span></div>
        </ng-container>
        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.header)">
            <h3 class="slide-header slide-info" *scRichText="rendering.fields.header"></h3>
        </ng-container>
        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.body)">
            <p class="slide-body" *scRichText="rendering.fields.body"></p>
        </ng-container>
        <div class="btg-section slide-info" *ngIf="hasFieldOrEditable(rendering.fields.btgHeader)">
            <div class="variant-header">
                <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgHeader)">
                    <p class="variant-top-row" *scRichText="rendering.fields.btgHeader"></p>
                </ng-container>
                <xm-media-image *ngIf="btgImage" class="btg-icon flex-end" [imageOptions]="btgImage"></xm-media-image>
            </div>
            <div class="variant-body slide-info">
                <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgBody)">
                    <p class="variant-bottom-row btg-body" *scRichText="rendering.fields.btgBody"></p>
                </ng-container>
                <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPrice)">
                    <p class="variant-bottom-row btg-price flex-end" *scRichText="rendering.fields.btgPrice"></p>
                </ng-container>
            </div>
        </div>
        <div class="unl-section slide-info" *ngIf="hasFieldOrEditable(rendering.fields.unlHeader)">
            <div class="variant-header">
                <ng-container *ngIf="hasFieldOrEditable(rendering.fields.unlHeader)">
                    <p class="variant-top-row" *scRichText="rendering.fields.unlHeader"></p>
                </ng-container>
                <xm-media-image *ngIf="unlImage" class="unl-icon flex-end" [imageOptions]="unlImage"></xm-media-image>
            </div>
            <div class="variant-body slide-info">
                <ng-container *ngIf="hasFieldOrEditable(rendering.fields.unlBody)">
                    <p class="variant-bottom-row unl-body" *scRichText="rendering.fields.unlBody"></p>
                </ng-container>
                <ng-container *ngIf="hasFieldOrEditable(rendering.fields.unlPrice)">
                    <p class="variant-bottom-row unl-price flex-end" *scRichText="rendering.fields.unlPrice"></p>
                </ng-container>
            </div>
        </div>
        <div class="separator" *ngIf="hasFieldOrEditable(rendering.fields.monthlyHeader)"><div></div></div>
        <div class="monthly-section slide-info" *ngIf="hasFieldOrEditable(rendering.fields.monthlyHeader)">
            <div class="variant-header">
                <ng-container *ngIf="hasFieldOrEditable(rendering.fields.monthlyHeader)">
                    <p class="variant-top-row" *scRichText="rendering.fields.monthlyHeader"></p>
                </ng-container>
                <ng-container *ngIf="hasFieldOrEditable(rendering.fields.monthlyPrice)">
                    <p class="variant-top-row monthly-price flex-end" *scRichText="rendering.fields.monthlyPrice"></p>
                </ng-container>
            </div>
            <div class="variant-body slide-info">
                <ng-container *ngIf="hasFieldOrEditable(rendering.fields.monthlyBody)">
                    <p class="variant-bottom-row monthly-body" *scRichText="rendering.fields.monthlyBody"></p>
                </ng-container>
            </div>
        </div>
    </div>
</section>
