import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiChannel } from 'core/constants';
import { Cart } from './model/cart';
import { XmHttp } from 'services/http/http';
import { XmStore } from 'services/store';
import { ModelBase } from '../model-base';
import { StoreAction } from 'core/store-actions';

@Injectable()
export class CartApi {
    public static getCart(_xmStore: XmStore, xmHttp: XmHttp): Observable<Cart> {
        return xmHttp.get(ApiChannel.GATEWAY, 'cart').pipe(
            map((response: Cart) => Cart.create(response))
        );
    }
}

ModelBase.fetchMapping[StoreAction.GET_CART] = CartApi.getCart;
