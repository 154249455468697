import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Cloudinary {
    private static URL: string = 'https://img.xfinitymobile.com/image/';
    private static ICON_NAME: string = 'icon';
    private static IMAGE_NAME: string = 'image';
    private static ILLUSTRATION_NAME: string = 'illustration';
    private static VERSION: string = 'v1653670270';

    public static generateMediaFromCms(cmsImage: CmsMediaImage): MediaImageCustom{
        return {
            alt: cmsImage.alt || '',
            base: cmsImage.base,
            version: cmsImage.version,
            width: cmsImage.width || -1,
            height: cmsImage.height || -1,
            isSvg: (cmsImage.base || '').endsWith('.svg'),
            trimTransparency: cmsImage.trimTransparency || false,
            useBoundingBox: cmsImage.useBoundingBox || false,
            icon: cmsImage.type === Cloudinary.ICON_NAME,
            image: cmsImage.type === Cloudinary.IMAGE_NAME,
            illustration: cmsImage.type === Cloudinary.ILLUSTRATION_NAME,
            imageSize: cmsImage.imageSize || '',
            imageCenter: cmsImage.imageCenter || '',
            staticRepo: cmsImage.staticRepo
        };
    }

    public static pickBreakpointImage(cmsImages: MediaImageOptions, breakpoint: Breakpoint, generateParms?: CmsImageParams): string | MediaImageCustom{
        if (!cmsImages) {
            return;
        }

        let currentImage: MediaImageCustom = cmsImages.xlarge || cmsImages.large || cmsImages.medium || cmsImages.small;
        if (breakpoint.isSmall) {
            currentImage = cmsImages.small;
        } else if (breakpoint.isMedium) {
            currentImage = cmsImages.medium || cmsImages.small;
        } else if (breakpoint.isLarge) {
            currentImage = cmsImages.large || cmsImages.medium || cmsImages.small;
        }

        if (!currentImage) {
            return;
        }

        if (generateParms) {
            return Cloudinary.generateUrl(generateParms.width, generateParms.height, currentImage);
        }
        
        return currentImage;
    }

    public static generateMediaOptionsFromCms(cmsImages: CmsMediaOptionsImage): MediaImageOptions {
        const options: MediaImageOptions = {};

        if (cmsImages?.small) {
            options.small = Cloudinary.generateMediaFromCms(cmsImages.small);
        }

        if (cmsImages?.medium) {
            options.medium = Cloudinary.generateMediaFromCms(cmsImages.medium);
        }

        if (cmsImages?.large) {
            options.large = Cloudinary.generateMediaFromCms(cmsImages.large);
        }

        if (cmsImages?.xlarge) {
            options.xlarge = Cloudinary.generateMediaFromCms(cmsImages.xlarge);
        }

        return options;
    }

    public static generateUrl(width: number, height: number, image: MediaImageCustom): string {
        let url: string = Cloudinary.URL;
        if (!image || !image.base) {
            return '';
        }

        const regexAbsolutePath: RegExp = /(http:)|(https:)/;
        const matchUrl: RegExpMatchArray = image.base.match(regexAbsolutePath);

        if (matchUrl) {
            url += 'fetch/';
        } else {
            url += 'upload/';
        }

        if (!image.isSvg && image.trimTransparency) {
            url += 'e_trim/';
        }

        let options: string = '';

        const widthString: string = width.toString();
        const heightString: string = height.toString();

        if (!image.isSvg && !image.base) {
            if (width > 0 && height > 0) {
                options += `w_${widthString},h_${heightString},`;
            } else if (width > 0) {
                options += `w_${widthString},`;
            } else if (height > 0) {
                options += `h_${heightString},`;
            }
        }

        if (options) {
            options = options.substring(0, options.length - 1);
            url += `${options}/`;
        }

        if (image.version) {
            url += `v${image.version}/`;
        } else {
            url += `${Cloudinary.VERSION}/`;
        }

        const endUrl: string = matchUrl ? encodeURIComponent(image.base) : image.base;
        url += `client/v2/images/${endUrl}`;

        return url;
    }

    public static generateResponsiveUrl(width: number, height: number, currentImage: SingletonResponsiveImage): string {
        if (currentImage.url.endsWith('.svg')) {
            return currentImage.url;
        }

        let options: string = currentImage.trimTransparency ? 'e_trim/' : '';

        if (width > 0 && height > 0) {
            options += `w_${width},h_${height},`;
        } else if (width > 0) {
            options += `w_${width},`;
        } else if (height > 0) {
            options += `h_${height},`;
        }

        if (options) {
            options = options.substring(0, options.length - 1);
            options = `${options}/`;
        }

        return currentImage.url.replace('upload/', `upload/${options}`);
    }
}
