import { Util } from 'core/utils/util';

export class ModelBase {
    public static storeName: string;
    public static idAttribute: string;

    public static fetchMapping: object = {};
    public static persistMapping: object = {};
    public static deleteMapping: object = {};

    public static create<T extends ModelBase>(initData: any = {}): T {
        /* eslint-disable no-param-reassign */
        initData = initData || {};
        /* eslint-enable no-param-reassign */

        const t: ModelBase = new this(initData, false);

        const ctor: typeof ModelBase = t.constructor as typeof ModelBase;

        ctor.hasOne.forEach((member: ChildModel) => {
            t[member.attrName] = member.model.create(initData[member.attrName] || {});
        });

        ctor.hasMany.forEach((member: ChildModel) => {
            t[member.attrName] = initData[member.attrName] ? initData[member.attrName].map((x: object) => member.model.create(x)) : [];
        });

        return <T> t;
    }

    constructor(initData: any = {}, createChildModels: boolean = true) {
        /* eslint-disable no-param-reassign */
        initData = initData || {};
        /* eslint-enable no-param-reassign */

        Object.assign(this, JSON.parse(JSON.stringify(initData)));

        if (createChildModels) {
            const ctor: typeof ModelBase = this.constructor as typeof ModelBase;

            ctor.hasOne.forEach((member: ChildModel) => {
                this[member.attrName] = new member.model(initData[member.attrName] || {});
            });

            ctor.hasMany.forEach((member: ChildModel) => {
                this[member.attrName] = initData[member.attrName] ? initData[member.attrName].map((x: object) => new member.model(x)) : [];
            });
        }
    }

    protected static get hasMany(): ChildModel[] {
        return [];
    }

    protected static get hasOne(): ChildModel[] {
        return [];
    }

    public get hasApiData(): boolean {
        const modelCtor: typeof ModelBase = this.constructor as typeof ModelBase;

        return !modelCtor.idAttribute || Util.dotWalk(this, modelCtor.idAttribute) !== undefined;
    }

    public convertToJSON(): object {
        const toReturn: object = Util.cloneProperties(this);
        const ctor: typeof ModelBase = this.constructor as typeof ModelBase;

        ctor.hasOne.forEach((child: ChildModel) => {
            if (toReturn[child.attrName]) {
                toReturn[child.attrName] = this[child.attrName].convertToJSON();
            }
        });

        ctor.hasMany.forEach((child: ChildModel) => {
            if (toReturn[child.attrName]) {
                toReturn[child.attrName] = this[child.attrName].map((m: ModelBase) => m.convertToJSON());
            }
        });

        return toReturn;
    }

    public deepLookupOne<T extends ModelBase>(keyToFind: string, returnParent: boolean = false): T {
        const matchedKey: boolean = Object.keys(this).includes(keyToFind);
        if (matchedKey) {
            return this[keyToFind];
        }

        let foundModel: T;
        const ctor: typeof ModelBase = this.constructor as typeof ModelBase;
        ctor.hasOne.some((child: ChildModel) => {
            const model: ModelBase = this[child.attrName];
            const deeperModel: T = model.deepLookupOne<T>(keyToFind, returnParent);

            const modelCtor: typeof ModelBase = deeperModel.constructor as typeof ModelBase;
            if (deeperModel && Util.dotWalk(deeperModel, modelCtor.idAttribute) !== undefined) {
                foundModel = deeperModel;

                return true;
            }
        });

        return returnParent && foundModel ? <T> <any> this : foundModel;
    }

    public deepLookupMany<T extends ModelBase>(keyToFind: string, filters: ChildManyFilter[], returnParent: boolean = false): T {
        const matchedKey: boolean = Object.keys(this).includes(keyToFind);
        if (matchedKey) {
            return this[keyToFind];
        }

        let childModel: T;

        if (filters) {
            const filter: ChildManyFilter = filters.shift();
            const models: ModelBase[] = this[filter.parentKey];
            const foundModel: ModelBase = models.find((m: ModelBase) => Util.dotWalk(m, filter.filterKey) === filter.filterValue);
            childModel = foundModel ? foundModel.deepLookupMany<T>(keyToFind, filters, returnParent) : undefined;

            return childModel && childModel.hasApiData ? childModel : undefined;
        }
    }
}

export interface ChildModel {
    attrName: string;
    model: typeof ModelBase;
}
