import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[xmAriaHidden]'
})
export class XmAriaHidden implements OnChanges {
    @Input() private xmAriaHidden: boolean | object | string = false;

    private element: HTMLElement;

    constructor(element: ElementRef) {
        Object.assign(this, { element: element.nativeElement });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.xmAriaHidden) {
            if (this.xmAriaHidden) {
                this.element.setAttribute('aria-hidden', 'true');
            } else {
                this.element.removeAttribute('aria-hidden');
            }
        }
    }
}
