<section #parentRef>
    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.header)">
        <h2 *scRichText="rendering.fields.header"></h2>
    </ng-container>
    <table class="table">
        <tr class="row">
            <th class="column" scope="col">
                <ng-container *ngIf="hasFieldOrEditable(rendering.fields.unlimitedTitle)">
                    <div class="title" *scRichText="rendering.fields.unlimitedTitle"></div>
                </ng-container>
            </th>
            <th class="column" scope="col">
                <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgTitle)">
                    <div class="title" *scRichText="rendering.fields.btgTitle"></div>
                </ng-container>
            </th>          
        </tr>
        <ng-container *ngFor="let item of rendering.fields.unlimitedFeatures; let i = index">
            <tr class="row">
                <td class="column top-margin">
                    <div class="features">
                        <xm-media-image class="image" [imageOptions]="generateImageFromCms(pageData.unlimitedFeatures[i].icon)"></xm-media-image>
                        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.unlimitedFeatures[i].fields.body)">
                            <div class="description" *scRichText="rendering.fields.unlimitedFeatures[i].fields.body"></div>
                        </ng-container>
                    </div>
                </td> 
                <td class="column top-margin">
                    <div class="features">
                        <xm-media-image class="image" [imageOptions]="generateImageFromCms(pageData.btgFeatures[i].icon)"></xm-media-image>
                        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgFeatures[i].fields.body)">
                            <div class="description" *scRichText="rendering.fields.btgFeatures[i].fields.body"></div>
                        </ng-container>
                    </div>
                </td>
            </tr>
        </ng-container>        

        <tr class="footer">
            <td scope="col">
                <xm-cms-text class="details upper" *ngIf="hasFieldOrEditable(rendering.fields.upperCta)" [field]="rendering.fields.upperCta"></xm-cms-text>
                <xm-generic-link class="details-link upper" *ngIf="hasFieldOrEditable(rendering.fields.linkDecorationOne)" [field]="rendering.fields.linkDecorationOne"></xm-generic-link>
                <xm-cms-text class="details" *ngIf="hasFieldOrEditable(rendering.fields.body)" [field]="rendering.fields.body"></xm-cms-text>
                <xm-popover
                    *ngIf="rendering.fields.popover"
                    class="lower"
                    [rendering]="rendering.fields.popover"
                    [parentTemplate]="parentRef"
                    position="center">
                </xm-popover>
            </td>
        </tr>
    </table>
</section>
