<section (mouseenter)="mouseHoverEvent($event)" (mouseleave)="mouseHoverEvent($event)">
    <div class="nav-menu-items separator" *ngIf="isSeparator"></div>
    <xm-nav-chat *ngIf="isChat" [rendering]="rendering" class="nav-menu-items" (toggleMobileNav)="closeMobileMenu()"></xm-nav-chat>
    <xm-nav-cart *ngIf="isCart" [rendering]="rendering" (toggleMobileNav)="closeMobileMenu()"></xm-nav-cart>
    <div *ngIf="!isSeparator && !isChat && !isCart" class="nav-menu-items"  [ngClass]="{'nav-selected-menu': changeMenuColor}">
        <div *ngIf="isStateActive" class="active-state"></div>
        <xm-media-image [imageOptions]="displayIcon" class="icon" *ngIf="displayIcon"></xm-media-image>
        <!-- change this logic -->
        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.name) && (!pageData.children || pageData.children.length == 0)">
            <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.name)" class="nav-menu-item" [field]="rendering.fields.name" [menuHeader]="true" role="navigation"></xm-cms-text>
        </ng-container>
        <ng-container *ngIf="!hasFieldOrEditable(rendering.fields.name) && hasGenericLink">
            <xm-generic-link [field]="rendering.fields.genericLink"  (click)="closeMobileMenu()" class="nav-menu-item"></xm-generic-link>
        </ng-container>
        <ng-container *ngIf="!displayName && !hasGenericLink">
            <a *scRichText="rendering.fields.name" (click)="closeMobileMenu()" [href]="pageData.url" class="nav-menu-item"></a>
        </ng-container>
        <button aria-roledescription="submenu" *ngIf="showChildren && caretIcon && pageData.children && pageData.children.length" class="caret-icon-container" (click)="expandChildItems()" [attr.aria-labelledby]="rendering.fields.name['value'].replace(' ', '-')" [attr.aria-expanded]="showChildItems" [attr.aria-label]="expandMessge">
            <xm-cms-text [id]="rendering.fields.name['value'].replace(' ', '-')" *ngIf="hasFieldOrEditable(rendering.fields.name)" class="nav-menu-item" [field]="rendering.fields.name" role="navigation" [applyDropdownTextStyle]="true"></xm-cms-text>
            <xm-media-image [imageOptions]="caretIcon" aria-hidden="true" class="caret-icon"></xm-media-image>
        </button>
        <ul *ngIf="showChildren && pageData.children && pageData.children.length" class="child-items" [ngClass]="{ 'show-child-items': showChildItems, 'inherit-width': inheritParentWidth }">
            <li class="child-list" *ngFor="let child of pageData.children; index as i" [ngClass]= "{ 'child-sub-items': showChildren, 'nav-selected-sub-menu': changeCaretIconColor }" (mouseenter)="mouseHoverSubEvent($event, rendering.fields.children[i].fields.children.length > 0)" (mouseleave)="mouseHoverSubEvent($event, rendering.fields.children[i].fields.children.length > 0)" (click)="closeMobileMenu()">
                <xm-generic-link *ngIf="!child.isLogout && child.genericLink && child.genericLink.url" [field]="rendering.fields.children[i].fields.genericLink"  class="child-item"></xm-generic-link>
                <button *ngIf="rendering.fields.children[i].fields.children && rendering.fields.children[i].fields.children.length" class="caret-icon-container"  (click)="expandSubChildItems()" [attr.aria-expanded]="showSubChildItems" aria-label="Click to expand">
                    <xm-media-image [imageOptions]="caretRightIcon" aria-hidden="true" class="caret-icon" [ngClass]="{
                        'blue-color': true, 
                        'black-color': false }" [ngClass]="{ 'caret-icon-blue': true }"></xm-media-image>
                </button>
                <ul *ngIf="showChildren && rendering.fields.children[i].fields.children && rendering.fields.children[i].fields.children.length" class="super-child-items" [ngClass]="{ 'show-child-items': showSubChildItems, 'inherit-width': inheritParentWidth }">
                    <li class="child-list" *ngFor="let childSecond of rendering.fields.children[i].fields.children; index as j">
                        <xm-generic-link *ngIf="!child.isLogout && child.genericLink && child.genericLink.url" [field]="rendering.fields.children[i].fields.children[j].fields.genericLink" (click)="closeMobileMenu()" class="child-item"></xm-generic-link>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</section>
