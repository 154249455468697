import { Injectable, OnDestroy } from '@angular/core';
import { Transition, TransitionService } from '@uirouter/core';
import { WindowReference } from 'services/window';
import { DataLayer } from 'core/services';

@Injectable({
    providedIn: 'root'
})
export class CoreRoute implements OnDestroy {
    private transition: TransitionService;
    private onSuccessDeregister: Function;
    private dataLayer: DataLayer;

    constructor(transition: TransitionService, dataLayer: DataLayer) {
        Object.assign(this, { transition, dataLayer });
    }

    public init(): Promise<void> {
        this.onSuccessDeregister = this.transition.onSuccess({}, (trans: Transition) => {
            // if transition occurs on the same route
            this.dataLayer.sendPageDataOnTransition(trans);
            if (WindowReference.isWindowAvailable && !trans.dynamic()) {
                window.scrollTo(0, 0);
            }
        });

        return Promise.resolve();
    }

    public ngOnDestroy(): void {
        this.onSuccessDeregister();
    }
}
