import { Component, Injector, Input } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss';

import { SitecoreComponent } from 'components/shared/sitecore';

@Component({
    selector: 'marketing-policy-header',
    styleUrls: [ './policy-header.scss' ],
    templateUrl: './policy-header.html'
})
export class MaketingPolicyHeaderModule extends SitecoreComponent {
    @Input() public rendering: ComponentRendering;

    constructor(injector: Injector) {
        super(injector);
    }
}
