import { Component, Injector, Input, OnInit } from '@angular/core';

import { ComponentRendering } from '@sitecore-jss/sitecore-jss';
import { SitecoreComponent } from 'components/shared/sitecore';
import { Modal } from 'services/modal';

@Component({
    selector: 'promo-banner',
    styleUrls: [ './promo-banner.scss' ],
    templateUrl: './promo-banner.html'
})
export class PromoBannerComponent extends SitecoreComponent implements OnInit {
    @Input() public rendering: ComponentRendering;

    public pageData: PromoBannerPageData;

    private modal: Modal;

    constructor(injector: Injector, modal: Modal) {
        super(injector);

        Object.assign(this, { modal });
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<PromoBannerPageData>(this.rendering.fields);
    }

    public viewModal(): void {
        this.modal.promo(this.rendering.fields);
    }
}
