<nav (click)="searchWindowService.close()" class="component-nav" aria-label="Mainmenu navigation">
    <div class="component-nav-topmenu-div">
        <div class="item-bar">
            <button class="toggle-menu" (click)="toggleSideMenu();" [ngClass]="{
                'active': !sideFirstSubMenuVisible, 
                'inactive': sideFirstSubMenuVisible }">
                <a href="javascript:void(0);" class="brand">
                    <img *scImage="rendering.fields.hamburger; editable: false" alt="Hamburger logo"/>
                </a>
            </button>
            <button class="toggle-menu" (click)="closeHamburgerMenu();" [ngClass]="{
                'active': sideFirstSubMenuVisible, 
                'inactive': !sideFirstSubMenuVisible }">
                <a href="javascript:void(0);" class="brand">
                    <img *scImage="rendering.fields.hamburgerClose; editable: false" alt="Hamburger logo"/>
                </a>
            </button>
            <div class="logo-bar">
                <a *scLink="rendering.fields.logoNav">
                    <img *scImage="rendering.fields.logo; editable: false" alt="Comcast business logo"/>
                </a>
            </div>
            <a *scLink="rendering.fields.shop" class="link"></a>
            <a *scLink="rendering.fields.support" class="link"></a>
        </div>
        <div class="right-section">
            <xm-search [rendering]="rendering"></xm-search>
            <div class="phone">
                <a *scLink="rendering.fields.phoneLink">
                <img *scImage="rendering.fields.phoneIcon; editable: false" alt="Dial phone icon"/></a>
                <span class="phone-replace" data-component="phone-number"><a href="tel:877-289-8849" tabindex="-1"> 877-289-8849 </a></span>
            </div>
            <div class="signin">
                <a *scLink="rendering.fields.signInLink"><img *scImage="rendering.fields.signInIcon; editable: false" alt="SignIn Icon"/></a>
                <a *scLink="rendering.fields.signInLink" class="link"></a>
            </div>
        </div>
    </div>
</nav>

<div class="sidebar-menu" [ngClass]="{'active': sideMenuVisible}">
    <div class="main-navigation">
        <nav class="component-nav-topmenu" aria-label="Mainmenu mobile Navigation">
            <div class="component-nav-topmenu-div">
                <div class="component-nav-topmenu-item active">
                    <a *scLink="rendering.fields.shopMobile" ></a>
                    <img class="open-caret" *scImage="rendering.fields.openCaret; editable: false" alt="Shop menu" (click)="toggleFirstSideSubMenu();"/>
                </div>
                <div class="component-nav-topmenu-item">
                    <a *scLink="rendering.fields.supportMobile"></a>
                    <img class="open-caret" *scImage="rendering.fields.openCaret; editable: false" alt="Support menu"/>
                </div>                
                <div class="component-nav-topmenu-item">
                    <a *scLink="rendering.fields.myAccountMobile"></a>
                    <a class="sign-in" *scLink="rendering.fields.signInLink"></a>
                </div>
            </div>
        </nav>
    </div>
    <div class="secondary-navigation">
        <nav class="component-nav-topmenu" aria-label="Topmenu Navigation">
            <div class="component-nav-topmenu-div">                
                <div class="component-nav-topmenu-item">
                    <a *scLink="rendering.fields.xfinity"></a>
                </div>
                <div class="component-nav-topmenu-item">
                    <a *scLink="rendering.fields.enterprise"></a>
                </div>
            </div>
        </nav>
    </div>
</div>

<div class="sidebar-menu" [ngClass]="{'active': sideFirstSubMenuVisible}">
    <div class="main-navigation">
        <nav class="component-nav-topmenu" aria-label="Mainmenu mobile Navigation">
            <div class="component-nav-topmenu-div">
                <div class="component-nav-topmenu-item header"> 
                    <div (click)="sideFirstSubMenuVisible = false;">
                        <img class="close-caret" *scImage="rendering.fields.closeCaret; editable: false" alt="Support menu">
                        <span *scRichText=rendering.fields.backText>
                        </span>
                    </div>
                    <a *scLink="rendering.fields.shopMobile"></a>
                </div>                
                <div class="component-nav-topmenu-item" *ngFor="let child of rendering.fields.mobileMenuItems; index as i">                    
                    <xm-generic-link [field]="child.fields.genericLink"  class="nav-menu-item"></xm-generic-link>
                    <ng-container *ngIf="child.fields.children && child.fields.children.length">
                        <img class="open-caret" *scImage="rendering.fields.openCaret; editable: false" alt="Shop menu" (click)="toggleSecondSideSubMenu(i);" />
                    </ng-container>                  
                </div>
            </div>
        </nav>
    </div>
    <div class="secondary-navigation">
        <nav class="component-nav-topmenu" aria-label="Topmenu Navigation">
            <div class="component-nav-topmenu-div">                
                <div class="component-nav-topmenu-item">
                    <a *scLink="rendering.fields.xfinity"></a>
                </div>
                <div class="component-nav-topmenu-item">
                    <a *scLink="rendering.fields.enterprise"></a>
                </div>
            </div>
        </nav>
    </div>
</div>

<div class="sidebar-menu" [ngClass]="{'active': sideSecondSubMenuVisible}">
    <div class="main-navigation">
        <nav class="component-nav-topmenu" aria-label="Mainmenu mobile Navigation">
            <div class="component-nav-topmenu-div">
                <div class="component-nav-topmenu-item header">
                    <div (click)="sideSecondSubMenuVisible = false;">
                        <img class="close-caret" *scImage="rendering.fields.closeCaret; editable: false" alt="Support menu" />
                        <span *scRichText=rendering.fields.backText>
                        </span>
                    </div>
                    <a *scLink="rendering.fields.mobileMenuItems[currentChildIndex].fields.genericLink"></a>
                </div>                
                <div class="component-nav-topmenu-item" *ngFor="let child of rendering.fields.mobileMenuItems[currentChildIndex].fields.children; index as i">                    
                    <xm-generic-link [field]="child.fields.genericLink"  class="nav-menu-item"></xm-generic-link>
                    <ng-container *ngIf="child.fields.children && child.fields.children.length">
                        <img class="open-caret" *scImage="rendering.fields.openCaret; editable: false" alt="Shop menu" (click)="toggleThirdSideSubMenu(i);"/>
                    </ng-container>                  
                </div>
            </div>
        </nav>
    </div>
    <div class="secondary-navigation">
        <nav class="component-nav-topmenu" aria-label="Topmenu Navigation">
            <div class="component-nav-topmenu-div">                
                <div class="component-nav-topmenu-item">
                    <a *scLink="rendering.fields.xfinity"></a>
                </div>
                <div class="component-nav-topmenu-item">
                    <a *scLink="rendering.fields.enterprise"></a>
                </div>
            </div>
        </nav>
    </div>
</div>
<div class="sidebar-menu" [ngClass]="{'active': sideThirdSubMenuVisible}">
    <div class="main-navigation">
        <nav class="component-nav-topmenu" aria-label="Mainmenu mobile Navigation">
            <div class="component-nav-topmenu-div">
                <div class="component-nav-topmenu-item header">
                    <div (click)="sideThirdSubMenuVisible = false;">
                        <img class="close-caret" *scImage="rendering.fields.closeCaret; editable: false" alt="Support menu" />
                        <span *scRichText=rendering.fields.backText>
                        </span>
                    </div>
                    <a *scLink="rendering.fields.mobileMenuItems[currentSubChildIndex].fields.children[currentSubChildIndex].fields.genericLink"></a>
                </div>                
                <div class="component-nav-topmenu-item" *ngFor="let child of rendering.fields.mobileMenuItems[currentSubChildIndex].fields.children[currentSubChildIndex].fields.children; index as i">                    
                    <xm-generic-link [field]="child.fields.genericLink"  class="nav-menu-item"></xm-generic-link>
                    <ng-container *ngIf="child.fields.children && child.fields.children.length">
                        <img class="open-caret" *scImage="rendering.fields.openCaret; editable: false" alt="Shop menu" (click)="toggleThirdSideSubMenu(i);"/>
                    </ng-container>                  
                </div>
            </div>
        </nav>
    </div>
    <div class="secondary-navigation">
        <nav class="component-nav-topmenu" aria-label="Topmenu Navigation">
            <div class="component-nav-topmenu-div">                
                <div class="component-nav-topmenu-item">
                    <a *scLink="rendering.fields.xfinity"></a>
                </div>
                <div class="component-nav-topmenu-item">
                    <a *scLink="rendering.fields.enterprise"></a>
                </div>
            </div>
        </nav>
    </div>
</div>
