import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { XmHttp } from 'services/http/http';
import { XmStore } from 'services/store';
import { ApiChannel } from 'core/constants';
import { StoreAction } from 'core/store-actions';
import { ModelBase } from '../model-base';
import { CmsPageData } from 'core/store/cms/model/cms';

import { LayoutServiceData } from '@sitecore-jss/sitecore-jss-angular';

@Injectable()
export class CmsApi {
    public static getPageData(_xmStore: XmStore, xmHttp: XmHttp, params: ApiParams): Observable<CmsPageData> {

        return xmHttp.get(ApiChannel.CMS, params.pageId).pipe(
            map((response: LayoutServiceData) => CmsPageData.create({ sitecorePage: response, pageId: params.pageId }))
        );
    }

}

ModelBase.fetchMapping[StoreAction.GET_CMS_PAGE_DATA] = CmsApi.getPageData;
