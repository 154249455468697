import { Component, Injector, Input } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss';
import { SitecoreComponent } from 'components/shared/sitecore';
import { Cloudinary } from 'services/cloudinary';

@Component({
    selector: 'marketing-flexible-text',
    styleUrls: [ './flexible-text.scss' ],
    templateUrl: './flexible-text.html'
})
export class MarketingFlexibleTextComponent extends SitecoreComponent {
    @Input() public rendering: ComponentRendering;

    public pageData: FlexibleTextPageData;
    public hasActions: boolean = false;
    public alignment: string;
    public sectionContainer: string;
    public positionSection: string;
    public breakpointImages: MediaImageOptions;
    public lbTextContainer: string;
    public sbTextContainer: string;
    public pageNotFoundWrapper: boolean = false;
    public savingsCalculator: boolean = false;
    public linkDecorationOneIcon: MediaImageOptions;
    public theme: string = 'light';

    private readonly PAGE_NOT_FOUND_CLASS: string = 'page-not-found';
    private readonly ERROR_CLASS: string = 'error-page';

    constructor(injector: Injector) {
        super(injector);
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<FlexibleTextPageData>(this.rendering.fields);
        this.alignment = this.pageData.align || 'align-center';
        this.positionSection = this.pageData.positionSection || '';
        this.sectionContainer = this.pageData.sectionContainer || '';

        if (this.pageData.icon) {
            this.breakpointImages = Cloudinary.generateMediaOptionsFromCms(this.pageData.icon);
        }

        this.hasActions = this.checkForActions();
        this.lbTextContainer = this.pageData.largeBodyClass || '';
        this.sbTextContainer = this.pageData.smallBodyClass || '';

        if (this.pageData.sectionContainer === this.PAGE_NOT_FOUND_CLASS || this.pageData.sectionContainer === this.ERROR_CLASS) {
            this.pageNotFoundWrapper = true;
        }

        if (this.pageData.sectionContainer === 'savingsCalculatorPlan') {
            this.savingsCalculator = true;
        }

        if (this.pageData.linkDecorationOneIcon) {
            this.linkDecorationOneIcon = Cloudinary.generateMediaOptionsFromCms(this.pageData.linkDecorationOneIcon);
        }

        if (this.pageData.theme) {
            this.theme = this.pageData.theme;
        }
    }

    private checkForActions(): boolean {
        return Boolean(this.hasFieldOrEditable(this.rendering.fields.ctaOne) ||
                this.hasFieldOrEditable(this.rendering.fields.ctaTwo) ||
                this.hasFieldOrEditable(this.rendering.fields.linkDecorationOne) ||
                this.hasFieldOrEditable(this.rendering.fields.linkDecorationTwo) ||
                this.rendering.fields.popover);
    }
}
