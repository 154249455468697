import { Component, Injector, Input } from '@angular/core';
import { SitecoreComponent } from 'components/shared/sitecore';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss';

@Component({
    selector: 'supported-device',
    styleUrls: [ './supported-device.scss' ],
    templateUrl: './supported-device.html'
})
export class SupportedDeviceComponent extends SitecoreComponent {
    @Input() public rendering: ComponentRendering;

    constructor(injector: Injector) {
        super(injector);
    }
}
