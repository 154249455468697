export const DEFAULT_PAGE: TDDPage = {
    affiliate: {
        channel: 'web',
        name: 'comcast'
    },
    codeBase: {
        name: 'd2c-marketing'
    },
    attribute: {
        isPageLoadVariables: true
    },
    category: {
        primaryCategory: 'learn',
        subCategory1: 'learn',
        designType: 'responsive',
        businessType: 'busn_cbm',
        siteType: 'sales',
        global: {
            primaryCategory: 'learn'
        }
    },
    pageInfo: {
        pageName: '',
        contentType: 'deals',
        pageURL: '',
        screenName: 'deals'
    },
    siteInfo: {
        siteVersion: '',
        siteErrors: '',
        sessionID: '497c713-186f-4015-b7ce',
        responsive: 'yes',
        server: 'PATAPLL-J72NXW1',
        visitorID: ''
    }
};
