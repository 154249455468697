import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { share, timeout } from 'rxjs/operators';
import { SessionStorage } from 'core/services/storage/session';
import { IXMOptions } from 'core/interfaces';
import { ApiChannel, CONFIG_TOKEN, StorageToken } from 'core/constants';
import { HttpOptions, IHttpOptions, RequestMethod } from './options';
import { CimaToken } from '../cima/token';
import { Util } from 'src/marketing/core/utils/util';

@Injectable({
    providedIn: 'root'
})
export class XmHttp {
    private config: IXMOptions;
    private http: HttpClient;
    private cimaToken: CimaToken;
    private sessionStorage: SessionStorage;

    constructor(cimaToken: CimaToken, http: HttpClient, sessionStorage: SessionStorage, @Inject(CONFIG_TOKEN) config: IXMOptions) {
        Object.assign(this, { cimaToken, http, sessionStorage, config });
    }

    public get(channelCode: ApiChannel, path: string, options?: IHttpOptions): Observable<object> {
        return this.sendApiCall(RequestMethod.GET, this.getHeaders(channelCode, options, true), this.createUrl(channelCode, path), undefined, options);
    }

    public post(channelCode: ApiChannel, path: string, body?: object | string, options?: IHttpOptions): Observable<object> {
        return this.sendApiCall(RequestMethod.POST, this.getHeaders(channelCode, options), this.createUrl(channelCode, path), body, options);
    }

    public put(channelCode: ApiChannel, path: string, body: object, options?: IHttpOptions): Observable<object> {
        return this.sendApiCall(RequestMethod.PUT, this.getHeaders(channelCode, options), this.createUrl(channelCode, path), body, options);
    }

    public patch(channelCode: ApiChannel, path: string, body: object, options?: IHttpOptions): Observable<object> {
        return this.sendApiCall(RequestMethod.PATCH, this.getHeaders(channelCode, options), this.createUrl(channelCode, path), body, options);
    }

    public delete(channelCode: ApiChannel, path: string, body?: object, options?: IHttpOptions): Observable<object> {
        return this.sendApiCall(RequestMethod.DELETE, this.getHeaders(channelCode, options), this.createUrl(channelCode, path), body, options);
    }

    private getHeaders(channelCode: ApiChannel, options?: IHttpOptions, getCall: boolean = false): HttpHeaders {
        let header: HttpHeaders = new HttpHeaders({});

        if (!getCall) {
            header = header.set('Content-Type', 'application/json');
        }

        switch (channelCode) {
            case ApiChannel.GATEWAY:
                header = header.set('Content-Type', 'application/json');
                header = header.set('Authorization', `Bearer ${this.cimaToken.accessToken}`);
                header = header.set('X-channel', 'WEB');
                header = header.set('X-global-tracking-id', Util.generateUuid());
                break;
            case ApiChannel.SESSION_API:
                header = header.set('Content-Type', 'application/json');
            case ApiChannel.BOOTSTRAP_API:
                header = header.set('x-api-key', `${this.config.BOOTSTRAP_X_API_KEY}`);
                header = header.set('X-Channel', 'WEB');
                header = header.set('tracking-id', this.sessionStorage.get(StorageToken.VISITOR_SESSION_ID));
                break;
        }

        if (options && options.httpHeaders) {
            options.httpHeaders.forEach((httpHeader: HttpHeader) => {
                header = header.set(httpHeader.name, httpHeader.value);
            });
        }

        return header;
    }

    private createUrl(channelCode: ApiChannel, path: string): string {
        switch (channelCode) {
            case ApiChannel.GATEWAY:
                return `${this.config.GATEWAY_BASE_URL}/${path}`;
            case ApiChannel.CMS: {
                if (this.config.JSON) {
                    return `${this.config.CMS_URL}/${path}.json`;
                }
           

                // ALWAYS send sc_mode as normal... in SC Editor we don't call this API since it is rendered server-side
                let url: string = `${this.config.CMS_API_URL}/sitecore/api/layout/render/jss?item=/${path}&sc_mode=normal&sc_lang=${this.config.SITECORE_LANG}&sc_apikey=${this.config.SITECORE_KEY}`;

                if (this.config.SITECORE_SITE) {
                    url += `&sc_site=${this.config.SITECORE_SITE}`;
                }

                return url;
            }
            case ApiChannel.COVERAGE:
                return `${this.config.COVERAGE_CHECKER_URL}/${path}`;
            case ApiChannel.PCAT:
                return `${this.config.PCAT_BASE_URL}/${path}`;
            case ApiChannel.SESSION_API:
                return `${this.config.VISITOR_SESSION_URL}/${path}`;
            case ApiChannel.BOOTSTRAP_API: 
                return `${this.config.BOOTSTRAP_API_URL}/${path}`
        }
    }

    private sendApiCall(method: string, headers: HttpHeaders, fullPath: string, body?: object | string, overrideOptions?: IHttpOptions): Observable<object> {
        const options: HttpOptions = new HttpOptions(overrideOptions);
        let requestOptions: object = {
            headers
        };

        if (body) {
            requestOptions = { ...requestOptions, body };
        }

        if (!options.onlyJson) {
            requestOptions = { ...requestOptions, observe: 'response' };
        }

        const httpCall: Observable<object> = this.http.request(method, fullPath, requestOptions).pipe(
            timeout(options.timeout),
            share()
        );

        return httpCall;
    }
}
