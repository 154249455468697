import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

export class FormUtil {
    public static blurActiveControl(onlyFormElements: boolean = true): void {
        const control: HTMLElement = <HTMLElement> document.activeElement;
        const tag: string = control.tagName;

        if ((!onlyFormElements) || (tag === 'INPUT' || tag === 'SELECT')) {
            control.blur();
        }
    }

    public static focusFirstError(): void {
        setTimeout(() => {
            const element: HTMLElement = document.querySelector('input.ng-invalid, select.ng-invalid, [xmRadioControl].ng-invalid input, xm-dropdown.ng-invalid .select-box');

            if (element) {
                element.focus();
            }
        }, 0);
    }

    public static forceControlsDirty(fg: UntypedFormGroup): void {
        Object.keys(fg.controls).forEach((key: string) => {
            const control: AbstractControl = fg.controls[key];
            control.markAsDirty();
            control.markAsTouched();
            control.updateValueAndValidity({
                emitEvent: false
            });
        });
    }

    public static submitForm(fg: UntypedFormGroup, callback: Function): void {
        FormUtil.blurActiveControl();
        FormUtil.forceControlsDirty(fg);
        FormUtil.focusFirstError();
        callback();
    }

    public static validators(errors: XmInputError[] = []): ValidatorFn[] {
        if (!errors) {
            return;
        }
        const validators: ValidatorFn[] = [];
        errors.forEach((error: XmInputError) => {
            if (error.code === 'required') {
                validators.push(this.requiredValidator(error));
            } else if (error.regex) {
                validators.push(this.regexValidator(error));
            } else if (error.minLength) {
                validators.push(this.lengthValidator(error));
            }
        });

        return validators;
    }

    private static regexValidator(error: XmInputError): ValidatorFn {
        return (control: AbstractControl): XmInputError => !new RegExp(error.regex).test(control.value) ? error : undefined;
    }

    private static lengthValidator(error: XmInputError): ValidatorFn {
        return (control: AbstractControl): XmInputError => (control.value || '').length < error.minLength ? error : undefined;
    }

    private static requiredValidator(error: XmInputError): ValidatorFn {
        return (control: AbstractControl): XmInputError => !control.value ? error : undefined;
    }
}
