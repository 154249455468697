import { LayoutServiceData, RouteData } from '@sitecore-jss/sitecore-jss-angular';

import { ModelBase } from 'core/store/model-base';

export class CmsPageData extends ModelBase {
    public static storeName: string = 'cmsPageData';

    public sitecorePage: LayoutServiceData;
    public pageId: string;

    public get routeData(): RouteData {
        return this.sitecorePage.sitecore.route;
    }
}
