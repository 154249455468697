<section [ngClass]="{ 'small': isSmall, 'medium': isMedium, 'large': isLarge }" [ngStyle]="{ backgroundColor: pageData.backgroundColor || 'white' }">
    <xm-media-image *ngIf="image" class="image" [imageOptions]="image"></xm-media-image>
    <div class="text">
        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.header)">
            <h3 *scRichText="rendering.fields.header"></h3>
        </ng-container>
        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.body)">
            <p *scRichText="rendering.fields.body"></p>
        </ng-container>
        <xm-generic-link class="card cta" *ngIf="hasFieldOrEditable(rendering.fields.genericLink)" [field]="rendering.fields.genericLink"></xm-generic-link>
        <xm-cms-text class="card cta" *ngIf="hasFieldOrEditable(rendering.fields.cta)" [field]="rendering.fields.cta"></xm-cms-text>
    </div>
</section>
