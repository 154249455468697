import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SearchWindowService {

    public isVisible: boolean = false;

    public open = () => {
        this.isVisible = true;
    }

    public close = () => {
        this.isVisible = false;
    }

    public toggle = () => {
        this.isVisible = !this.isVisible;
    }
}
