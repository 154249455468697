import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiChannel } from 'core/constants';
import { XmHttp } from 'services/http/http';
import { XmStore } from 'services/store';
import { Coverage } from 'core/store/coverage/model/coverage';
import { ModelBase } from '../model-base';
import { StoreAction } from 'core/store-actions';

@Injectable()
export class CoverageApi {
    public static getCoverage(_xmStore: XmStore, xmHttp: XmHttp, param: StoreFilters): Observable<Coverage> {
        return xmHttp.get(ApiChannel.COVERAGE, `network/coverage?zip=${param.zipcode}`).pipe(
            map((response: Coverage) => {
                response.zipcode = <string> param.zipcode;

                return Coverage.create(response);
            })
        );
    }
}

ModelBase.fetchMapping[StoreAction.GET_COVERAGE] = CoverageApi.getCoverage;
