import { Component, Inject, Injector } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ComponentFields, Field } from '@sitecore-jss/sitecore-jss';

import { SitecoreComponent } from 'components/shared/sitecore';

@Component({
    selector: 'xm-descriptive-modal',
    templateUrl: 'descriptive.html',
    styleUrls: [ 'descriptive.scss' ]
})
export class DescriptiveModal extends SitecoreComponent {
    public data: ComponentFields;
    public hasActions: boolean = false;

    private dialogRef: MatDialogRef<DescriptiveModal>;

    constructor(injector: Injector, dialogRef: MatDialogRef<DescriptiveModal>, @Inject(MAT_DIALOG_DATA) data: ComponentFields) {
        super(injector);
        dialogRef.disableClose = false;
        Object.assign(this, { data, dialogRef });
        this.hasActions = this.checkForActions();
    }

    public ngOnInit(): void {
        this.updateCopyrightYear();

    }

    public close(action: string): void {
        const response: ModalResponse = {
            close: action === 'close',
            primary: action === 'primary',
            secondary: action === 'secondary'
        };

        this.dialogRef.close(response);
    }

    private checkForActions(): boolean {
        return Boolean(this.hasFieldOrEditable(this.data.ctaOne) ||
                this.hasFieldOrEditable(this.data.ctaTwo) ||
                this.hasFieldOrEditable(this.data.linkDecorationOne) ||
                this.hasFieldOrEditable(this.data.linkDecorationTwo) ||
                this.data.popover);
    }

    private updateCopyrightYear(): void {
        const currentYear: number = new Date().getFullYear();
        const key = 'length';
        if (this.data.descriptions[key] > 0) {
            const index = this.data.descriptions[key] - 1;
            const description = (<Field> this.data.descriptions[index].fields.paragraph).value;
            (<Field> this.data.descriptions[index].fields.paragraph).value = description ? description.toString().replace(/YEAR/g, currentYear.toString()) : '';
        }
    }
}
