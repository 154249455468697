import { Component, HostBinding, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { Subscription } from 'rxjs';

import { SitecoreComponent } from 'components/shared/sitecore';
import { CimaToken, Cloudinary } from 'core/services';
import { Util } from 'core/utils/util';

@Component({
    selector: 'tout-component',
    styleUrls: [ './tout.scss' ],
    templateUrl: './tout.html'
})
export class MarketingToutComponent extends SitecoreComponent implements OnDestroy, OnInit {
    @HostBinding('class.swiper-slide') public showSwiperSlideClass: boolean;
    @HostBinding('class.hide-tout') public hideToutClass: boolean;

    @Input() public rendering: ComponentRendering;
    @Input() public isCarousel: boolean;
    @Input() public enableBusinessMessaging: boolean;
    @Input() public isCardMode: boolean;

    public image: MediaImageOptions;
    public businessImage: MediaImageOptions;
    public pageData: MarketingToutCard;
    public showBusinessContent: boolean;

    private cimaToken: CimaToken;
    private subscriptions: Subscription[] = [];
    private hasBusinessContent: boolean;
    private hasResiContent: boolean;

    constructor(injector: Injector, cimaToken: CimaToken) {
        super(injector);

        Object.assign(this, { cimaToken });
    }

    public ngOnInit(): void {
        this.showSwiperSlideClass = this.isCarousel;
        this.showBusinessContent = this.enableBusinessMessaging;
        this.pageData = this.flattenFields<MarketingToutCard>(this.rendering.fields);
        // this.pageData.image.small.base = 'assets/img/unl-savings.svg';
        this.image = this.pageData.image && Cloudinary.generateMediaOptionsFromCms(this.pageData.image);
        this.businessImage = this.pageData.businessImage && Cloudinary.generateMediaOptionsFromCms(this.pageData.businessImage);
        this.hasBusinessContent = Boolean(this.pageData.businessHeader || this.pageData.businessBody || this.businessImage);
        this.hasResiContent = Boolean(this.pageData.image || this.pageData.header || this.pageData.body);
        this.hideToutClass = !this.hasResiContent;

        // For business users, if they have "enableBusinessMessaging" flag enabled, show or hide tout based on the content availability.
        // For residential, non-authenticated and business users(with "enableBusinessMessaging" flag disabled), show or hide tout based on the content availability.
        if (this.enableBusinessMessaging) {
            this.subscriptions.push(this.cimaToken.loginChange.subscribe(() => {
                const hasContent: boolean = this.cimaToken.isBusinessUser && this.hasBusinessContent || this.hasResiContent;
                this.hideToutClass = !hasContent;
                this.showBusinessContent = this.cimaToken.isBusinessUser && this.hasBusinessContent;
            }));
        }
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }
}
