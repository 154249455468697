import { StoreAction, XmAction } from 'core/store-actions';
import { AccountInfo } from './model/account';

export function accountReducer(state: AccountInfo, action: XmAction<AccountInfo>): AccountInfo {
    switch (action.type) {
        case StoreAction.GET_ACCOUNT:
            return action.payload;
        default:
            return state;
    }
}
