<section #parentRef>
    <div class="options-wrapper">
        <div class="title-container">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.header)">
                <h2 *scRichText="rendering.fields.header"></h2>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.description)">
                <p class="options-description" *scRichText="rendering.fields.description"></p>
            </ng-container>
        </div>
        <div class="feature">
            <div class="offer">
                <xm-media-image *ngIf="byTheGigIcon" class="options-images" [imageOptions]="byTheGigIcon"></xm-media-image>
                <ng-container *ngIf="hasFieldOrEditable(rendering.fields.byTheGigHeader)">
                    <h3 class="title" *scRichText="rendering.fields.byTheGigHeader"></h3>
                </ng-container>
                <ng-container *ngIf="hasFieldOrEditable(rendering.fields.byTheGigSubtitle)">
                    <p class="offer-options" *scRichText="rendering.fields.byTheGigSubtitle"></p>
                </ng-container>
                <div class="offer-details">
                    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.byTheGigStartingGb)">
                        <p class="details-header" *scRichText="rendering.fields.byTheGigStartingGb"></p>
                    </ng-container>
                    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.byTheGigPrice)">
                        <div class="details-pricing" *scRichText="rendering.fields.byTheGigPrice"></div>
                    </ng-container>
                    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.byTheGigPerMonth)">
                        <p class="details-options" *scRichText="rendering.fields.byTheGigPerMonth"></p>
                    </ng-container>
                </div>
                <div class="data-options">
                    <ul class="gb-container">
                        <li class="gb-labels">
                            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.oneGigHeader)">
                                <p class="gig-value" *scRichText="rendering.fields.oneGigHeader"></p>
                            </ng-container>
                            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.oneGigPrice)">
                                <p class="gig-price" *scRichText="rendering.fields.oneGigPrice"></p>
                            </ng-container>
                        </li>
                        <li class="gb-labels">
                            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.threeGigHeader)">
                                <p class="gig-value" *scRichText="rendering.fields.threeGigHeader"></p>
                            </ng-container>
                            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.threeGigPrice)">
                                <p class="gig-price" *scRichText="rendering.fields.threeGigPrice"></p>
                            </ng-container>
                        </li>
                        <li class="gb-labels">
                            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.tenGigHeader)">
                                <p class="gig-value" *scRichText="rendering.fields.tenGigHeader"></p>
                            </ng-container>
                            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.tenGigPrice)">
                                <p class="gig-price" *scRichText="rendering.fields.tenGigPrice"></p>
                            </ng-container>
                        </li>
                    </ul>
                    <xm-popover
                        *ngIf="rendering.fields.byTheGigPopover"
                        [rendering]="rendering.fields.byTheGigPopover"
                        [parentTemplate]="parentRef"
                        position="center">
                    </xm-popover>
                </div>
            </div>
            <div class="horizontal-separator">
                <div class="horizontal-title">
                    <xm-media-image *ngIf="mixAndMatchIcon" class="mix-match-icon" [imageOptions]="mixAndMatchIcon"></xm-media-image>
                    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.mixAndMatch)">
                        <span class="mix-and-match" *scRichText="rendering.fields.mixAndMatch"></span>
                    </ng-container>
                </div>
            </div>
            <div class="offer-unlimited">
                <xm-media-image *ngIf="unlimitedIcon" class="options-images" [imageOptions]="unlimitedIcon"></xm-media-image>
                <ng-container *ngIf="hasFieldOrEditable(rendering.fields.unlimitedHeader)">
                    <h3 class="title" *scRichText="rendering.fields.unlimitedHeader"></h3>
                </ng-container>
                <ng-container *ngIf="hasFieldOrEditable(rendering.fields.unlimitedSubtitle)">
                    <p class="offer-options" *scRichText="rendering.fields.unlimitedSubtitle"></p>
                </ng-container>
                <div class="offer-details">
                    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.unlimitedAvailable)">
                    <p class="details-header" *scRichText="rendering.fields.unlimitedAvailable"></p>
                    </ng-container>
                    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.unlimitedPrice)">
                        <div class="details-pricing" *scRichText="rendering.fields.unlimitedPrice"></div>
                    </ng-container>
                    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.unlimitedPerLine)">
                        <p class="details-options" *scRichText="rendering.fields.unlimitedPerLine"></p>
                    </ng-container>
                </div>
                <div class="data-options">
                    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.unlimitedDescription)">
                        <p class="offer-options-unlimited" *scRichText="rendering.fields.unlimitedDescription"></p>
                    </ng-container>
                    <xm-popover
                        *ngIf="rendering.fields.unlimitedPopover"
                        [rendering]="rendering.fields.unlimitedPopover"
                        [parentTemplate]="parentRef"
                        position="center">
                    </xm-popover>
                </div>
            </div>
            <div class="vertical-separator">
                <div class="vertical-title">
                    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.mixAndMatch)">
                        <span *scRichText="rendering.fields.mixAndMatch"></span>
                    </ng-container>
                    <xm-media-image *ngIf="mixAndMatchIcon" class="mix-match-icon" [imageOptions]="mixAndMatchIcon"></xm-media-image>
                </div>
            </div>
        </div>
        <div class="explore-details">
            <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.exploreButton)" class="explore" [field]="rendering.fields.exploreButton"></xm-cms-text>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.disclaimer)">
                <p class="reduce-description" *scRichText="rendering.fields.disclaimer"></p>
            </ng-container>
        </div>
    </div>
</section>
