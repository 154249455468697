import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { XmHttp } from 'services/http/http';
import { XmStore } from 'services/store';
import { ApiChannel } from 'core/constants';
import { AccountInfo } from 'core/store/account/model/account';
import { StoreAction } from 'core/store-actions';
import { ModelBase } from '../model-base';

@Injectable()
export class AccountApi {
    public static getAccount(_xmStore: XmStore, xmHttp: XmHttp): Observable<AccountInfo> {
        return xmHttp.get(ApiChannel.GATEWAY, 'customer/account').pipe(
            map((response: AccountInfo) => AccountInfo.create(response))
        );
    }
}

ModelBase.fetchMapping[StoreAction.GET_ACCOUNT] = AccountApi.getAccount;
