import { InjectionToken } from '@angular/core';
import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { LocalDB } from './local-db';
import { StoreAction, XmAction } from 'core/store-actions';
import { accountReducer } from './account/reducer';
import { cartReducer } from './cart/reducer';
import { coverageReducer } from './coverage/reducer';
import { cmsPageDataReducer } from './cms/reducer';
import { catalogReducer } from './catalog/reducer';

export const reducers: ActionReducerMap<LocalDB, XmAction<any>> = {
    accountInfo: accountReducer,
    cart: cartReducer,
    coverage: coverageReducer,
    cmsPageData: cmsPageDataReducer,
    catalog: catalogReducer
};

export const REDUCER_TOKEN: InjectionToken<ActionReducerMap<XmAction<any>>> = new InjectionToken<ActionReducerMap<XmAction<any>>>('reducers');

export function clearState(reducer: ActionReducer<LocalDB>): ActionReducer<LocalDB> {
    return (state: LocalDB, action: Action): LocalDB => {
        if (action.type === StoreAction.CLEAR_ON_SUBMIT) {
            Object.keys(state).forEach((key: string) => {
                state[key] = undefined;
            });
        }

        return reducer(state, action);
    };
}

export const metaReducers: MetaReducer<LocalDB>[] = [ clearState ];
