import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { StateService } from '@uirouter/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

const sitecore = require('./5xx.msg.json').sitecore;

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {
    private state: StateService;

    constructor(state: StateService) {
        Object.assign(this, { state });
    }

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    if (Number(error.status) !== 404) {
                        return of(new HttpResponse({ body: { sitecore } }));
                    }

                    this.state.go('404', {}, { location: false });
                })
            );
    }
}