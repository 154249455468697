import { Inject, Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { Subscription } from 'rxjs';

import { CmsService } from '../cms';
import { CONFIG_TOKEN } from 'core/constants';
import { IXMOptions } from 'core/interfaces';
import { XmStoreUtil } from '../store';

@Injectable({
    providedIn: 'root'
})
export class AbTestService {
    public abTests: AbTestUid[] = [];

    private config: IXMOptions;
    private cmsService: CmsService;
    private uiRouter: UIRouter;
    private subscriptions: Subscription[] = [];
    private initialLoad: boolean = true;

    constructor(@Inject(CONFIG_TOKEN) config: IXMOptions, cmsService: CmsService, uiRouter: UIRouter) {
        Object.assign(this, { config, cmsService, uiRouter });
    }

    /**
     * 1. Call getTests() function before router success for the first time otherwise route won't resolve.
     * 2. On every route change call getTests() which will make a notification call to target
     */
    public loadTarget(): Promise<string> {
        const offerFunction: boolean = window.AdobeTeamUtils && typeof window.AdobeTeamUtils.getPrefetchedOfferPromise === 'function' && typeof window.AdobeTeamUtils.getCurrentViewMboxList === 'function';
        if (this.config.ENABLE_AB_TESTS && offerFunction) {
            this.subscriptions.push(XmStoreUtil.subscribe(this.uiRouter.globals.success$, () => {
                if (!this.initialLoad) {
                    this.getTests();
                } else {
                    this.initialLoad = false;
                }
            }));

            return this.getTests();
        }

        return Promise.resolve('Adobe Target Library Not Found');
    }

    public getTests(): Promise<string> {
        return new Promise((resolve: Function) => {
            window.AdobeTeamUtils.getPrefetchedOfferPromise({ mboxList: window.AdobeTeamUtils.getCurrentViewMboxList() }).then((response: AbTestData[]) => {
                this.abTests = this.flattenResponse(response);
                this.logAbTestData(`AbTests processed responses: ${JSON.stringify(this.abTests)}`);
                this.cmsService.setTestData(this.abTests);
                resolve('Adobe Target Library Loaded');
            }, (error: string) => {
                this.logAbTestData(`AbTests error: ${JSON.stringify(error)}`);

                resolve(error);
            });
        });
    }

    /**
     * Check if the right data type/structure is passed or not
     * If yes, remove "testData" key from every test activity and spread the array response.
     */
    private flattenResponse(response: AbTestData[]): AbTestUid[] {
        if (Array.isArray(response)) {
            return response.flatMap((singleActivity: AbTestData) => Array.isArray(singleActivity.testData) && singleActivity.testData.filter(Boolean));
        }

        return [];
    }

    private logAbTestData(data: string): void {
        if (window.logAbTests) {
            /* eslint-disable  no-console */
            console.log(data);
            /* eslint-enable  no-console */
        }
    }
}
