<section class = "{{ theme }}" (mouseenter)="mouseHoverEvent($event)" (mouseleave)="mouseHoverEvent($event)">
    <div class="header">
        <button
            class="popover {{ linkClass }} {{customClass}}"
            [ngClass]="{ 'byod-promo': showByodPromoStyles }"
            *scRichText="rendering.fields.buttonText"
            (click)="viewModal()">
        </button>
    </div>
    <div class="overlay" [ngClass]="{ 'show-overlay': showOverlay , 'hide-overlay': !showOverlay }" [attr.aria-hidden]="!showOverlay">
        <div class="overlay-header" clickable>
            <h2 class="{{ headerClass }}" (click)="viewModal()" *scRichText="modalData.header" clickable></h2>
            <xm-media-image *ngIf="pageData.modalLinkIcon" class="icons" [imageOptions]="generateImageFromCms(pageData.modalLinkIcon)" (click)="viewModal()" >
            </xm-media-image>
        </div>
        <div class="content {{ bodyContentClass }}">
            <xm-cms-text *ngFor="let description of modalData.descriptions" [field]="description.fields.paragraph"></xm-cms-text>
        </div>
    </div>
</section>
