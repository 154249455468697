import { Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { WindowReference } from 'core/services';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { AnimationStates } from 'core/constants';

@Component({
    selector: 'xm-animPlayer',
    styleUrls: [ './anim-player.scss' ],
    templateUrl: './anim-player.html'
})
export class MarketingAnimationComponent implements OnInit, OnDestroy {
    @Input() public options: AnimationOptions;
    @ViewChild(LottieComponent, { static: true, read: ElementRef }) public animation: ElementRef<HTMLElement>;

    public animationItem: AnimationItem;
    public animationState: AnimationPlayState;
    public vPos: number;
    public isVisible: boolean;
    public scrolling: boolean = false;

    private timeout: number;
    private windowRef: WindowReference;
    private scrollTracker: Subscription;
    private ngZone: NgZone;

    constructor(
            ngZone: NgZone,
            windowRef: WindowReference
        ) {
        Object.assign(this, {
            ngZone, windowRef
        });
    }

    public ngOnInit(): void {

        if (WindowReference.isWindowAvailable) {
            this.scrollTracker = this.windowRef.scrollDebounce.subscribe(() => {
                this.isVisible = this.determineInView(this.animation.nativeElement);

                if (this.isVisible && this.animationState !== AnimationStates.FINISHED) {
                    this.animationPlay();
                    this.scrollTracker.unsubscribe();
                }
            });
        }
    }

    public determineInView(anim: HTMLElement): boolean {
        // wait for the SVG to be in the view before running the animation
        if (anim) {
            if (window.pageYOffset + window.innerHeight > anim.offsetTop && window.pageYOffset < anim.offsetTop + anim.clientHeight) {
                return true;
            }
        }
    }

    public ngOnDestroy(): void {
        if (this.scrollTracker) {
            this.scrollTracker.unsubscribe();
        }
    }

    public animationCreated(animationItem: AnimationItem): void {
        this.animationState = AnimationStates.IDLE;
        this.animationItem = animationItem;
        this.isVisible = this.determineInView(this.animation.nativeElement);

        if (this.isVisible) {
            this.animationPlay();
        }

    }

    public animationPause(): void {
        if (this.animationState === AnimationStates.RUNNING) {
            this.ngZone.run(() => {
                this.animationItem.pause();
            });
            this.animationState = AnimationStates.PAUSED;
        }
    }

    public animationContinue(): void {
        if (this.animationState === AnimationStates.PAUSED) {
            this.ngZone.run(() => {
                this.animationPlay();
            });
            this.animationState = AnimationStates.RUNNING;
        }
    }

    public animationStop(): void {
        if (this.animationState !== (AnimationStates.IDLE || AnimationStates.FINISHED)) {
            this.ngZone.run(() => {
                this.animationItem.stop();
            });
            this.animationState = AnimationStates.IDLE;
        }
    }

    public animationPlay(): void {
        if (this.animationState !== AnimationStates.RUNNING) {
            clearTimeout(this.timeout);
            this.timeout = window.setTimeout(() => {
                this.ngZone.run(() => {
                    this.animationItem.play();
                });
            }, 500);
            this.animationState = AnimationStates.RUNNING;
        }
    }

    public loopComplete(): void {
        const targetFrame: number = this.animationItem.getDuration(true);
        this.ngZone.run(() => {
            this.animationItem.goToAndStop(targetFrame, true);
        });
        this.animationState = AnimationStates.FINISHED;
    }
}
