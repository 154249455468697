import { Injectable, RendererFactory2 } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ComponentFields } from '@sitecore-jss/sitecore-jss';
import { Observable } from 'rxjs';

import { DescriptiveModal } from 'components/shared/modal/descriptive/descriptive';
import { PromoModal } from 'components/shared/modal/promo/promo';

@Injectable({
    providedIn: 'root'
})
export class Modal {
    private dialog: MatDialog;
    private previousFocusElement: HTMLElement;

    constructor(dialog: MatDialog, overlayContainer: OverlayContainer, rendererFactory: RendererFactory2) {
        Object.assign(this, { dialog });

        // angular animations renderer hooks up the logic to disable animations into setProperty
        rendererFactory.createRenderer(undefined, undefined).setProperty(overlayContainer.getContainerElement(), '@.disabled', true);

        this.dialog.afterAllClosed.subscribe(() => {
            if (this.previousFocusElement) {
                this.previousFocusElement.focus();
                this.previousFocusElement = undefined;
            }
        });
    }

    public descriptive(data: ComponentFields): Observable<ModalResponse> {
        return this.open<ComponentFields>(this.dialog, DescriptiveModal, { data });
    }

    public promo(data: ComponentFields): Observable<ModalResponse> {
        return this.open<ComponentFields>(this.dialog, PromoModal, { data });
    }

    public setPreviousFocusElement(ele: HTMLElement): void {
        this.previousFocusElement = ele;
    }

    // D => Type definition for the data object
    // R => [OPTIONAL] Type for return data. You can give a strong type now or cast the any to a return type after the subscription
    // C => [OPTIONAL] Component/JS Class. It is inherently determined from the second parameter
    public open<D, R = any, C = any>(modalService: MatDialog, component: ComponentType<C>, dataOptions: MatDialogConfig<D>): Observable<R> {
        const dialogReference: MatDialogRef<C> = (modalService || this.dialog).open<C, D>(component, dataOptions);

        return dialogReference.afterClosed();
    }
}
