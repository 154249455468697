import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode, NgModuleRef } from '@angular/core';
import { IXMOptions } from 'core/interfaces';
import { CONFIG_TOKEN } from 'core/constants';
import { MarketingModule } from './marketing/module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

export async function optionsLoaded(apiData: Response): Promise<NgModuleRef<MarketingModule>> {
    const options: IXMOptions = await apiData.json();

    return platformBrowserDynamic(
        [
            {
                provide: CONFIG_TOKEN,
                useValue: options
            }
        ]
    ).bootstrapModule(MarketingModule);
}

fetch(`${window.xmOptions.fetchUrl}`, { credentials: 'same-origin' }).then(optionsLoaded);
