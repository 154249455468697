import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

/**
 * amount: Amount to be formatted (EXAMPLE: {{ amount | xmCurrency}})
 * parenthesis: True if you want your amount in parenthesis in case of negative value. (EXAMPLE: {{ amount | xmCurrency:'on'}})
 * freeLabel: True if you want 'Free' label (EXAMPLE: {{ amount | xmCurrency:'off':true}} or {{ amount | xmCurrency:'on':true}})
 * useDollar: False if you just want price value (EXAMPLE: {{ amount | xmCurrency:'off':false:false:false }} -> 39.99),
 *            True if you want '$' symbol before price value
 */

@Pipe({
    name: 'xmCurrency'
})
export class XmCurrencyPipe implements PipeTransform {
    private currencyPipe: CurrencyPipe;

    constructor(currencyPipe: CurrencyPipe) {
        this.currencyPipe = currencyPipe;
    }

    public transform(amount: number, parenthesis: string = 'off', freeLabel: boolean = false, removeCents: boolean = false, useDollar: boolean = true): string {
        // NOTE: you can't do !amount because amount is number
        if (amount === undefined) {
            return '';
        }

        if (freeLabel && amount === 0) {
            return 'Free';
        }

        const decimalFormat: string = removeCents ? '1.0-0' : '1.2-2';

        let result: string = '';

        // always make amount to postive because of undetermined behaviour of some API
        result = this.currencyPipe.transform(Math.abs(amount), 'USD', useDollar ? 'symbol' : '', decimalFormat);

        if (parenthesis === 'off') {
            return result;
        } else if (parenthesis === 'on') {
            return `(${result})`;
        } else if (parenthesis === 'auto') {
            return amount < 0 ? `(${result})` : result;
        }
    }
}
