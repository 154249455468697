<section #parentRef class="{{ alignment }} {{ pageData.class }}" [ngStyle]="{ backgroundColor: pageData.backgroundColor }">
    <div class="wrapper" [ngClass]="{ 'border-style': pageData.showBorder, 'reverse-content': reverseContent }">
        <div class="half">
            <xm-media-image class="icon" *ngIf="breakpointImages" [imageOptions]="breakpointImages" [isFullWidth]="true" ></xm-media-image>        
        </div>
        <div class="half content">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.title)">
                <div class="title text-spacing" *scRichText="rendering.fields.title" [ngStyle]="{ color: pageData.titleColor }"></div>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.header)">
                <div class="header text-spacing" *scRichText="rendering.fields.header" [ngStyle]="{ color: pageData.headerColor }"></div>
            </ng-container>
            <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.largeBody)" class="large-body text-spacing" [field]="rendering.fields.largeBody" [ngStyle]="{ color: pageData.largeBodyColor }"></xm-cms-text>
            <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.mediumBody)" class="medium-body text-spacing" [field]="rendering.fields.mediumBody" [ngStyle]="{ color: pageData.mediumBodyColor }"></xm-cms-text>
            <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.smallBody)" class="small-body text-spacing" [field]="rendering.fields.smallBody" [ngStyle]="{ color: pageData.smallBodyColor }"></xm-cms-text>
            <xm-popover
            *ngIf="rendering.fields.popover"
            class="popover-button"
            [rendering]="rendering.fields.popover"
            [parentTemplate]="parentRef"
            position="center">
            </xm-popover>
            <div class="actions button-spacing" *ngIf="hasActions">
                <xm-generic-link *ngIf="hasFieldOrEditable(rendering.fields.linkDecorationOne)" class="cta" [field]="rendering.fields.linkDecorationOne"></xm-generic-link>
                <xm-generic-link *ngIf="hasFieldOrEditable(rendering.fields.linkDecorationTwo)" class="cta" [field]="rendering.fields.linkDecorationTwo"></xm-generic-link>
                <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.ctaOne)" class="cta" [field]="rendering.fields.ctaOne"></xm-cms-text>
                <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.ctaTwo)" class="cta" [field]="rendering.fields.ctaTwo"></xm-cms-text>              
            </div>
            <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.disclaimerText)" class="disclaimer-text" [field]="rendering.fields.disclaimerText"></xm-cms-text>
            </div>
        </div>
</section>