import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Field } from '@sitecore-jss/sitecore-jss';
import { SitecoreComponent } from 'components/shared/sitecore';

@Component({
    selector: 'xm-modal-close',
    templateUrl: 'close.html',
    styleUrls: [ 'close.scss' ]
})

export class ModalCloseButton extends SitecoreComponent implements OnInit {
    @Input() public field: Field;
    @Output() public onClose: EventEmitter<void> = new EventEmitter<void>();
    public imageOptions: MediaImageOptions;
    public hasAdditionalActions: boolean = false;

    constructor(injector: Injector) {
        super(injector);
    }

    public ngOnInit(): void {
        this.imageOptions = {
            small: {
                base: 'Comcast-Business/LearnBuy/learn/icons/close_24_black.svg',
                version: '1500575503',
                alt: 'Modal Close button',
                icon: true,
                imageSize: 'size-width',
                imageCenter: 'center'
            }
        };
        this.hasAdditionalActions = this.checkForActions();
    }

    public closeClick(): void {
        this.onClose.emit();
    }

    private checkForActions(): boolean {
        return Boolean(this.hasFieldOrEditable(this.field));
    }
}
