<section #parentRef class="section-container {{sectionContainer}}" [ngStyle]="backgroundColorStyles" [ngClass]="{ 'left-banner': pageData.flipped }">
    <div class="banner-container">
        <div class="banner-detail" [ngClass]="{ 'flex': pageData.flipped }">
            <div class="image-container {{imageContainer}}">
                <xm-media-image [imageOptions]="breakpointImages"></xm-media-image>
            </div>
            <div class="text-container {{textContainer}}">
                <div class="{{ textAlignment }}">
                    <div class="texts">
                        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.smallTitle)">
                            <div class="small-title" *scRichText="rendering.fields.smallTitle" [ngStyle]="{ color: pageData.smallTitleColor }"></div>
                        </ng-container>
                        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.header)">
                            <div class="header" *scRichText="rendering.fields.header" [ngStyle]="{ color: pageData.headerColor }"></div>
                        </ng-container>
                        <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.description)" class="description" [ngStyle]="{ color: pageData.descriptionColor }" [field]="rendering.fields.description"></xm-cms-text>
                        <!-- Replace xm-cms-text with xm-decorated-text when we have support for backward compatibility  -->
                        <!-- <xm-decorated-text *ngIf="hasFieldOrEditable(rendering.fields.description)" class="description" [ngStyle]="{ color: pageData.descriptionColor }" [field]="rendering.fields.description"></xm-decorated-text> -->
                        
                        <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.smallBody)" class="small-body text-spacing" [field]="rendering.fields.smallBody"></xm-cms-text>
                        <xm-popover
                        *ngIf="rendering.fields.popover"
                        class="popover-button"
                        [rendering]="rendering.fields.popover"
                        [parentTemplate]="parentRef"
                        [customClass]="'planPage'"
                        position="center">
                        </xm-popover>
                    </div>
                    <div class="actions button-spacing" *ngIf="hasActions">
                        <xm-generic-link *ngIf="hasFieldOrEditable(rendering.fields.linkDecorationOne)" class="cta" [field]="rendering.fields.linkDecorationOne"></xm-generic-link>
                        <xm-generic-link *ngIf="hasFieldOrEditable(rendering.fields.linkDecorationTwo)" class="cta" [field]="rendering.fields.linkDecorationTwo"></xm-generic-link>
                        <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.buttonOne)" class="cta" [field]="rendering.fields.buttonOne"></xm-cms-text>
                        <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.buttonTwo)" class="cta" [field]="rendering.fields.buttonTwo"></xm-cms-text>
                    </div>
                    <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.disclaimerText)" class="disclaimer-text text-spacing" [field]="rendering.fields.disclaimerText"></xm-cms-text>
                </div>
            </div>
        </div>
    </div>
</section>