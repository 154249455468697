<section>
    <div class="banner" [ngStyle]="{ 'background-color': pageData.bannerBackgroundColor }">
        <xm-media-image class="banner-image" [imageOptions]="bannerImage"></xm-media-image>
    </div>
    <div *ngIf="!pageData.hideForm" class="email-collection" [ngStyle]="{ 'background-color': pageData.textBackgroundColor }">
        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.header)">
            <div *scRichText="rendering.fields.header" class="header {{ textAlignment }}" [ngStyle]="{ 'color': pageData.headerColor }"></div>
        </ng-container>
        <div *ngIf="!formSubmitted" class="form-info">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.description)">
                <div *scRichText="rendering.fields.description" class="description {{ textAlignment }}" [ngStyle]="{ 'color': pageData.descriptionColor }"></div>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.supportedDeviceHeader)">
                <div *scRichText="rendering.fields.supportedDeviceHeader" class="supported-device-header {{ textAlignment }}"></div>
            </ng-container>
            <div *ngIf="hasPlaceholder(rendering, 'supportedDevices')" class="supported-device">
                <ul sc-placeholder name="supportedDevices" [rendering]="rendering"></ul>
            </div>
            <form id="signUp" [action]="iframeUrl" method="post" target="response-iframe" [formGroup]="formGroup" >
                <input type="hidden" name="_ri_" [value]="pageData.formValue">
                <input type="hidden" name="COMCAST_MARKETING" value="Y">
                <div class="input-group">
                    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.emailHeader)">
                        <label for="email" class="email-label" *scRichText="rendering.fields.emailHeader"></label>
                    </ng-container>
                    <input name="EMAIL_ADDRESS_" type="email" id="email" formControlName="email" [ngClass]="{ 'input-error': emailFormControl.errors && emailFormControl.dirty && emailFormControl.touched }"/>
                    <ng-container *ngIf="emailFormControl.errors && emailFormControl.dirty && emailFormControl.touched">
                        <div class="error" [innerHtml]="emailFormControl.errors.message"></div>
                    </ng-container>
                    <div class="submit-cta">
                        <button class="form-cta xm-btn-primary-light" *scRichText="rendering.fields.formCta" type="submit" (click)="submit()"></button>
                    </div>
                </div>
            </form>
            <iframe id="response-iframe" name="response-iframe"></iframe>
        </div>
        <div *ngIf="inEditModeOr(formSubmitted)" class="confirmation-info">
            <xm-media-image [imageOptions]="confirmationIcon" class="confirmation-icon"></xm-media-image>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.confirmationTitle)">
                <div *scRichText="rendering.fields.confirmationTitle" class="confirmation-header" [ngStyle]="{ 'color': pageData.confirmationTitleColor }"></div>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.confirmationDescription)">
                <div *scRichText="rendering.fields.confirmationDescription" class="confirmation-description" [ngStyle]="{ 'color': pageData.confirmationDescriptionColor }"></div>
            </ng-container>
            <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.confirmationCta)" class="confirmation-cta" [field]="rendering.fields.confirmationCta"></xm-cms-text>
        </div>
    </div>
</section>
