import { NgModule } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';

import { ModalCloseButton } from './close/close';
import { DescriptiveModal } from './descriptive/descriptive';
import { SharedModule } from '../module';
import { PromoModal } from './promo/promo';

@NgModule({
    providers: [
        // set configs for all modals
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: Object.assign(new MatDialogConfig(), {
                disableClose: true,
                maxWidth: '960px'
            })
        }
    ],
    declarations: [
        DescriptiveModal,
        ModalCloseButton,
        PromoModal
    ],
    exports: [
        MatDialogModule,
        DescriptiveModal,
        ModalCloseButton,
        PromoModal
    ],
    imports: [
        MatDialogModule,
        SharedModule
    ]
})
export class ModalModule {}
