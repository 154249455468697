import { Inject, Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { CONFIG_TOKEN } from 'core/constants';
import { IXMOptions } from 'core/interfaces';
import { WindowReference } from './window';

/*
    Functions:
    Functions cannot be nested
    There can be multiple different functions in one input string.

        1. Link with link style
        Example: link(Shop Now|https://www.xfinity.com|Shop Xfinity Now)
        Another use case: Inline link with inherited text style except font-color
        Example: Like Xfinity? link(Shop Now|https://www.xfinity.com|Shop Xfinity Now)

        2. Text with inherited text style but overwritten with bold font
        Example: bold(Bugs!)

        3. Text with inherited text style but with strikethrough
        Example: strike(Bugs!) Feature!

    Params (can be used in any of the functions above):

        1. Link with accessibility text
        Example: link(Shop Now|https://www.xfinity.com|Shop Xfinity Now)

        2. Link without accessibility text
        Example: link(Shop Now|https://www.xfinity.com)

        3. Link that opens in a new tab
        Example: link(tab|Shop Now|https://www.xfinity.com|Shop Xfinity Now)
*/

export interface TextDecorationInfo {
    newTab?: boolean;
    target?: string;
    displayText?: string;
    fullUrl?: string;
    a11yText?: string;
    isPlainText?: boolean;
    isLink?: boolean;
    isPrimary?: boolean;
    isSecondary?: boolean;
    inheritParentStyles?: boolean;
    isBold?: boolean;
    isStrikeThrough?: boolean;
    isNavMenu?: boolean;
    isNavSubMenu?: boolean;
    isNavDropdownSubMenu?: boolean;
    isNavMobileMenu?: boolean;
    isRefreshButton?: boolean;
}

export interface LinkDecorationInfo extends TextDecorationInfo {
    uiSref?: string;
    uiParams?: object;
    absoluteUrl?: SafeUrl;
}

@Injectable({
    providedIn: 'root'
})
export class Decorator {
    public static DIGITAL_LEARN_URL: RegExp = /^http[s]?:\/\/business\.comcast\.com\/learn\/mobile(.*)/;
    private static LINK_FUNCTION_NAME: string = 'LINK';
    private static PRI_FUNCTION_NAME: string = 'PRIMARY';
    private static SEC_FUNCTION_NAME: string = 'SECONDARY';
    private static BOLD_FUNCTION_NAME: string = 'bold';
    private static STRIKE_FUNCTION_NAME: string = 'strike';
    private static ALL_REPLACEABLES: RegExp = /(link|bold|strike)\((.+?)\)/g;
    private static OPEN_IN_NEW_TAB: string = 'tab';
    private static PLAIN_TEXT_SPLITTER: string = '[]';
    private static PARAM_SPLITTER: string = '|';
    private static DROPDOWN_SUBMENU_LINK_NAME: string = 'DROPDOWN-SUBMENU-LINK';
    private static SUBMENU_LINK_NAME: string = 'SUBMENU-LINK';
    private static MOBILE_LINK_NAME: string = 'MOBILE-LINK';

    private sanitizer: DomSanitizer;

    public static transformReplaceable(functionName: string, paramString: string): TextDecorationInfo {
        const replaceableParams: string[] = paramString.split(Decorator.PARAM_SPLITTER);
        const newTab: boolean = replaceableParams[0] === Decorator.OPEN_IN_NEW_TAB;
        const startingIndex: number = newTab ? 1 : 0;
        const isBold: boolean = functionName === Decorator.BOLD_FUNCTION_NAME;
        const isStrikeThrough: boolean = functionName === Decorator.STRIKE_FUNCTION_NAME;

        return {
            newTab,
            displayText: replaceableParams[startingIndex],
            fullUrl: replaceableParams[startingIndex + 1],
            a11yText: replaceableParams[startingIndex + 2],
            isPlainText: isBold || isStrikeThrough,
            isLink: functionName && functionName.toUpperCase() === Decorator.LINK_FUNCTION_NAME,
            isBold,
            isStrikeThrough
        };
    }

    constructor(@Inject(CONFIG_TOKEN) config: IXMOptions, sanitizer: DomSanitizer) {
        Object.assign(this, { config, sanitizer });
    }

    public parseTextDecoration(input: string): TextDecorationInfo[] {
        if (!Decorator.ALL_REPLACEABLES.test(input)) {
            return [ this.getPlainTextDecorationInfo(input) ];
        }

        const completedDecorationInfo: TextDecorationInfo[] = [];
        const replaceableDecorationInfo: TextDecorationInfo[] = [];

        const stringWithSplitter: string = input.replace(Decorator.ALL_REPLACEABLES, (_replaceableWithFunction: string, functionName: string, paramString: string) => {
            replaceableDecorationInfo.push(Decorator.transformReplaceable(functionName, paramString));

            return Decorator.PLAIN_TEXT_SPLITTER;
        });

        stringWithSplitter.split(Decorator.PLAIN_TEXT_SPLITTER).forEach((plainText: string, index: number) => {
            if (plainText) {
                completedDecorationInfo.push(this.getPlainTextDecorationInfo(plainText));
            }

            if (replaceableDecorationInfo[index]) {
                completedDecorationInfo.push(replaceableDecorationInfo[index]);
            }
        });

        return completedDecorationInfo;
    }

    // This does not support backward compatibility to handle cms-text format
    public parseLinkDecoration(inputString: string): LinkDecorationInfo[] {
        return this.parseTextDecoration(inputString).map((textInfo: TextDecorationInfo) => {
            if (!textInfo.fullUrl) {
                return textInfo;
            }

            return this.createSpaLinks(textInfo.fullUrl);
        });
    }

    public createGenericLinkDecoration(genericLink: CmsGenericLink): LinkDecorationInfo {
        if (!genericLink) {
            return;
        }

        let toReturn: LinkDecorationInfo = {};
        toReturn = this.createSpaLinks(genericLink.url);
        toReturn.displayText = genericLink.text;
        toReturn.a11yText = genericLink.title;
        toReturn.target = genericLink.target;

        String(genericLink.linktype) === 'refresh' ? toReturn.isRefreshButton = true : toReturn.isRefreshButton = false;

        // create styles
        if (genericLink.class) {
            const styleClass: string = genericLink.class.toUpperCase();
            toReturn.isPrimary = styleClass === Decorator.PRI_FUNCTION_NAME;
            toReturn.isSecondary = styleClass === Decorator.SEC_FUNCTION_NAME;
            toReturn.isLink = styleClass === Decorator.LINK_FUNCTION_NAME;
            toReturn.isNavSubMenu = styleClass === Decorator.SUBMENU_LINK_NAME;
            toReturn.isNavDropdownSubMenu = styleClass === Decorator.DROPDOWN_SUBMENU_LINK_NAME;
            toReturn.isNavMobileMenu = styleClass === Decorator.MOBILE_LINK_NAME;
        } else {
            toReturn.inheritParentStyles = true;
        }

        return toReturn;
    }

    public createSpaLinks(fullUrl: string): LinkDecorationInfo {
        if (!(fullUrl && WindowReference.isWindowAvailable)) {
            return {};
        }

        const toReturn: LinkDecorationInfo = {};
        toReturn.absoluteUrl = fullUrl.startsWith('/') ? `${window.location.origin}${fullUrl}` : this.sanitizer.bypassSecurityTrustUrl(fullUrl);

        return toReturn;
    }

    private getPlainTextDecorationInfo(text: string): TextDecorationInfo {
        return {
            displayText: text,
            isPlainText: true
        };
    }
}
