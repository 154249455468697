import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { AUTH_COOKIE_NAME, AuthCookieValue, MILLISECOND_MULTIPLIER, StorageToken } from 'core/constants';
import { Util } from 'src/marketing/core/utils/util';
import { LocalStorage } from '../storage/local';
import { WindowReference } from '../window';

const ONE_MINUTE: number = 60;
const RESI_USER_TYPE: string = 'Residential';

@Injectable({
    providedIn: 'root'
})
export class CimaToken {
    public code: string;
    public accessToken: string;
    public refreshToken: string;
    public idToken: string;
    public decodedIdToken: JWT;
    public loginChange: Observable<boolean>;

    private loginChangeSubject: BehaviorSubject<boolean>;
    private expirationTimeWithOffset: number;

    private localStorage: LocalStorage;

    constructor(localStorage: LocalStorage) {
        Object.assign(this, { localStorage });

        this.loginChangeSubject = new BehaviorSubject(false);
        this.loginChange = this.loginChangeSubject.pipe(distinctUntilChanged());
        this.loadTokens();
    }

    public get tokens(): CimaTokens {
        return {
            accessToken: this.accessToken,
            refreshToken: this.refreshToken,
            expirationTime: this.expirationTimeWithOffset,
            idToken: this.idToken
        };
    }

    // Check if the user is residential or buiness based on the CIMA ID token
    public get isBusinessUser(): boolean {
        return this.decodedIdToken && this.decodedIdToken.user_type !== RESI_USER_TYPE;
    }

    public saveNewToken(token: string, expirationTimeInSeconds: number, refreshToken: string, idToken: string): void {
        this.code = ''; // whenever you set a new token, expire the existing code
        this.accessToken = token;
        this.refreshToken = refreshToken;
        this.idToken = idToken;
        this.decodedIdToken = Util.decodeJWT(idToken);

        const expirationTime: number = Date.now() + (expirationTimeInSeconds - ONE_MINUTE) * MILLISECOND_MULTIPLIER;

        // an effort to offset the multiple tabs from updating the same refresh token
        this.expirationTimeWithOffset = expirationTime - this.generateRandomOffset();
        this.localStorage.set(StorageToken.CIMA, JSON.stringify({
            accessToken: this.accessToken,
            refreshToken: this.refreshToken,
            idToken: this.idToken,
            expirationTime
        }));

        this.createCookie(AUTH_COOKIE_NAME, AuthCookieValue.SET);
        this.loginChangeSubject.next(true);
    }

    public logoutCleanup(): void {
        this.createCookie(AUTH_COOKIE_NAME, AuthCookieValue.UNSET);
        this.localStorage.destroy(StorageToken.CIMA);
        this.localStorage.destroy(StorageToken.CIMA_TOKEN);
    }

    public get isLoggedIn(): boolean {
        const cimaTokenExists = localStorage.getItem(StorageToken.CIMA);
        if (cimaTokenExists) {
            return this.hasAccess();
        } return false;
    }

    public checkForNewTokens(): boolean {
        const tokens: CimaTokens = this.localStorage.get<CimaTokens>(StorageToken.CIMA);
        if (tokens && tokens.accessToken && this.accessToken !== tokens.accessToken) {
            this.loadTokens();

            return true;
        }

        return false;
    }

    public isExpired(tokens?: CimaTokens): boolean {
        const expirationTime: number = tokens ? tokens.expirationTime : this.expirationTimeWithOffset;

        if (!expirationTime) {
            return true;
        }

        return expirationTime < Date.now();
    }

    public createCookie(name: string, value: string, days: number = 1): void {
        if (!WindowReference.isWindowAvailable) {
            return;
        }

        let cookie: string;
        const date: Date = new Date();
        const baseDomain: string = window.location.hostname.split('.').slice(-2).join('.');

        date.setTime(date.getTime() + ((days) * 864e5));
        const expires: string = date.toUTCString();
        cookie = `${encodeURIComponent(name)}=${value};expires=${expires};path=/`;

        if (baseDomain.indexOf('.') !== -1) {
            cookie += ';domain=' + baseDomain;
        }

        window.document.cookie = cookie;
    }

    public getCookie(cname: string): string {
        const name = cname + '=';
        const cookieList = document.cookie.split(';');
        for (let cookie of cookieList) {
            while (cookie.startsWith(' ')) {
                cookie = cookie.substring(1);
            }
            if (cookie.startsWith(name)) {
                return cookie.substring(name.length, cookie.length);
            }
        }

        return '';
    }

    private loadTokens(): void {
        if (!WindowReference.isWindowAvailable) {
            return;
        }

        const search: UrlQueries = Util.getObjectFromQueryString(window.location.search.slice(1));

        // first time coming back from CIMA with code
        if (search.code) {
            this.code = search.code;

            return;
        }

        // Otherwise get them from localstorage
        const tokens: CimaTokens = this.localStorage.get<CimaTokens>(StorageToken.CIMA);
        if (!this.isExpired(tokens)) {
            this.accessToken = tokens.accessToken;
            this.refreshToken = tokens.refreshToken;
            this.idToken = tokens.idToken;
            this.decodedIdToken = Util.decodeJWT(tokens.idToken);
            this.expirationTimeWithOffset = tokens.expirationTime - this.generateRandomOffset();

            this.loginChangeSubject.next(true);
        }
    }

    private generateRandomOffset(): number {
        // possible values are from 0-20 (in milliseconds)
        return Math.floor(Math.random() * 20) * MILLISECOND_MULTIPLIER;
    }

    private hasAccess(): boolean {
        if (typeof this.accessToken !== 'undefined') {
            return this.accessToken && !this.isExpired();
        }

        return false;
    }
}
