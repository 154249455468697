<section>
    <div class="about" *ngIf="hasFieldOrEditable(rendering.fields.header)">
        <p class="header" *scRichText="rendering.fields.header"></p>
    </div>
    <ng-container *ngIf="hasPlaceholder(rendering, 'questionAnswers')">
        <div class="info">
            <div sc-placeholder name="questionAnswers" [rendering]="rendering"></div>

            <div class="actions" *ngIf="hasActions">
                <xm-generic-link *ngIf="hasFieldOrEditable(rendering.fields.linkDecorationOne)" class="cta"
                    [field]="rendering.fields.linkDecorationOne"></xm-generic-link>
                <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.ctaOne)" class="cta"
                    [field]="rendering.fields.ctaOne"></xm-cms-text>
            </div>

        </div>
    </ng-container>
</section>