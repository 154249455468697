import { ModelBase } from 'core/store/model-base';

export class Cart extends ModelBase {
    public static storeName: string = 'cart';

    public items: object[];
    public itemCount: number;

    public static create<T extends ModelBase>(initData?: any): T {
        initData.itemCount = initData.items && initData.items.length;

        return initData;
    }

    public get cartCount(): string {
        return this.itemCount && this.itemCount.toString();
    }
}
