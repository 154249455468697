import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Item } from '@sitecore-jss/sitecore-jss';

import { SitecoreComponent } from 'components/shared/sitecore';
import { CimaToken, Cloudinary } from 'core/services';
import { Util } from 'core/utils/util';
import { MouseEventType } from 'core/constants';
import { XmStore, XmStoreUtil } from 'services/store';
import { StoreAction } from 'core/store-actions';
import { Cart } from 'core/store/cart/model/cart';

@Component({
    selector: 'xm-nav-cart',
    styleUrls: [ './cart.scss' ],
    templateUrl: './cart.html'
})
export class MarketingNavCartComponent extends SitecoreComponent implements OnInit, OnDestroy {
    @Input() public rendering: Item;
    @Output() public toggleMobileNav: EventEmitter<void> = new EventEmitter<void>();

    public pageData: NavMenuItem;
    public displayIcon: MediaImageOptions;
    public cartIcon: MediaImageOptions;
    public cartHoverIcon: MediaImageOptions;
    public cartCountText: string;

    private subscriptions: Subscription[] = [];
    private store: XmStore;
    private cimaToken: CimaToken;

    constructor(injector: Injector, store: XmStore, cimaToken: CimaToken) {
        super(injector);
        Object.assign(this, { store, cimaToken });
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<NavMenuItem>(this.rendering.fields);

        this.cartIcon = this.pageData.icon && Cloudinary.generateMediaOptionsFromCms(this.pageData.icon);
        this.cartHoverIcon = this.pageData.hoverIcon && Cloudinary.generateMediaOptionsFromCms(this.pageData.hoverIcon);

        this.displayIcon = this.cartIcon;

        this.subscriptions.push(this.cimaToken.loginChange.subscribe(() => {
            if (this.cimaToken.isLoggedIn) {
                this.subscriptions.push(XmStoreUtil.subscribe(this.store.find(StoreAction.GET_CART, Cart), (cart: Cart) => {
                    this.cartCountText = cart.cartCount;
                }));
            }
        }));
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    public toggleNavMenu(): void {
        this.toggleMobileNav.emit();
    }

    public mouseHoverEvent(event: Event): void {
        this.displayIcon = event.type === MouseEventType.MOUSE_ENTER && this.cartHoverIcon ? this.cartHoverIcon : this.cartIcon;
    }
}
