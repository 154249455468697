import { StoreAction, XmAction } from 'core/store-actions';
import { Cart } from './model/cart';

export function cartReducer(state: Cart, action: XmAction<Cart>): Cart {
    switch (action.type) {
        case StoreAction.GET_CART:
            return action.payload;
        default:
            return state;
    }
}
