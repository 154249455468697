import { Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'xm-expandable',
    styleUrls: [ './expandable.scss' ],
    templateUrl: './expandable.html',
    animations: [
        trigger('toExpand', [
            state('true', style({
                opacity: 1,
                height: '*'
            })),
            state('false', style({
                opacity: 0,
                height: '0px'
            })),
            transition('true => false', animate('250ms')),
            transition('false => true', animate('250ms'))
        ])
    ]
})
export class MarketingExpandableComponent {
    @Input() public expanded: boolean;
    @Output() public onComplete: EventEmitter<void> = new EventEmitter<void>();

    private renderer: Renderer2;

    constructor(renderer: Renderer2) {
        Object.assign(this, { renderer });
    }

    public animationComplete(a: AnimationEvent): void {
        this.onComplete.emit();

        if (this.expanded) {
            this.renderer.removeAttribute(a.element, 'aria-hidden');
            this.renderer.removeAttribute(a.element, 'tabindex');
        } else {
            this.renderer.setAttribute(a.element, 'aria-hidden', 'true');
            this.renderer.setAttribute(a.element, 'tabindex', '-1');
        }
    }
}
