import { Inject, Injectable } from '@angular/core';
import { Transition } from '@uirouter/core';
import { Util } from 'core/utils/util';
import { CONFIG_TOKEN } from 'core/constants';
import { IXMOptions } from 'core/interfaces';
import { Subscription } from 'rxjs';
import { WindowReference } from 'services/window';
import * as DD from './constants';

@Injectable({
    providedIn: 'root'
})
export class DataLayer {
    private config: IXMOptions;

    private windowReference: WindowReference;

    private breakpoint: string;

    private isFirstLoad: boolean = false;

    private subscriptions: Subscription[] = [];

    constructor(@Inject(CONFIG_TOKEN) config: IXMOptions, windowReference: WindowReference) {
        Object.assign(this, {
            config,
            windowReference
        });
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    /* To load Analytics Script */
    public loadDataLayer(): Promise<void> {
        if (!this.config.ENABLE_NEW_DATALAYER) {
            return Promise.resolve();
        }

        this.subscriptions.push(this.windowReference.breakpoint.subscribe((breakpoint: Breakpoint) => {
            this.breakpoint = breakpoint.currentBreakpoint;
        }));

        return new Promise((resolve: (value?: void | PromiseLike<void>) => void) => {
            window.trackingDebug = this.config.ENABLE_DEV_TOOLS;

            const dataLayerScript: HTMLScriptElement = window.document.createElement('script');
            dataLayerScript.type = 'text/javascript';
            dataLayerScript.charset = 'UTF-8';
            dataLayerScript.src = this.config.DATA_LAYER_URL;
            dataLayerScript.async = true;
            dataLayerScript.onload = this.onload.bind(this, resolve);
            window.document.head.appendChild(dataLayerScript);
            resolve();
        });
    }

    public triggerPageStartForDataLayer(): void {
        if (!this.canSendToDataLayer) {
            return;
        }
        document.dispatchEvent(new CustomEvent('c-tracking-log-page-start', {
            bubbles: true
        }));
    }

    public sendPageDataOnTransition(trans?: Transition): void {
        if (!this.canSendToDataLayer) {
            return;
        }

        document.dispatchEvent(new CustomEvent('c-tracking-log-page', {
            detail: {
                page: {
                    pageInfo: {
                        pageURL: window.location.href,
                        screenName: trans ? trans.params().page_id.split('/').pop().split('?')[0].replace(new RegExp('-', 'g'), ' ') :
                        window.location.href.split('/').pop().split('?')[0].replace(new RegExp('-', 'g'), ' ') || DD.DEFAULT_PAGE.pageInfo.screenName,
                        language: 'en'
                    },
                    category: {
                        ...DD.DEFAULT_PAGE.category
                    },
                    siteInfo: {
                        siteVersion: this.breakpoint
                    }
                }
            },
            bubbles: true
        }));

    }

    private log(data: string): void {
        if (window.trackingDebug) {
            /* eslint-disable  no-console */
            console.log(data);
            /* eslint-enable  no-console */
        }
    }

    private onload(resolve: (value?: void | PromiseLike<void>) => void): void {
        window.dataLayerLoaded = true;
        this.log('Data Layer has been successfully loaded');

        // First Load : on the initial app load, data-layer value are lately loaded and setting value to undefined rather true, added the first load condition to pass the pageName to data-layer to resolve this
        // * NOTE :: condition should be removed once data-layer issue is sorted
        if (this.isFirstLoad) {
            this.sendPageDataOnTransition();
        }
        resolve();
    }

    private get canSendToDataLayer(): boolean {
        if (!window.dataLayerLoaded) {
            this.isFirstLoad = true;
        }

        return Boolean(window.dataLayerLoaded) && this.config.ENABLE_NEW_DATALAYER;
    }
}
