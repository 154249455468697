import { ChangeDetectionStrategy, Component, Injector, Input } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss';

import { SitecoreComponent } from 'components/shared/sitecore';

@Component({
    selector: 'marketing-grid',
    styleUrls: [ './grid.scss' ],
    templateUrl: './grid.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketingGridComponent extends SitecoreComponent {
    @Input() public rendering: ComponentRendering;

    constructor(injector: Injector) {
        super(injector);
    }
}
