<section [ngClass]="{ 'has-promo-banner': hasPromoBanner }">
    <div class="search">
        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.header)">
            <div class="search-header" *scRichText="rendering.fields.header"></div>
        </ng-container>

        <form [formGroup]="zipForm">
            <div class="input-wrapper">
                <ng-container *ngIf="hasFieldOrEditable(rendering.fields.zipLabel)">
                    <label class="zip-label" [for]="zipInputOptions.inputId"
                        *scRichText="rendering.fields.zipLabel"></label>
                </ng-container>
                <input class="zip-input" xmMask xmAriaDescBy xmAriaInvalid defaultAttrType="tel" formControlName="zip"
                    [id]="zipInputOptions.inputId" [placeholder]="zipCodePlaceholder"
                    [template]="zipInputOptions.template" (raw)="zipCodeListener($event)" />
                <div *ngIf="inEditModeOr(zipInputForm.errors && zipInputForm.dirty && zipInputForm.touched)">
                    <span class="error" id="aria-{{ zipInputOptions.inputId }}"
                        *scRichText="rendering.fields.zipError"></span>
                </div>
                <button type="submit" class="btn submit-btn" (click)="onSubmit()">
                    <xm-media-image class="search-icon" *ngIf="searchIcon" [imageOptions]="searchIcon"
                        aria-hidden="true"></xm-media-image>
                </button>
            </div>
        </form>
    </div>
    <div class="iframe-wrapper">
        <iframe *ngIf="showIframe" [src]="frameSrc"></iframe>
    </div>
</section>
