import { Component, HostBinding, Injector, Input, OnInit } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';

import { SitecoreComponent } from 'components/shared/sitecore';
import { Cloudinary } from 'services/cloudinary';

@Component({
    selector: 'plan-variant',
    styleUrls: [ './plan-variant.scss' ],
    templateUrl: './plan-variant.html'
})
export class PlanVariantComponent extends SitecoreComponent implements OnInit {
    @HostBinding('class.swiper-slide') public hostClass: boolean = true;

    @Input() public rendering: ComponentRendering;

    public pageData: MarketingPlanVariantPageData;
    public btgImage: MediaImageOptions;
    public unlImage: MediaImageOptions;

    constructor(injector: Injector) {
        super(injector);
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<MarketingPlanVariantPageData>(this.rendering.fields);
        this.btgImage = this.pageData && this.pageData.btgIcon && Cloudinary.generateMediaOptionsFromCms(this.pageData.btgIcon);
        this.unlImage = this.pageData && this.pageData.unlIcon && Cloudinary.generateMediaOptionsFromCms(this.pageData.unlIcon);
    }
}
