import { ModelBase } from 'core/store/model-base';

export class Coverage extends ModelBase {
    public static storeName: string = 'coverage';

    public zipcode: string;
    public lteAvailable: boolean;
    public hotspotsCount: number;
    public fivegAvailable: boolean;

    public serviceAvailable(): boolean {
        return this.lteAvailable || this.fivegAvailable || Boolean(this.hotspotsCount);
    }

    public hotspotAvailable(): boolean {
        return Boolean(this.hotspotsCount);
    }
}
