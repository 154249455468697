import { Component, Injector, Input } from '@angular/core';
import { SitecoreComponent } from 'components/shared/sitecore';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss';

@Component({
    selector: 'question-answers',
    styleUrls: [ './question-answers.scss' ],
    templateUrl: './question-answers.html'
})
export class QuestionAnswersComponent extends SitecoreComponent {
    @Input() public rendering: ComponentRendering;

    public questionOpen: boolean = this.inEditMode;

    constructor(injector: Injector) {
        super(injector);
    }

    public toggle(): void {
        if (this.inEditMode) {
            return;
        }

        this.questionOpen = !this.questionOpen;
    }
}
