<section>
    <xm-cms-button
        *ngIf="hasFieldOrEditable(rendering.fields.question)"
        class="question"
        (click)="toggle()"
        [attr.aria-expanded]="questionOpen"
        [field]="rendering.fields.question" [opened]="questionOpen">        
    </xm-cms-button>
    <xm-expandable [expanded]="questionOpen">
        <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.answer)" class="answer" [field]="rendering.fields.answer"></xm-cms-text>
        <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.ctaLink)" class="btn-container" [field]="rendering.fields.ctaLink"></xm-cms-text>
    </xm-expandable>
</section>
