import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class SearchApiService {

    private httpClient: HttpClient;

    constructor(httpClient: HttpClient) {
        Object.assign(this, { httpClient });
    }

    public suggestionsSearchApi = (searchInput: string) => this.httpClient.get<string[]>(`https://xapi.stg.xfinity.com/search/suggestions/?cat=qc&q=${searchInput}`);
}
