import { Inject, Injectable } from '@angular/core';
import { ApiChannel, CONFIG_TOKEN } from 'core/constants';

import { IXMOptions } from 'core/interfaces';
import { XmHttp } from '../http/http';
import { Util } from 'src/marketing/core/utils/util';
import { CimaToken } from './token';
import { CimaUrl } from './url';
import { WindowReference } from '../window';

@Injectable({
    providedIn: 'root'
})
export class CimaCore {
    private static PASSIVE_EVENT: string = 'OAUTH_EVENT';

    private config: IXMOptions;
    private xmHttp: XmHttp;
    private cimaToken: CimaToken;
    private cimaUrl: CimaUrl;
    private refreshInterval: number;

    constructor(
        @Inject(CONFIG_TOKEN) config: IXMOptions,
        xmHttp: XmHttp,
        cimaToken: CimaToken,
        cimaUrl: CimaUrl
    ) {
        Object.assign(this, {
            config,
            xmHttp,
            cimaToken,
            cimaUrl
        });

        if (WindowReference.isWindowAvailable) {
            window.addEventListener('storage', this.storageListener.bind(this));
        }
    }

    /**
     * Will check if the cima token was found in local storage and get one if it doesn't exist
     */
    public loadCimaToken(): Promise<string | void> {
        // we have a "code" from cima redirect which can then be used to get user access/refresh token
        if (this.cimaToken.code) {
            return this.getUserToken();
        } else if (this.cimaToken.accessToken) {
            this.setupRefreshToken();

            return Promise.resolve(this.cimaToken.accessToken);
        }

        return Promise.resolve(undefined);
    }

    /**
     * Public function that initiates the check to see if they are logged in with another comcast service
     */
    public tryPassiveAuthLogin(): void {
        if (this.config.ENABLE_PASSIVE_AUTH && !this.cimaToken.isLoggedIn) {
            this.passiveAuthIframe();
        }
    }

    /**
     * Get user access/refresh token from the "code" that we are given on redirect from cima
     */
    private getUserToken(fromPassiveAuth: boolean = false): Promise<string | void> {
        const requestBody = {
            code: this.cimaToken.code,
            consumer: 'cbm',
            redirectUri: this.cimaUrl.apiRedirectUri(fromPassiveAuth)
        };
        const bootstrap_api_endpoint = 'v2/usertoken/codetoken';

        return this.xmHttp.post(ApiChannel.BOOTSTRAP_API, bootstrap_api_endpoint, requestBody).toPromise()
            .then((tokens: cimaBootstrapResponse) => {
                this.cimaToken.saveNewToken(tokens.data.accessToken, tokens.data.expiresIn, tokens.data.refreshToken, tokens.data.idToken);
                this.setupRefreshToken();


                return this.cimaToken.accessToken;
            })
            .catch(() => {
                Promise.resolve(undefined);
            });
    }

    /**
     * Get new access/refresh token using the saved refresh token
     */
    private getUserTokenFromRefresh(): void {
        const requestBody = {
            refreshToken: this.cimaToken.refreshToken,
            consumer: 'cbm'
        };
        const bootstrap_api_endpoint = 'v2/usertoken/refreshtoken';

        this.xmHttp.post(ApiChannel.BOOTSTRAP_API, bootstrap_api_endpoint, requestBody).toPromise()
            .then((tokens: cimaBootstrapResponse) => {
                this.cimaToken.saveNewToken(tokens.data.accessToken, tokens.data.expiresIn, tokens.data.refreshToken, tokens.data.idToken);
                this.setupRefreshToken();

                return this.cimaToken.accessToken;
            })
            .catch(() => {
                Promise.resolve(undefined);
            });
    }

    private setupRefreshToken(): void {
        this.clearInterval();

        if (!WindowReference.isWindowAvailable) {
            return;
        }

        this.refreshInterval = window.setInterval(() => {
            // if the expiration time has passed, need to refresh the token
            if (this.cimaToken.isExpired()) {
                this.clearInterval();
                this.getUserTokenFromRefresh();
            }
        }, 10000);
    }

    private clearInterval(): void {
        if (WindowReference.isWindowAvailable && this.refreshInterval) {
            window.clearInterval(this.refreshInterval);
            this.refreshInterval = undefined;
        }
    }

    private storageListener(event: StorageEvent): void {
        // only listen for our token
        if (event.key === 'xm-access-token') {
            if (!event.newValue) {
                this.cimaUrl.redirectToLogout();
            } else if (event.newValue !== event.oldValue) {
                // the case where another tab updated the token and we need to load the new token
                const foundNewToken: boolean = this.cimaToken.checkForNewTokens();
                if (foundNewToken) {
                    this.setupRefreshToken();
                }
            }
        }
    }

    /**
     * Build an iframe that checks if the user is logged in with another comcast service
     */
    private passiveAuthIframe(): void {
        if (!WindowReference.isWindowAvailable) {
            return;
        }

        // set listener for passive auth
        window.addEventListener(CimaCore.PASSIVE_EVENT, this.passiveAuthListener.bind(this), false);

        const queryParams: string = Util.createQueryParams({
            client_id: this.config.CLIENT_ID,
            redirect_uri: this.cimaUrl.apiRedirectUri(true),
            prompt: 'none',
            response_type: 'code'
        });

        const authIframe: HTMLIFrameElement = window.document.createElement('iframe');
        authIframe.setAttribute('src', `${this.config.CIMA_BASE_URL}/oauth/authorize${queryParams}`);
        authIframe.setAttribute('name', 'oauth-iframe');
        authIframe.setAttribute('id', 'oauth-iframe');
        authIframe.setAttribute('title', 'oauth-iframe');
        authIframe.setAttribute('style', 'height: 0; width: 0; border: 0; position: absolute;');
        window.document.body.appendChild(authIframe);
    }

    /**
     * If the user is logged in with another comcast service, the code will get sent back here, which will allow
     * us to get the user cima token and then load any inital user data
     */
    private passiveAuthListener(event: CustomEvent): void {
        const code: string = event ? event.detail : '';
        if (code) {
            this.cimaToken.code = code;
            this.getUserToken(true);
        }
    }
}
