import { Component, Injector, Input, OnInit } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss';

import { SitecoreComponent } from 'components/shared/sitecore';
import { Cloudinary } from 'services/cloudinary';

@Component({
    selector: 'marketing-policy-list-text',
    styleUrls: [ './text.scss' ],
    templateUrl: './text.html'
})
export class MarketingPolicyListTextComponent extends SitecoreComponent implements OnInit {
    @Input() public rendering: ComponentRendering;

    public pageData: PolicyListPagedata;
    public icon: MediaImageOptions;

    constructor(injector: Injector) {
        super(injector);
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<PolicyListPagedata>(this.rendering.fields);

        this.icon = this.pageData.icon && Cloudinary.generateMediaOptionsFromCms(this.pageData.icon);
    }
}
