import { Component, HostBinding, Injector, Input, OnInit } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss';

import { SitecoreComponent } from 'components/shared/sitecore';
import { Util } from 'core/utils/util';

@Component({
    selector: 'marketing-unl-pricing-modal-group',
    templateUrl: 'group.html',
    styleUrls: [ 'group.scss' ]
})
export class MarketingUnlPricingModalGroup extends SitecoreComponent implements OnInit {
    @Input() public rendering: ComponentRendering;
    @HostBinding('class.swiper-slide') public hostClass: boolean = true;

    public pageData: UnlPricingModalGroupData;
    constructor(injector: Injector) {
        super(injector);
    }

    public ngOnInit(): void {
        if (!this.inEditMode && this.rendering) {
            this.pageData = this.flattenFields<UnlPricingModalGroupData>(this.rendering.fields);

            this.pageData.firstTablePerLine = Util.decorateFloatingNumbersWithSuperscript(this.pageData.firstTablePerLine);
            this.pageData.firstCarouselPerLine = Util.decorateFloatingNumbersWithSuperscript(this.pageData.firstCarouselPerLine);

            this.pageData.secondTablePerLine = Util.decorateFloatingNumbersWithSuperscript(this.pageData.secondTablePerLine);
            this.pageData.secondCarouselPerLine = Util.decorateFloatingNumbersWithSuperscript(this.pageData.secondCarouselPerLine);

            this.pageData.thirdTablePerLine = Util.decorateFloatingNumbersWithSuperscript(this.pageData.thirdTablePerLine);
            this.pageData.thirdCarouselPerLine = Util.decorateFloatingNumbersWithSuperscript(this.pageData.thirdCarouselPerLine);

            this.pageData.fourthTablePerLine = Util.decorateFloatingNumbersWithSuperscript(this.pageData.fourthTablePerLine);
            this.pageData.fourthCarouselPerLine = Util.decorateFloatingNumbersWithSuperscript(this.pageData.fourthCarouselPerLine);
        }
    }
}
