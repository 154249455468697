<div class="global-footer">
    <div class="main-content hidden-small-devices-desktop">
      <div class="footer-logo">
        <a href="https://business.comcast.com/">
          <img src="assets/icons/Comcast Business.png" alt="Comcast business logo" aria-label="Comcast business logo"/>
      </a>
      </div>
      <div>
        <a *scLink="rendering.fields.shopheader"></a>
        <div *ngFor="let link of rendering.fields.shop"><a *scLink="link.fields.link"></a></div>
      </div>
      <div>
        <a *scLink="rendering.fields.supportheader"></a>
        <div *ngFor="let link of rendering.fields.support"><a *scLink="link.fields.link"></a></div>
      </div>
      <div>
        <a *scLink="rendering.fields.myaccountheader"></a>
        <div *ngFor="let link of rendering.fields.myaccount"><a *scLink="link.fields.link"></a></div>
      </div>
      <div>
        <a *scLink="rendering.fields.aboutusheader"></a>
        <div *ngFor="let link of rendering.fields.aboutus"><a *scLink="link.fields.link"></a></div>
      </div>
      <div>
        <a *scLink="rendering.fields.partnerheader"></a>
        <div *ngFor="let link of rendering.fields.partners"><a *scLink="link.fields.link"></a></div>
      </div>
    </div>

    <div class="sub-content divider-desktop">
      <div class="order-{{i}}" *ngFor="let link of rendering.fields.links; index as i">
        <a *scLink="link.fields.link"></a>
      </div>
    </div>
    <div class="divider-sm"></div>
    <div class="copyright-content">
      <p class="copyright-text"> {{ pageData.copyright }}</p>
    </div>
    <div class="social-content">
      <div *ngFor="let link of rendering.fields.social"><a class="link"  href="{{link.fields.url.value}}">
        <img class="img-icon" src="{{link.fields.icon.value}}" alt="{{link.fields.name.value}}">
      </a></div>
    </div>
  </div>