
    <div *ngIf="field && field.editable" [innerHtml]="field.editable | safeHtml"></div>
    <ng-container *ngIf="field && !field.editable">
        <a *ngIf="genericLinkDecoration && !genericLinkDecoration.isRefreshButton && genericLinkDecoration.uiSref && genericLinkDecoration.uiParams"
            [ngClass]="{
                'xm-btn-link-light': genericLinkDecoration.isLink,
                'xm-btn-primary-light': genericLinkDecoration.isPrimary,
                'xm-btn-secondary-light': genericLinkDecoration.isSecondary,
                'dropdown-mainmenu-link': genericLinkDecoration.isNavSubMenu, 
                'dropdown-submenu-link': genericLinkDecoration.isNavDropdownSubMenu,                             
                'inline-text-replace': genericLinkDecoration.inheritParentStyles,
                'mobile-menu-link': genericLinkDecoration.isNavMobileMenu,
                'link-with-icon': icon,
                'cbm-plan-data-option': dataOptionCta
            }"
            class="{{ theme !== 'light' && genericLinkDecoration.isSecondary? theme: '' }}"
            tabindex="0"
            [target]="genericLinkDecoration.target || ''"
            [uiSref]="genericLinkDecoration.uiSref"
            [uiParams]="genericLinkDecoration.uiParams"
            [attr.aria-label]="genericLinkDecoration.a11yText"            
        >
            {{ genericLinkDecoration.displayText }}
            <xm-media-image aria-hidden="true" *ngIf="icon" class="image" [imageOptions]="icon"></xm-media-image>
        </a>
        <a *ngIf="genericLinkDecoration && !genericLinkDecoration.isRefreshButton && genericLinkDecoration.absoluteUrl"
            [ngClass]="{
                'xm-btn-link-light': genericLinkDecoration.isLink,
                'xm-btn-primary-light': genericLinkDecoration.isPrimary,
                'xm-btn-secondary-light': genericLinkDecoration.isSecondary,
                'dropdown-mainmenu-link': genericLinkDecoration.isNavSubMenu, 
                'dropdown-submenu-link': genericLinkDecoration.isNavDropdownSubMenu,                             
                'inline-text-replace': genericLinkDecoration.inheritParentStyles,
                'mobile-menu-link': genericLinkDecoration.isNavMobileMenu,
                'link-with-icon': icon,
                'cbm-plan-data-option': dataOptionCta
            }"
            tabindex="0"
            class="{{ theme !== 'light' && genericLinkDecoration.isSecondary? theme: '' }}"
            [target]="genericLinkDecoration.target || ''"
            [href]="genericLinkDecoration.absoluteUrl"
            [attr.aria-label]="genericLinkDecoration.a11yText"            
        >
            {{ genericLinkDecoration.displayText }}
            <xm-media-image aria-hidden="true" *ngIf="icon" class="image" [imageOptions]="icon"></xm-media-image>
        </a>
        <a *ngIf="genericLinkDecoration && genericLinkDecoration.isRefreshButton && genericLinkDecoration.absoluteUrl"
            [ngClass]="{
                'xm-btn-link-light': genericLinkDecoration.isLink,
                'xm-btn-primary-light': genericLinkDecoration.isPrimary,
                'xm-btn-secondary-light': genericLinkDecoration.isSecondary,
                'dropdown-mainmenu-link': genericLinkDecoration.isNavSubMenu, 
                'dropdown-submenu-link': genericLinkDecoration.isNavDropdownSubMenu,                             
                'inline-text-replace': genericLinkDecoration.inheritParentStyles,
                'mobile-menu-link': genericLinkDecoration.isNavMobileMenu,
                'link-with-icon': icon
            }"
            tabindex="0"
            class="{{ theme !== 'light' && genericLinkDecoration.isSecondary? theme: '' }}"
            href="javascript:window.location.href=window.location.href"
            [attr.aria-label]="genericLinkDecoration.a11yText"            
        >
            {{ genericLinkDecoration.displayText }}
            <xm-media-image aria-hidden="true" *ngIf="icon" class="image" [imageOptions]="icon"></xm-media-image>
        </a>
    </ng-container>

