import { StoreAction, XmAction } from 'core/store-actions';
import { Coverage } from './model/coverage';

export function coverageReducer(state: Coverage[] = [], action: XmAction<Coverage>): Coverage[] {
    switch (action.type) {
        case StoreAction.GET_COVERAGE:
            return [ ...state, action.payload ];
        default:
            return state;
    }
}
