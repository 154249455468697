import { Component, Injector, Input } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { SitecoreComponent } from 'components/shared/sitecore';

@Component({
  selector: 'xm-device',
  templateUrl: './device.component.html',
  styleUrls: [ './device.component.scss' ]
})
export class DeviceComponent extends SitecoreComponent {
  @Input() public rendering: ComponentRendering;

  constructor(injector: Injector) {
    super(injector);
  }
}
