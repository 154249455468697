<section class="selection-container" *ngIf="hasFieldOrEditable(rendering.fields.header) && hasPlaceholder(rendering, 'gridText')" [ngClass]="{ 'plan-details-header': rendering.fields.planDetailsHeader }">
    <div class="wrapper">
        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.header)">
            <h3 class="header-content"  *scRichText="rendering.fields.header"></h3>
        </ng-container>
        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.showSeparator)">
            <div class="separator"></div>
        </ng-container>
        <ng-container *ngIf="hasPlaceholder(rendering, 'gridText')">
            <div class="placeholders" sc-placeholder name="gridText" [rendering]="rendering"></div>
        </ng-container>
    </div>
</section>
