<div class="search-btn" [ngClass]="{active: (searchWindowService.isVisible && isMobileView)}">
    <a href="javascript:void(0);" (click)="toggleSearchWindowView(); $event.stopPropagation()">
        <img *scImage="rendering.fields.searchIcon; editable: false" alt="Search Icon" />
    </a>
    <div>&nbsp;</div>
</div>


<div class="search-window-container" (click)="$event.stopPropagation()" *ngIf="searchWindowService.isVisible">
    <div class="search-window">
        <form (submit)="getResults()">
            <div class="searchbox">
                <div class="search">
                    <div class="close-bar">
                        <img *scImage="rendering.fields.closeIcon; editable: false" (click)="searchWindowService.close(); searchTerm = '';"
                            alt="Close Icon" />
                        <em class="fa fa-close" (click)="searchWindowService.close(); searchTerm = '';"></em>
                    </div>
                    <input [placeholder]="'Search Comcast Business'" [type]="!isMobileView? 'search' : 'text'" (click)="$event.stopPropagation()" required [(ngModel)]="searchTerm"
                        [ngModelOptions]="{standalone: true}" (change)="search($event.target.value)"
                        (keydown.enter)="getResults()" class="searchTerm">
                    <button type="submit" (click)="$event.stopPropagation()" class="searchButton">
                        <img *scImage="searchIconImgPic; editable: false" alt="Search Icon" />
                        <em class="fa fa-search"></em>
                    </button>
                    <button type="reset"  class="clearButton">
                        <img *scImage="rendering.fields.searchClearMobileIcon; editable: false" alt="Clear Icon" />
                        <em class="fa fa-search"></em>
                    </button>
                </div>
            </div>
        </form>

        <div *ngIf="searchResults.length>0" class="search-results">
            <div *ngFor="let result of searchResults" class="result-item">
                <p class="name">{{result}}</p>
            </div>
        </div>
    </div>
</div>