<section #parentRef class={{pageData?.sectionContainer}} [ngStyle]="{ 'background-color': pageData.backgroundColor }">
    <div class="text-container {{ textAlignment }}" [ngClass]="{ 'text-container-byow': cmsHeader.header_four }">
        <div class="texts">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.smallTitle)">
                <div class="small-title" *scRichText="rendering.fields.smallTitle" [ngStyle]="{ color: pageData.smallTitleColor }"></div>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.header)">
                <div [ngClass]="{ 'header': cmsHeader.default, 'header-one': cmsHeader.header_one, 'header-good-to-go': cmsHeader.header_two, 'header-additional-questions': cmsHeader.header_three, 'header-talk-specialist': cmsHeader.header_four }" *scRichText="rendering.fields.header" [ngStyle]="{ color: pageData.headerColor }"></div>
            </ng-container>
            <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.description)" class="description" [ngStyle]="{ color: pageData.descriptionColor }" [ngClass]="{ 'description-talk-specialist': cmsHeader.header_four, 'description-good-to-go': cmsHeader.header_two, 'description-additional-question': cmsHeader.header_three }" [field]="rendering.fields.description"></xm-cms-text>
            <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.disclaimer)" class="disclaimer"  [customClass]="'disclaimer-text-link'" [field]="rendering.fields.disclaimer"></xm-cms-text>
        </div>
        <div class="actions" [ngClass]="{ 'actions-talk-specialist': cmsHeader.header_four }"  *ngIf="hasActions">
            <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.buttonOne)" class="cta" [field]="rendering.fields.buttonOne"></xm-cms-text>
            <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.buttonTwo)" class="cta" [field]="rendering.fields.buttonTwo"></xm-cms-text>
            <xm-popover
                *ngIf="rendering.fields.popover"
                class="popover-button"
                [rendering]="rendering.fields.popover"
                [parentTemplate]="parentRef"
                position="center">
            </xm-popover>
        </div>
    </div>
</section>
