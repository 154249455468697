import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { LayoutServiceData } from '@sitecore-jss/sitecore-jss';

import * as Api from './store/apis';
import { ApiChannel, BUTTON_LOADER_CLASS } from './constants';
import { Seo, WindowReference, XmHttp } from './services';
import { SitecoreUtil } from './utils/sitecore';
import { ErrorCatchingInterceptor } from 'services/http/error-catching.interceptor';

export function redirectUrl(xmHttp: XmHttp, seo: Seo): () => Promise<void> {
    return () => new Promise((resolve: () => void) => {
        if (WindowReference.isWindowAvailable) {
            xmHttp.get(ApiChannel.CMS, 'redirects').toPromise()
                .then((response: LayoutServiceData) => {
                    const redirectFields: RedirectData = SitecoreUtil.flattenFields<RedirectData>(response.sitecore.route.fields);
                    const newPage: RedirectLink = redirectFields.redirects.find((rewrite: RedirectLink) => rewrite.currentUrl.includes(window.location.href));

                    if (newPage) {
                        if (!window.navigator.userAgent.includes('Prerender')) {
                            window.location.replace(newPage.redirectUrl);
                        }
                        seo.addPrerender301Tag(newPage.redirectUrl);
                    } else {
                        resolve();
                    }
                })
                .catch(resolve);
        } else {
            resolve();
        }
    });
}

@NgModule({
    imports: [
        Angular2PromiseButtonModule.forRoot({
            disableBtn: false,
            btnLoadingClass: BUTTON_LOADER_CLASS,
            spinnerTpl: '<span class="btn-loader"><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div></span>'
        }),
        HttpClientModule
    ],
    providers: [
        Api.AccountApi,
        Api.CartApi,
        Api.CoverageApi,
        Api.CmsApi,
        Api.CatalogApi,
        {
            provide: APP_INITIALIZER,
            useFactory: redirectUrl,
            deps: [ XmHttp, Seo ],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorCatchingInterceptor,
            multi: true
        }
    ]
})
export class CoreModule {}
