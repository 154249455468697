import { ComponentFields, ComponentRendering, Field, getFieldValue, Item } from '@sitecore-jss/sitecore-jss';
import { WindowReference } from 'services/window';

export class SitecoreUtil {
    public static flattenFields<T>(fields: ComponentFields): T {
        const keys: string[] = Object.keys(fields || {});

        const toReturn: any = {};

        keys.forEach((key: string) => {
            const data: Field | Item | Item[] = fields[key];

            if (Array.isArray(data)) {
                toReturn[key] = data.map((innerData: Item) => SitecoreUtil.flattenFields<any>(innerData.fields));
            } else {
                if (!data) {
                    toReturn[key] = undefined;
                } else if (data.hasOwnProperty('value')) {
                    // Field with existing value
                    toReturn[key] = (<Field> data).value;
                } else {
                    // nested object with more fields
                    toReturn[key] = SitecoreUtil.flattenFields((<Item> data).fields);
                }
            }
        });

        return toReturn;
    }

    public static isExperienceEditorActive(): boolean {
        if (WindowReference.isWindowAvailable) {
            return Boolean(window.Sitecore);
        }

        return false;
    }

    public static getFieldValue<T>(renderingOrFields: ComponentRendering | { [name: string]: Field | Item[] }, fieldName: string, defaultValue?: T): T {
        return getFieldValue<T>(renderingOrFields, fieldName, defaultValue);
    }
}
