import { Component, Injector, Input } from '@angular/core';
import { Field } from '@sitecore-jss/sitecore-jss';
import { SitecoreComponent } from 'components/shared/sitecore';

@Component({
    selector: 'xm-cms-button',
    styleUrls: [ './button.scss' ],
    templateUrl: './button.html'
})
export class XmButtonComponent extends SitecoreComponent {
    @Input() public field: Field;
    @Input() public opened: boolean;

    constructor(injector: Injector) {
        super(injector);
    }
}
