<div *ngIf="!inEditMode">
    <div class="carousel-view">
        <div class="page-column" *ngIf="pageData.firstCarouselNumberOfLines">
            <div class="first-row" *scRichText="rendering.fields.firstCarouselNumberOfLines"></div>
            <div class="second-row" *scRichText="rendering.fields.firstCarouselTotal"></div>
            <div class="third-row" [innerHtml]="pageData.firstCarouselPerLine"></div>
        </div>

        <div class="page-column" *ngIf="pageData.secondCarouselNumberOfLines">
            <div class="first-row" *scRichText="rendering.fields.secondCarouselNumberOfLines"></div>
            <div class="second-row" *scRichText="rendering.fields.secondCarouselTotal"></div>
            <div class="third-row" [innerHtml]="pageData.secondCarouselPerLine"></div>
        </div>

        <div class="page-column" *ngIf="pageData.thirdCarouselNumberOfLines">
            <div class="first-row" *scRichText="rendering.fields.thirdCarouselNumberOfLines"></div>
            <div class="second-row" *scRichText="rendering.fields.thirdCarouselTotal"></div>
            <div class="third-row" [innerHtml]="pageData.thirdCarouselPerLine"></div>
        </div>

        <div class="page-column" *ngIf="pageData.fourthCarouselNumberOfLines">
            <div class="first-row" *scRichText="rendering.fields.fourthCarouselNumberOfLines"></div>
            <div class="second-row" *scRichText="rendering.fields.fourthCarouselTotal"></div>
            <div class="third-row" [innerHtml]="pageData.fourthCarouselPerLine"></div>
        </div>
    </div>

    <div class="table-view">
        <tr class="table-row" *ngIf="pageData.firstTableNumberOfLines">
            <td class="first-column" *scRichText="rendering.fields.firstTableNumberOfLines"></td>
            <td class="second-column" [innerHtml]="pageData.firstTablePerLine"></td>
            <td class="third-column" *scRichText="rendering.fields.firstTableTotal"></td>
        </tr>
    
        <tr class="table-row" *ngIf="pageData.secondTableNumberOfLines">
            <td class="first-column" *scRichText="rendering.fields.secondTableNumberOfLines"></td>
            <td class="second-column" [innerHtml]="pageData.secondTablePerLine"></td>
            <td class="third-column" *scRichText="rendering.fields.secondTableTotal"></td>

        </tr>
    
        <tr class="table-row" *ngIf="pageData.thirdTableNumberOfLines">
            <td class="first-column" *scRichText="rendering.fields.thirdTableNumberOfLines"></td>
            <td class="second-column" [innerHtml]="pageData.thirdTablePerLine"></td>
            <td class="third-column" *scRichText="rendering.fields.thirdTableTotal"></td>
        </tr>
    
        <tr class="table-row" *ngIf="pageData.fourthTableNumberOfLines">
            <td class="first-column" *scRichText="rendering.fields.fourthTableNumberOfLines"></td>
            <td class="second-column" [innerHtml]="pageData.fourthTablePerLine"></td>
            <td class="third-column" *scRichText="rendering.fields.fourthTableTotal"></td>
        </tr>
    </div>
</div>

<div class="edit-view" *ngIf="inEditMode">
    <div class="instruction">Carousel view (items will be automatically centered on modal):</div>
    <div class="carousel-content">
        <div class="page-column">
            <div class="first-row" *scRichText="rendering.fields.firstCarouselNumberOfLines"></div>
            <div class="second-row" *scRichText="rendering.fields.firstCarouselTotal"></div>
            <div class="third-row" *scRichText="rendering.fields.firstCarouselPerLine"></div>
        </div>
        <div class="page-column">
            <div class="first-row" *scRichText="rendering.fields.secondCarouselNumberOfLines"></div>
            <div class="second-row" *scRichText="rendering.fields.secondCarouselTotal"></div>
            <div class="third-row" *scRichText="rendering.fields.secondCarouselPerLine"></div>
        </div>
        <div class="page-column">
            <div class="first-row" *scRichText="rendering.fields.thirdCarouselNumberOfLines"></div>
            <div class="second-row" *scRichText="rendering.fields.thirdCarouselTotal"></div>
            <div class="third-row" *scRichText="rendering.fields.thirdCarouselPerLine"></div>
        </div>
        <div class="page-column">
            <div class="first-row" *scRichText="rendering.fields.fourthCarouselNumberOfLines"></div>
            <div class="second-row" *scRichText="rendering.fields.fourthCarouselTotal"></div>
            <div class="third-row" *scRichText="rendering.fields.fourthCarouselPerLine"></div>
        </div>
    </div>

    <div class="instruction">Full table view:</div>
    <div class="table-content">
        <div class="table-row">
            <div class="first-column" *scRichText="rendering.fields.firstTableNumberOfLines"></div>
            <div class="second-column" *scRichText="rendering.fields.firstTablePerLine"></div>
            <div class="third-column" *scRichText="rendering.fields.firstTableTotal"></div>
        </div>
        <div class="table-row">
            <div class="first-column" *scRichText="rendering.fields.secondTableNumberOfLines"></div>
            <div class="second-column" *scRichText="rendering.fields.secondTablePerLine"></div>
            <div class="third-column" *scRichText="rendering.fields.secondTableTotal"></div>
        </div>
        <div class="table-row">
            <div class="first-column" *scRichText="rendering.fields.thirdTableNumberOfLines"></div>
            <div class="second-column" *scRichText="rendering.fields.thirdTablePerLine"></div>
            <div class="third-column" *scRichText="rendering.fields.thirdTableTotal"></div>
        </div>
        <div class="table-row">
            <div class="first-column" *scRichText="rendering.fields.fourthTableNumberOfLines"></div>
            <div class="second-column" *scRichText="rendering.fields.fourthTablePerLine"></div>
            <div class="third-column" *scRichText="rendering.fields.fourthTableTotal"></div>
        </div>    
    </div>
</div>
