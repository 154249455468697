import { Inject, Injectable, OnDestroy, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { StorageToken } from 'core/constants';
import { CimaToken } from 'core/services/cima/token';
import { SessionStorage } from 'core/services/storage/session';
import { Transition, TransitionService, UIRouterGlobals } from '@uirouter/core';

@Injectable({
    providedIn: 'root'
})
export class GlobalNav implements OnDestroy {
    private _document: Document;
    private sessionStorage: SessionStorage;
    private transitionService: TransitionService;
    private onSuccessDeregister: Function;
    private uiRouterGlobals: UIRouterGlobals;
    private local: RegExp = /business.local.comcast.com/;
    private dev: RegExp = /business.dev.comcast.com/;
    private dev5: RegExp = /business.dev5.cable.comcast.com/;
    private integrated: RegExp = /business-mobile-shared-app-dev.np.digital.business.comcast.com/;
    private qa: RegExp = /business.qa.comcast.com/;
    private qa5: RegExp = /business.qa5.cable.comcast.com/;
    private prodqa: RegExp = /business.pqa.comcast.com/;
    private stage: RegExp = /business.stg.comcast.com/;
    private prod: RegExp = /business.comcast.com/;
    private currentLocation: string = window.location.hostname;

    constructor(@Inject(DOCUMENT) _document: Document,
                cimaToken: CimaToken,
                sessionStorage: SessionStorage,
                transitionService: TransitionService,
                uiRouterGlobals: UIRouterGlobals) {
        Object.assign(this, { _document, cimaToken, sessionStorage, transitionService, uiRouterGlobals });
    }

    public initGlobalNav(renderer2: Renderer2): void {
        let dataEnvironment: string;
        let scriptSrc: string;
        let subdomain: string;
        let fetchUrl: string;
        let homePageOverrideUrl: string;

        switch (true) {
            case this.local.test(this.currentLocation):
                dataEnvironment = 'int';
                subdomain = 'int.web1.self-service.np';
                fetchUrl = `https://${subdomain}.digital.business.comcast.com/account/ss-service-navigation/Session/getOrCreate`;
                scriptSrc = 'https://bsd-global-nav-ui-dev.np.digital.business.comcast.com/globalNav.js';
                homePageOverrideUrl = `https:/${this.local}`;
                break;
            case this.integrated.test(this.currentLocation):
                dataEnvironment = 'int';
                subdomain = 'int.web1.self-service.np';
                scriptSrc = 'https://bsd-global-nav-ui-int.np.digital.business.comcast.com/globalNav.js';
                fetchUrl = `https://${subdomain}.digital.business.comcast.com/account/ss-service-navigation/Session/getOrCreate`;
                homePageOverrideUrl = `https:/${this.integrated}`;
                break;
            case this.dev.test(this.currentLocation):
                dataEnvironment = 'dev';
                subdomain = 'dev.web1.self-service.np';
                scriptSrc = 'https://bsd-global-nav-ui-dev.np.digital.business.comcast.com/globalNav.js';
                fetchUrl = `https://${subdomain}.digital.business.comcast.com/account/ss-service-navigation/Session/getOrCreate`;
                homePageOverrideUrl = `https:/${this.dev}`;
                break;
            case this.dev5.test(this.currentLocation):
                dataEnvironment = 'dev';
                subdomain = 'dev.web1.self-service.np';
                scriptSrc = 'https://bsd-global-nav-ui-dev.np.digital.business.comcast.com/globalNav.js';
                fetchUrl = `https://${subdomain}.digital.business.comcast.com/account/ss-service-navigation/Session/getOrCreate`;
                homePageOverrideUrl = `https:/${this.dev5}`;
                break;
            case this.qa.test(this.currentLocation):
                dataEnvironment = 'qa';
                subdomain = 'qa.web1.self-service.np';
                scriptSrc = 'https://bsd-global-nav-ui-qa.np.digital.business.comcast.com/globalNav.js';
                fetchUrl = `https://${subdomain}.digital.business.comcast.com/account/ss-service-navigation/Session/getOrCreate`;
                homePageOverrideUrl = `https:/${this.qa}`;
                break;
            case this.qa5.test(this.currentLocation):
                dataEnvironment = 'qa';
                subdomain = 'qa.web1.self-service.np';
                scriptSrc = 'https://bsd-global-nav-ui-qa.np.digital.business.comcast.com/globalNav.js';
                fetchUrl = `https://${subdomain}.digital.business.comcast.com/account/ss-service-navigation/Session/getOrCreate`;
                homePageOverrideUrl = `https:/${this.qa5}`;
                break;
            case this.prodqa.test(this.currentLocation):
                dataEnvironment = 'pqa';
                subdomain = 'pqa.web1.self-service.np';
                scriptSrc = 'https://bsd-global-nav-ui-pqa.np.digital.business.comcast.com/globalNav.js';
                fetchUrl = `https://${subdomain}.digital.business.comcast.com/account/ss-service-navigation/Session/getOrCreate`;
                homePageOverrideUrl = `https:/${this.prodqa}`;
                break;
            case this.stage.test(this.currentLocation):
                dataEnvironment = 'stg';
                subdomain = 'stg.self-service.np';
                scriptSrc = 'https://bsd-global-nav-ui-stg-va.np.digital.business.comcast.com/globalNav.js';
                fetchUrl = 'https://business.stg.comcast.com/account/ss-service-navigation/Session/getOrCreate';
                homePageOverrideUrl = `https:/${this.stage}`;
                break;
            case this.prod.test(this.currentLocation):
            default:
                dataEnvironment = 'prod';
                subdomain = 'self-service';
                scriptSrc = 'https://bsd-global-nav-ui-prod-va.digital.business.comcast.com/globalNav.js';
                fetchUrl = 'https://business.comcast.com/account/ss-service-navigation/Session/getOrCreate';
                homePageOverrideUrl = `https:/${this.prod}`;
        }

        const token = localStorage.getItem(StorageToken.CIMA) ? JSON.parse(localStorage.getItem(StorageToken.CIMA)).accessToken : localStorage.getItem(StorageToken.CIMA_TOKEN);

        fetch(fetchUrl, {
            headers: {
                accept: 'text/plain',
                'tracking-id': this.sessionStorage.get(StorageToken.VISITOR_SESSION_ID),
                Authorization: token ? `Bearer ${token}` : ''
            }
        }).then(() => {
            this.appendGlobalNav(dataEnvironment, homePageOverrideUrl, renderer2, scriptSrc, token);
        }).catch((error) => {
            /* eslint-disable  no-console */
            console.error('global nav fetch error: ', error);            
            this.appendGlobalNav(dataEnvironment, homePageOverrideUrl, renderer2, scriptSrc, token);
        });
    }

    public stateActive(navMenuItems: NavMenuItem): boolean {
        return !navMenuItems;
    }

    public navHeight(): number {
        const primaryNavElement: HTMLElement = <HTMLElement> window.document.getElementsByTagName('xm-primary-nav')[0];
        const checkoutNavElement: HTMLElement = <HTMLElement> window.document.getElementsByTagName('xm-checkout-nav')[0];
        const watchNavElement: HTMLElement = <HTMLElement> window.document.getElementsByTagName('xm-watch-nav')[0];

        if (primaryNavElement) {
            return primaryNavElement.clientHeight;
        }

        if (checkoutNavElement) {
            return checkoutNavElement.clientHeight;
        }

        if (watchNavElement) {
            return watchNavElement.clientHeight;
        }

        return 0;
    }

    public appendGlobalNav(dataEnvironment: string, homePageOverrideUrl: string, renderer: Renderer2, scriptSrc: string, token: string): void {
        const scriptEl = renderer.createElement('script');
        scriptEl.type = 'text/javascript';
        scriptEl.id = 'bsd-global-nav';
        scriptEl.setAttribute('data-environment', dataEnvironment);
        scriptEl.src = scriptSrc;
        scriptEl.setAttribute('data-application-type', 'SERVER');
        scriptEl.setAttribute('data-application-root-path', '/learn/mobile/');
        scriptEl.setAttribute('data-application-name', 'ComcastBusinessMobileLearn');
        scriptEl.setAttribute('data-homepage-override', homePageOverrideUrl);
        scriptEl.setAttribute('data-override-sign-out-url', homePageOverrideUrl);
        scriptEl.setAttribute('data-placement-l1-right', '[{"id":"bsd-nav-cart-icon", "placeHolderMarkup": "&ltb&gt;SVG ICON LOCATION&lt;b&gt;"]');
        scriptEl.setAttribute('data-components', '[{ "type": "GLOBAL_HEADER", "elementId": "headerId"},{ "type": "GLOBAL_FOOTER", "elementId": "footerId"}]');
        scriptEl.setAttribute('data-always-show-new-nav', 'true');
        scriptEl.setAttribute('data-auth-type', 'CIMA');
        scriptEl.setAttribute('data-auth-token', token ? `Bearer ${token}` : '');
        scriptEl.onload = () => {
            if (this.uiRouterGlobals.current.name?.startsWith('checkout')) {
                window.document.getElementsByClassName('bsd-nav')[0]?.classList.add('display-none');
            }
        };
        scriptEl.text = '';

        this.onSuccessDeregister = this.transitionService.onSuccess({}, (transition: Transition) => {
            if (transition.to().name?.startsWith('checkout')) {
                window.document.getElementsByClassName('bsd-nav')[0]?.classList.add('display-none');
            } else {
                window.document.getElementsByClassName('bsd-nav')[0]?.classList.remove('display-none');
            }
        });
        renderer.appendChild(this._document.head, scriptEl);
    }

    public ngOnDestroy(): void {
        this.onSuccessDeregister();
    }

}
