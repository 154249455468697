<section #parentRef class="unl-class-container">
    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.header)">
        <h2 *scRichText="rendering.fields.header"></h2>
    </ng-container>

    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.subheader)">
        <xm-cms-text class="subHeader" *scRichText="rendering.fields.subheader"></xm-cms-text>
    </ng-container>

    <div *ngIf="isMobileView" class="filter-options">
        <div *ngIf="hasFieldOrEditable(rendering.fields.mobilefilterText)" class="sort-text">
            <xm-cms-text *scRichText="rendering.fields.mobilefilterText"></xm-cms-text>
        </div>
        <div class="filter-dropdownbox">
            <xm-dropdown formControlName="'filter-dropdown'" [customClass]="'filter-dropdown'" [optionsList]="filterOptions"
            [caretImage]="pageData.upArrowIcon" [ariaLabelId]="'filter-dropdown'"
            (onCollapse)="getFilteredTableData($event.selection.value)">
        </xm-dropdown>
        </div>
    </div>
    <div id="unlimited">
        <table class="table">
            <tr class="row">
                <ng-container *ngFor="let tableHeader of tableHeaders">
                    <th class="tableheader-column" scope="col">
                        <div class="row-title">{{tableHeader.tableheader}}</div>
                    </th>
                </ng-container>
            </tr>
            <ng-container *ngFor="let tableBody of paginatedTableData">
                <tr class="row">
                    <td class="column">
                        <div class="features">
                            <ng-container>
                                <div class="lines">
                                    {{tableBody.lineId}}
                                </div>
                            </ng-container>
                        </div>
                    </td>
                    <ng-container *ngFor="let position of dataIndexes">
                        <td class="column">
                            <div class="feature">
                                <ng-container>
                                    <div class="price-title">
                                        {{getPriceData('title', position, tableBody)}}
                                    </div>
                                    <div class="description">
                                        {{getPriceData('description', position, tableBody)}}
                                    </div>
                                </ng-container>
                            </div>
                        </td>
                    </ng-container>
                </tr>
            </ng-container>
        </table>
    </div>
    <div class="pagination">
        <div *ngIf="hasFieldOrEditable(rendering.fields.dropdownText1)">
            <xm-cms-text class="" *scRichText="rendering.fields.dropdownText1"></xm-cms-text>
        </div>
        <div class="pagination-box">
            <xm-dropdown formControlName="'pagination-dropdown'" [optionsList]="paginationOptions"
                [caretImage]="pageData.upArrowIcon" [customClass]="'pagination-dropdown'" [ariaLabelId]="'pagination-dropdown'"
                (onCollapse)="getPaginatedDataForRange($event.selection.value)">
            </xm-dropdown>
        </div>
        <div *ngIf="hasFieldOrEditable(rendering.fields.dropdownText2)">
            <xm-cms-text class="" *scRichText="rendering.fields.dropdownText2"></xm-cms-text>
        </div>
    </div>
</section>