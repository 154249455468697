import { Component, Inject, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ComponentRendering, Field } from '@sitecore-jss/sitecore-jss-angular';
import { Subscription } from 'rxjs';

import { SitecoreComponent } from 'components/shared/sitecore';
import { CONFIG_TOKEN } from 'core/constants';
import { XmStore, XmStoreUtil } from 'core/services';
import { Cloudinary } from 'services/cloudinary';
import { FormUtil } from 'core/utils/form';
import { Coverage } from 'core/store/coverage/model/coverage';
import { IXMOptions } from 'core/interfaces';
import { StoreAction } from 'core/store-actions';
import { Decorator } from 'services/decorator';

@Component({
    selector: 'coverage-checker',
    styleUrls: [ './coverage-checker.scss' ],
    templateUrl: './coverage-checker.html',
    animations: [
        trigger('toGrow', [
            state('true', style({
                width: '100%',
                height: '100%'
            })),
            state('false', style({
                width: '0px',
                height: '0px'
            })),
            transition('true => false', animate('250ms')),
            transition('false => true', animate('250ms'))
        ])
    ]
})
export class MarketingCoverageCheckerComponent extends SitecoreComponent implements OnInit, OnDestroy {
    @Input() public rendering: ComponentRendering;

    public serviceAvailablilityHeader: string;
    public wifiBody: string;

    public image: MediaImageOptions;
    public mapImage: MediaImageOptions;

    public resultsIcon: MediaImageOptions;
    public pageData: CoverageCheckerPageData;
    public animOptions: CoverageCheckerAnimationOptions;
    public zipFormGroup: UntypedFormGroup;
    public zipFormControl: UntypedFormControl;
    public formSubmitted: boolean = false;
    public coverage: Coverage;
    public coverageSubscription: Subscription;
    public apiError: boolean;
    public hotspotLink: string;
    public zipCodeText: string;
    public zipCodeAriaText: string;
    public ariaAvailableMsg: string;

    private config: IXMOptions;
    private store: XmStore;

    constructor(injector: Injector, @Inject(CONFIG_TOKEN) config: IXMOptions, store: XmStore) {
        super(injector);

        Object.assign(this, { config, store });
        this.coverage = Coverage.create();
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<CoverageCheckerPageData>(this.rendering.fields);
        this.animOptions = {
            path: this.pageData.animPath,
            width: this.pageData.animWidth,
            height: this.pageData.animHeight,
            autoplay: this.pageData.autoplay,
            playReverse: this.pageData.playReverse
        };

        this.image = this.pageData.image && Cloudinary.generateMediaOptionsFromCms(this.pageData.image);
        this.resultsIcon = this.pageData.resultsIcon && Cloudinary.generateMediaOptionsFromCms(this.pageData.resultsIcon);
        this.mapImage = this.pageData.image && Cloudinary.generateMediaOptionsFromCms(this.pageData.mapImage);

        const zipInputErrors: XmInputError[] = [{
            code: 'Zipcode-Number',
            regex: /^[0-9]{5}$/,
            message: this.pageData.zipcodeErrorMsg
        }];

        this.zipFormControl = new UntypedFormControl('', { validators: FormUtil.validators(zipInputErrors), updateOn: 'blur' });

        this.zipFormGroup = new UntypedFormGroup({
            zip: this.zipFormControl
        });
        this.zipCodeText = this.pageData.zipCodeText;
        this.zipCodeAriaText = this.pageData.zipCodeAriaText;
        this.ariaAvailableMsg = this.pageData.serviceAvailableBody;
    }

    public ngOnDestroy(): void {
        if (this.coverageSubscription) {
            this.coverageSubscription.unsubscribe();
        }
    }

    public submit(): void {
        if (this.inEditMode) {
            return;
        }

        this.apiError = false;
        FormUtil.submitForm(this.zipFormGroup, () => {
            if (this.zipFormControl.valid) {
                this.coverageSubscription = XmStoreUtil.subscribe(this.store.find(StoreAction.GET_COVERAGE, Coverage, { filters: { zipcode: this.zipFormControl.value }, params: { zipcode: this.zipFormControl.value } }), (coverageData: Coverage) => {
                    this.coverage = coverageData;
                    this.formSubmitted = true;
                    this.buildResults(this.coverage, this.zipFormControl.value);
                    this.coverageSubscription = undefined;
                }, () => {
                    this.apiError = true;
                });
            }
        });
    }

    public resetForm(): void {
        this.formSubmitted = false;
    }

    private buildResults(coverageData: Coverage, zipcode: number): void {
        const fullUrl: string = this.pageData.hotSpotLink;
        const fullUrlWithoutBase: string = fullUrl.match(Decorator.DIGITAL_LEARN_URL)[1];
        const baseHrefRoot: string = this.config.BASE_URL === '/' ? '/learn/mobile' : this.config.BASE_URL.match(/(\/.+?)\//)[1];
        this.hotspotLink = `${window.location.origin}${baseHrefRoot}${fullUrlWithoutBase}?zip=${zipcode}`;

        if (this.pageData.wifiBody) {
            (<Field> this.rendering.fields.wifiBody).value = coverageData.hotspotsCount && this.pageData.wifiBody && this.pageData.wifiBody.replace('[COUNT]', coverageData.hotspotsCount.toString());
        }

        if (coverageData.serviceAvailable() && this.pageData.serviceAvailableBody) {
            (<Field> this.rendering.fields.serviceAvailableBody).value = this.pageData.serviceAvailableBody.replace('[ZIP_CODE]', zipcode && zipcode.toString());
        } else if (!coverageData.serviceAvailable() && this.pageData.serviceUnavailableBody) {
            (<Field> this.rendering.fields.serviceUnavailableBody).value = this.pageData.serviceUnavailableBody.replace('[ZIP_CODE]', zipcode && zipcode.toString());
            this.rendering.fields.serviceUnavailableMsg = {
                value : this.pageData.serviceUnavailableMsg.replace('[ZIP_CODE]', zipcode && zipcode.toString())
            };
        }
    }
}
