import { Component, Injector, Input } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss';
import { SitecoreComponent } from 'components/shared/sitecore';
import { Cloudinary } from 'services/cloudinary';

@Component({
  selector: 'flexible-section',
  templateUrl: './flexible-section.component.html',
  styleUrls: [ './flexible-section.component.scss' ]
})
export class FlexibleSectionComponent extends SitecoreComponent {
  @Input() public rendering: ComponentRendering;

  public pageData: FlexibleTextPageData;
  public hasActions: boolean = false;
  public alignment: string;
  public reverseContent: boolean;
  public breakpointImages: MediaImageOptions;

  constructor(injector: Injector) {
      super(injector);
  }

  public ngOnInit(): void {
      this.pageData = this.flattenFields<FlexibleTextPageData>(this.rendering.fields);
      this.alignment = this.pageData.align || 'align-center';
      this.reverseContent = this.pageData.reverseContent || false;
      if (this.pageData.icon) {
          this.breakpointImages = Cloudinary.generateMediaOptionsFromCms(this.pageData.icon);
      }

      this.hasActions = this.checkForActions();
  }

  private checkForActions(): boolean {
      return Boolean(this.hasFieldOrEditable(this.rendering.fields.ctaOne) ||
              this.hasFieldOrEditable(this.rendering.fields.ctaTwo) ||
              this.hasFieldOrEditable(this.rendering.fields.linkDecorationOne) ||
              this.hasFieldOrEditable(this.rendering.fields.linkDecorationTwo) ||
              this.rendering.fields.popover);
  }
}
