import { Component, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SearchApiService } from 'services/api/search.api.service';
import { BreakpointWidth } from 'core/constants';
import { SearchWindowService } from 'services/search-window';
import { ComponentRendering, Field, Item } from '@sitecore-jss/sitecore-jss';

@Component({
    selector: 'xm-search',
    styleUrls: [ './search.scss' ],
    templateUrl: './search.html'
})
export class SearchComponent {

    @Input() public rendering: ComponentRendering;

    public searchResults: string[] = [];

    public searchTerm: string = '';

    public searchIconImgPic: Item | Field | Item[];

    public isMobileView: boolean = false;

    public searchWindowService: SearchWindowService;

    private domainUrl: string = 'https://business.comcast.com/searchresults';

    @Inject(DOCUMENT) private document: Document;
    private searchApiService: SearchApiService;

    constructor(
        @Inject(DOCUMENT) document: Document,
        searchApiService: SearchApiService,
        searchWindowService: SearchWindowService
    ) {
        Object.assign(this, { document, searchApiService, searchWindowService });
    }

    public ngOnInit(): void {
        this.isMobileView = window.innerWidth < BreakpointWidth.MEDIUM;
        this.searchIconImgPic = this.isMobileView ? this.rendering.fields.searchActionMobileIcon : this.rendering.fields.searchActionIcon;
    }

    public toggleSearchWindowView = () => {
        this.searchWindowService.toggle();
    }

    public getResults = () => {
        if (!this.searchTerm) {
            return;
        }

        this.document.location.href = `${this.domainUrl}?q=${encodeURIComponent(this.searchTerm)}`;
    }

    public search = (userInput: string) => {
        this.searchApiService.suggestionsSearchApi(userInput).subscribe((data: string[]) => {
            this.searchResults = data;
        });
    }
}
