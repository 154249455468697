import { Inject, Injectable } from '@angular/core';

import { CONFIG_TOKEN } from 'core/constants';
import { IXMOptions } from 'core/interfaces';
import { SessionStorage } from './storage/session';

@Injectable({
    providedIn: 'root'
})
export class TrialService {
    public static TRIAL_ID: string = 'TRIAL_ID';

    private config: IXMOptions;
    private sessionStorage: SessionStorage;

    constructor(@Inject(CONFIG_TOKEN) config: IXMOptions, sessionStorage: SessionStorage) {
        Object.assign(this, { config, sessionStorage });
    }

    public init(trailQuery: string): void {
        let trialId: string;

        if (trailQuery) {
            this.sessionStorage.set(TrialService.TRIAL_ID, trailQuery);
            trialId = trailQuery;
        } else if (this.sessionStorage.hasKey(TrialService.TRIAL_ID)) {
            trialId = this.sessionStorage.get(TrialService.TRIAL_ID);
        }

        if (trialId) {
            const trialConfigs: string[] = trialId.split('_');

            trialConfigs.forEach((trialConfigId: string) => {
                this.config[this.convertString(trialConfigId)] = true;
            });
        }
    }

    private convertString(trialId: string): string {
        /**
         * This is the "magic" for securing our TRIAL_ID
         *
         * 1. Encode the value using btoa
         * 2. Subtract 15 ascii values for each char
         * 3. Run it through encodeURIComponent to be compatible with the browser url
         *
         * 4. Take the value (Router auto decodes the URI for us)
         * 5. Add 15 ascii values for each char
         * 6. Decode the value using btoa
         */
        const charCodes: number[] = trialId.split('').map((char: string) => char.charCodeAt(0) + 15);

        return atob(charCodes.map((code: number) => String.fromCharCode(code)).join(''));
    }
}
