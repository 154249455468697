import { isNullOrUndefined } from '@uirouter/core';
import { Subscription } from 'rxjs';

export class Util {
    public static cloneProperties<T extends {}>(obj: T): T {
        return JSON.parse(JSON.stringify(obj || {}));
    }

    /**
     * Merge sources deeply with the original target
     */
    public static mergeDeepWith<T>(target: T, ...sources: T[]): T {
        if (!sources.length) {
            return target;
        }

        const source: T = sources.shift();
        if (Util.isObject(target) && Util.isObject(source)) {
            for (const key in source) {
                if (Util.isObject(source[key])) {
                    if (!target[key]) {
                        Object.assign(target, { [key]: {} });
                    }

                    Util.mergeDeepWith(target[key], source[key]);
                } else {
                    Object.assign(target, { [key]: source[key] });
                }
            }
        }

        return Util.mergeDeepWith(target, ...sources);
    }

    /**
     * Merge target and sources deeply into a new object
     */
    public static mergeDeep(...sources: object[]): object {
        const mergedData: object = {};
        Util.mergeDeepWith(mergedData, ...sources);

        return mergedData;
    }

    public static unsubscribeAll(subscriptions: Subscription[] = []): void {
        subscriptions.forEach((sub: Subscription) => {
            if (sub && !sub.closed) {
                sub.unsubscribe();
            }
        });
    }

    public static isObject<T>(item: T): boolean {
        return item && typeof item === 'object' && !(Array.isArray(item));
    }

    public static decodeJWT(token: string): JWT {
        //Added null check to handle if id_token field is null/empty
        return !isNullOrUndefined(token) && JSON.parse(atob(token.split('.')[1]));
    }

    /* eslint-disable  */
    public static getObjectFromQueryString(str: string): UrlQueries {
        if (!str) {
            return {};
        }

        const query: string = str.startsWith('?') ? str.substring(1) : str;

        return query.split('&').filter((item: string) => item.includes('='))
            .reduce((prev: UrlQueries, curr: string) => {
                const p: string[] = curr.split('=');
                prev[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);

                return prev;
            }, {});
    }
    /* eslint-enable */

    public static dotWalk(obj: any, pathToWalk: string): any {
        if (!obj) {
            return undefined;
        }
        const paths: string[] = pathToWalk.split('.');
        let objToWalk: any = obj;

        paths.some((path: string) => {
            objToWalk = objToWalk[path];

            return objToWalk === undefined;
        });

        return objToWalk;
    }

    public static createQueryParams(params: object = {}, starter: string = '?'): string {
        let queryParams: string = starter;
        Object.keys(params).forEach((key: string) => {
            if (params[key] !== undefined && params[key] !== null) {
                queryParams = queryParams.concat(key, '=', params[key], '&');
            }
        });

        return queryParams.slice(0, -1); // Remove either the ? or the &
    }

    public static generateUuid(): string {
        return `${Util.uuidPartial()}${Util.uuidPartial()}-${Util.uuidPartial()}-${Util.uuidPartial()}-${Util.uuidPartial()}${Util.uuidPartial()}${Util.uuidPartial()}`;
    }

    public static decorateFloatingNumbersWithSuperscript(inputString: string): string {
        if (!inputString) {
            return '';
        }

        const match: string[] = inputString.match(/\d+/g);
        if (match && match[1]) {
            return inputString.replace(/\.(\d+)/, `.<span class="superscript">$1</span>`);
        }

        return inputString;
    }

    private static uuidPartial(): string {
        return Math.floor((Math.random() + 1) * 0x10000).toString(16).substring(1);
    }
}
