import { HttpHeaders, HttpParams } from '@angular/common/http';
import { API, ApiChannel, MILLISECOND_MULTIPLIER } from 'core/constants';

export interface IHttpOptions extends Object {
    delay?: number;
    timeout?: number;
    onlyJson?: boolean;
    chainAction?: boolean;
    storeAction?: string;
    httpHeaders?: HttpHeader[];
}

export interface HttpGenericOptions {
    // any other headers they want to add
    [key: string]: string | number | boolean;
}

export interface HttpOptionsNew extends HttpGenericOptions {
    apiChannel: ApiChannel;
    timeout?: number;
    storeAction?: string;
    handleCmsError?: boolean;
}

export class HttpOptions {
    public attempts: number;
    public delay: number;
    public timeout: number;
    public onlyJson: boolean;
    public chainAction: boolean;
    public storeAction: string;

    constructor(options: IHttpOptions = {}) {
        this.attempts = 0;
        this.delay = options.hasOwnProperty('delay') ? options.delay : 0;
        this.timeout = options.hasOwnProperty('timeout') ? options.timeout * MILLISECOND_MULTIPLIER : API.TIMEOUT; // passed in as seconds
        this.onlyJson = options.hasOwnProperty('onlyJson') ? options.onlyJson : true;

        this.chainAction = options.chainAction;
        this.storeAction = options.storeAction;
    }
}

export enum RequestMethod {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    PATCH = 'patch',
    DELETE = 'delete'
}

export function buildHeaders(opts: HttpOptionsNew): HttpHeaders {
    return new HttpHeaders(filterData(opts));
}

export function buildParams(opts: HttpGenericOptions): HttpParams {
    return new HttpParams({ fromObject: filterData(opts) });
}

function filterData(opts: HttpGenericOptions): { [key: string]: string } {
    // we need to cast everything to a string
    const objStrings: { [key: string]: string } = {};
    Object.entries(opts).forEach(([ key, value ]: [ string, string | number | boolean ]) => {
        if (value !== undefined && value !== null) {
            objStrings[key] = value.toString();
        }
    });

    return objStrings;
}
