import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { Util } from 'core/utils/util';

import { Cloudinary } from 'services/cloudinary';
import { WindowReference } from 'services/window';

@Component({
    selector: 'xm-media-image',
    styleUrls: [ './image.scss' ],
    templateUrl: './image.html'
})
export class XmMediaImageComponent implements OnChanges, OnInit, OnDestroy {
    private static SCALAR: number = 60;
    private static RETINA_SCALE: number = 2;

    @Input() public imageOptions: MediaImageOptions;
    @Input() public isFullWidth: boolean = false;

    public isSVG: boolean;
    public imageUrl: string;
    public imageUrl2x: string;
    public imageAlt: string;
    public imageSize: string = '';
    public imageCenter: string = '';

    private currentBreakpoint: Breakpoint;
    private element: ElementRef;
    private subscriptions: Subscription[] = [];
    private triedSecondBounding: boolean = false;

    constructor(element: ElementRef, windowReference: WindowReference) {
        Object.assign(this, { element });

        this.subscriptions.push(windowReference.breakpoint.subscribe((breakpoint: Breakpoint) => {
            this.currentBreakpoint = breakpoint;
            this.checkForBreakpoints();
        }));
    }

    public ngOnInit(): void {
        this.checkForBreakpoints();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.imageOptions) {
            this.checkForBreakpoints();
        }
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    private checkForBreakpoints(): void {
        const currentImage: MediaImageCustom = <MediaImageCustom> Cloudinary.pickBreakpointImage(this.imageOptions, this.currentBreakpoint);
        if (!currentImage) {
            return;
        }

        this.imageSize = currentImage.imageSize || 'size-auto';
        this.imageCenter = currentImage.imageCenter || 'center';
        this.createImagePaths(currentImage);
    }

    private createImagePaths(currentImage: MediaImageCustom): void {
        if (!currentImage.isSvg && currentImage.useBoundingBox) {
            let width: number = 400;
            let height: number = 400;

            if (WindowReference.isWindowAvailable) {
                const box: ClientRect = this.element.nativeElement.getBoundingClientRect();
                width = this.roundUp(box.width);
                height = this.roundUp(box.height);
            }

            if (width > 0 || height > 0) {
                if (this.priorityHeight(currentImage) && height > 0) {
                    this.imageUrl = Cloudinary.generateUrl(-1, height, currentImage);
                    this.imageUrl2x = `${Cloudinary.generateUrl(-1, height * XmMediaImageComponent.RETINA_SCALE, currentImage)} 2x`;
                } else if (this.priorityWidth(currentImage) && width > 0) {
                    this.imageUrl = Cloudinary.generateUrl(width, -1, currentImage);
                    this.imageUrl2x = `${Cloudinary.generateUrl(width * XmMediaImageComponent.RETINA_SCALE, -1, currentImage)} 2x`;
                } else {
                    this.imageUrl = Cloudinary.generateUrl(width, height, currentImage);
                    this.imageUrl2x = `${Cloudinary.generateUrl(width * XmMediaImageComponent.RETINA_SCALE, height * XmMediaImageComponent.RETINA_SCALE, currentImage)} 2x`;
                }
            } else if (!this.triedSecondBounding) {
                this.triedSecondBounding = true;
                setTimeout(() => {
                    this.checkForBreakpoints();
                });
            }
        } else {
            const width: number = currentImage.width || -1;
            const height: number = currentImage.height || -1;

            this.imageUrl = Cloudinary.generateUrl(width, height, currentImage);
            this.imageUrl2x = `${Cloudinary.generateUrl(width * XmMediaImageComponent.RETINA_SCALE, height * XmMediaImageComponent.RETINA_SCALE, currentImage)} 2x`;
        }

        this.imageAlt = currentImage.alt;
        this.isSVG = currentImage.isSvg;
    }

    private roundUp(value: number): number {
        return Math.ceil(value / XmMediaImageComponent.SCALAR) * XmMediaImageComponent.SCALAR;
    }

    private priorityHeight(image: MediaImageCustom): boolean {
        return image.imageSize === 'size-height';
    }

    private priorityWidth(image: MediaImageCustom): boolean {
        return image.imageSize === 'size-width';
    }
}
