import { Action } from '@ngrx/store';

export class XmAction<T> implements Action {
    public type: string;
    public payload?: T;
    public filters?: StoreFilters;
    public params?: ApiParams;
}

export enum StoreAction {
    CLEAR_ON_SUBMIT = 'CLEAR_ON_SUBMIT',

    GET_ACCOUNT = 'GET_ACCOUNT',

    GET_CART = 'GET_CART',

    GET_COVERAGE = 'GET_COVERAGE',

    GET_CMS_PAGE_DATA = 'GET_CMS_PAGE_DATA',

    GET_CATALOG = 'GET_CATALOG',

    GET_BUY_INFO = 'GET_BUY_INFO'
}
