<section>
    <div *ngIf="!inEditMode">
        <button
            class="xm-btn-link-light"
            *scRichText="rendering.fields.openerCta"
            (click)="viewModal()">
        </button>
    </div>
    <div *ngIf="inEditMode">
        <span class="xm-btn-link-light" *scRichText="rendering.fields.openerCta"></span>
    </div>
    <div #editModeView class="edit-mode" *ngIf="inEditMode" aria-hidden="true">
        <xm-media-image class="image" [imageOptions]="headerImage"></xm-media-image>

        <p *scRichText="rendering.fields.title"></p>
        <h2 *scRichText="rendering.fields.header"></h2>
        <div class="table-head">
            <div class="first-column" *scRichText="rendering.fields.tableHeaderFirstColumn"></div>
            <div class="second-column" *scRichText="rendering.fields.tableHeaderSecondColumn"></div>
            <div class="third-column" *scRichText="rendering.fields.tableHeaderThirdColumn"></div>
        </div>
        <ng-container *ngIf="hasPlaceholder(rendering, 'unlPricingGroups')">
            <div class="placeholders" sc-placeholder name="unlPricingGroups" [rendering]="rendering"></div>
        </ng-container>
        <p class="xm-btn-link-light" *scRichText="rendering.fields.modalCta"></p>
        <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.bottomDescription)" [field]="rendering.fields.bottomDescription"></xm-cms-text>
    </div>
</section>
