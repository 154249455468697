<fieldset role="radiogroup" class="colors" *ngIf="!asButtons">
    <div class="variant-item" *ngFor="let productColor of displayColors">
        <label class="color color-button" [ngClass]="{ 'variant-hover': !isMobileOrTablet }"
            for="{{ labelFor(productColor.name) }}">
            <input id="{{ labelFor(productColor.name) }}" name="{{ product.slug }}-color" type="radio"
                value="{{ productColor.name }}"
                (click)="selectVariant(productColor)"
                [checked]="isActiveColor(productColor)"/>
            <div class="variant-circle" [ngStyle]="{ 'background-color': productColor.hex, 'border-color': productColor.hex }" [ngClass]="{ 'low-contrast': colorContrast(productColor.hex), 'active': isActiveColor(productColor) }"></div>
            <span class="color-tooltip" aria-hidden="true">{{ productColor.name }}</span>
            <span class="accessibility-hidden">{{ product.name }} {{ productColor.name }}</span>
        </label>
    </div>
</fieldset>

<div class="colors" *ngIf="asButtons">
    <a *ngFor="let productColor of displayColors"
        [uiSref]="productLink"
        [uiParams]="{ slug: product.slug, colorName: productColor.name }"
        [ngClass]="{ 'low-contrast': colorContrast(productColor.hex), 'variant-hover': !isMobileOrTablet }"
        [ngStyle]="{ 'background-color': productColor.hex }"
        (mouseenter)="mouseEnter()"
        (mouseleave)="mouseLeave()"
        class="variant-circle variant-item color-button">
        <span class="color-tooltip" [ngClass]="{ 'right-align': rightAlign }" aria-hidden="true">{{ productColor.name }}</span>
        <span class="accessibility-hidden">{{ product.name }} {{ productColor.name }}</span>
    </a>
</div>
