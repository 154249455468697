import { Component, Injector, Input, OnInit } from '@angular/core';
import { Field } from '@sitecore-jss/sitecore-jss';
import { SitecoreComponent } from 'components/shared/sitecore';

import { Decorator, LinkDecorationInfo } from 'services/decorator';

@Component({
    selector: 'xm-generic-link',
    styleUrls: [ './generic-link.scss' ],
    templateUrl: './generic-link.html'
})
export class XmGenericLink extends SitecoreComponent implements OnInit {
    @Input() public field: Field<CmsGenericLink>;
    @Input() public icon?: MediaImageOptions;
    @Input() public theme?: string = 'light';
    @Input() public dataOptionCta: boolean;

    public genericLinkDecoration: LinkDecorationInfo;

    private decorator: Decorator;

    constructor(injector: Injector, decorator: Decorator) {
        super(injector);
        Object.assign(this, { decorator });
    }

    public ngOnInit(): void {
        if (!this.inEditMode) {
            this.genericLinkDecoration = this.decorator.createGenericLinkDecoration(this.field.value);
        }
    }
}
