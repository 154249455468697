import { Directive, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgControl } from '@angular/forms';
import { FormType, KeyboardEventKeys } from 'core/constants';

@Directive({
    selector: '[xmMask]',
    host: {
        '(keydown)': 'onKeyPress($event)',
        '(keydown.backspace)': 'onBackspace($event.target)',
        '(keydown.delete)': 'onDelete($event.target)',
        '(input)': 'onInput($event)',
        '(blur)': 'onBlur($event)'
    }
})
export class XmMask implements OnInit, OnChanges {
    private static slot: string = '_';
    private static def: object = {
        9: /\d/,
        a: /a-zA-Z\s/,
        '*': /[0-9a-zA-Z]/
    };

    @Input() public mask: string = '';
    @Input() public enableMask: boolean = false;
    @Input() public defaultAttrType: string = 'text';
    @Input() public template: string;

    // raw with template ex. 123-12-1234
    @Output() public rawTemplate: EventEmitter<string> = new EventEmitter<string>();

    // raw with no template ex. 123121234
    @Output() public raw: EventEmitter<string> = new EventEmitter<string>();

    // raw with template and masking ex. ***-**-****
    @Output() public maskedTemplate: EventEmitter<string> = new EventEmitter<string>();

    private placeholder: string = '';
    private placeholderSplit: string[] = [];
    private regExpValidator: RegExp[] = [];
    private regExpValidatorLength: number = 0;
    private currentPosition: number;
    private nextPosition: number;
    private isDelete: boolean = false;

    private rawVal: string;
    private rawTemplateVal: string;
    private maskedTemplateVal: string;

    private nativeElement: HTMLInputElement;
    private ctr: NgControl;
    private document: Document;
    private emitOnBlur: boolean;

    constructor(element: ElementRef, ctr: NgControl, @Inject(DOCUMENT) document: Document) {
        Object.assign(this, { nativeElement: element.nativeElement, ctr, document });
    }

    public ngOnInit(): void {
        this.emitOnBlur = this.ctr.control.updateOn === FormType.blur;
        this.mask = this.mask.charAt(0); // safety check if a mask has more than one character

        this.processTemplate();
        this.setInputField(this.ctr.control.value);
        this.setAttributeType();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        // only watch for changes to the mask
        if (changes && changes.enableMask && !changes.enableMask.firstChange) {
            this.setInputField(this.ctr.control.value);
            this.setAttributeType();
        }
    }

    public onBlur(): void {
        this.emitData();
    }

    public onInput(event: KeyboardEvent): void {
        const target: HTMLInputElement = <HTMLInputElement> event.target;
        this.setInputField(target.value);
    }

    public onKeyPress(event: KeyboardEvent): void {
        // firefox has a bug which registers keypress for backspace...we need to ignore it
        // ignore enter click too
        if (event.key === KeyboardEventKeys.BACKSPACE || event.key === KeyboardEventKeys.DELETE || event.key === KeyboardEventKeys.ENTER) {
            return;
        }

        const target: HTMLInputElement = <HTMLInputElement> event.target;
        this.isDelete = false;
        this.currentPosition = target.selectionStart;
    }

    public onBackspace(target: HTMLInputElement): void {
        if (target.selectionStart === 0) {
            return;
        }

        this.isDelete = true;
        this.currentPosition = target.selectionStart;
        this.nextPosition = this.findPreviousSlot(target.selectionStart, target.selectionEnd);
    }

    public onDelete(target: HTMLInputElement): void {
        this.isDelete = true;
        this.currentPosition = target.selectionStart;
        this.nextPosition = this.findNextSlot(target.selectionStart) - 1;
    }

    private get hasMask(): boolean {
        return this.mask && this.enableMask;
    }

    private setAttributeType(): void {
        this.nativeElement.setAttribute('type', this.enableMask ? 'password' : this.defaultAttrType);
    }

    private processTemplate(): void {
        Array.from(this.template).forEach((letter: string) => {
            if (XmMask.def[letter]) {
                this.placeholderSplit.push(XmMask.slot);
                this.regExpValidator.push(new RegExp(XmMask.def[letter]));
            } else {
                this.placeholderSplit.push(letter);
            }
        });

        this.placeholder = this.placeholderSplit.join('');
        this.regExpValidatorLength = this.regExpValidator.length;
    }

    private findNextSlot(start: number): number {
        let nextSlot: number = start;

        // continue to find the index that doesn't match the placeholder character
        while (nextSlot < this.placeholder.length) {
            if (this.placeholder[nextSlot] === XmMask.slot) {
                break;
            }

            nextSlot++;
        }

        // we want to move the cursor right after the index we found
        return nextSlot + 1;
    }

    private findPreviousSlot(start: number, end: number): number {
        if (start !== end) {
            return start;
        }

        let foundTempalteSlot: boolean = false;
        let nextSlot: number = start - 1;

        while (nextSlot > 0) {
            if (this.placeholder[nextSlot] === XmMask.slot) {
                break;
            }

            nextSlot--;
            foundTempalteSlot = true;
        }

        return foundTempalteSlot ? nextSlot + 1 : nextSlot;
    }

    private emitData(): void {
        this.ctr.control.setValue(this.rawVal, { emitEvent: false, emitModelToViewChange: false });

        this.raw.emit(this.rawVal);
        this.rawTemplate.emit(this.rawTemplateVal);
        this.maskedTemplate.emit(this.maskedTemplateVal);
    }

    private setInputField(value: string): void {
        const newChars: string = this.validCharacters(value);
        const isDiff: boolean = newChars !== this.rawVal;

        if (isDiff && !this.isDelete) {
            this.nextPosition = this.findNextSlot(this.currentPosition);
        }

        this.rawVal = newChars;
        this.rawTemplateVal = this.buildTemplateFromRaw(this.rawVal);

        if (this.hasMask) {
            this.maskedTemplateVal = this.buildTemplateFromRaw(this.rawTemplateVal, true);
            this.nativeElement.value = this.maskedTemplateVal;
        } else {
            this.nativeElement.value = this.rawTemplateVal;
        }

        // only need to set the position if a value exists and we are focused on the element we are editing
        if (this.rawVal && this.document.activeElement === this.nativeElement) {
            if (this.isDelete || (isDiff && this.nextPosition >= 0)) {
                this.nativeElement.setSelectionRange(this.nextPosition, this.nextPosition);
                this.nextPosition = -1; // reset it
            } else {
                this.nativeElement.setSelectionRange(this.currentPosition, this.currentPosition);
            }
        }

        // if this control is not marked for blur, then emit now.
        // Otherwise let the blur event do the emitting
        if (!this.emitOnBlur) {
            this.emitData();
        }
    }

    private validCharacters(value: string): string {
        const valueString: string = value || '';
        let validChars: string = '';
        let regExpIndex: number = 0;
        let rawIndex: number = 0;

        Array.from(valueString).some((char: string) => {
            if (regExpIndex === this.regExpValidatorLength) {
                return true;
            }

            if (this.hasMask && char === this.mask) {
                validChars += this.rawVal[rawIndex];
                rawIndex++;

                // increment this since we copied over from the raw value
                regExpIndex++;
            } else if (this.regExpValidator[regExpIndex].test(char)) {
                validChars += char;
                regExpIndex++;
            }
        });

        return validChars;
    }

    private buildTemplateFromRaw(raw: string, useMask: boolean = false): string {
        const rawLength: number = raw.length;
        let rawIndex: number = 0;
        let value: string = '';

        this.placeholderSplit.some((letter: string, index: number) => {
            if ((useMask && index === rawLength) || rawIndex === rawLength) {
                return true;
            }

            if (letter === XmMask.slot) {
                if (useMask) {
                    value += this.mask;
                } else {
                    value += raw[rawIndex];
                    rawIndex++;
                }
            } else if (!useMask) {
                value += letter;
            }
        });

        return value;
    }
}
