import { Component, Injector, Input, OnInit } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';

import { SitecoreComponent } from 'components/shared/sitecore';
import { Cloudinary } from 'services/cloudinary';

@Component({
    selector: 'marketing-plan-options',
    styleUrls: [ './plan-options.scss' ],
    templateUrl: './plan-options.html'
})
export class MarketingPlanOptionsModule extends SitecoreComponent implements OnInit {
    @Input() public rendering: ComponentRendering;

    public byTheGigIcon: MediaImageOptions;
    public unlimitedIcon: MediaImageOptions;
    public mixAndMatchIcon: MediaImageOptions;

    constructor(injector: Injector) {
        super(injector);
    }

    public ngOnInit(): void {
        const pageData: PlanOptionsPageData = this.flattenFields<PlanOptionsPageData>(this.rendering.fields);

        if (pageData.byTheGigIcon) {
            this.byTheGigIcon = Cloudinary.generateMediaOptionsFromCms(pageData.byTheGigIcon);
        }

        if (pageData.unlimitedIcon) {
            this.unlimitedIcon = Cloudinary.generateMediaOptionsFromCms(pageData.unlimitedIcon);
        }

        if (pageData.mixAndMatchIcon) {
            this.mixAndMatchIcon = Cloudinary.generateMediaOptionsFromCms(pageData.mixAndMatchIcon);
        }
    }
}
