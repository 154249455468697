import { Component, HostListener, Injector, Input } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { SitecoreComponent } from 'components/shared/sitecore';
import { BreakpointWidth } from 'core/constants';

@Component({
    selector: 'marketing-vertical-spacer',
    styleUrls: [ './spacer.scss' ],
    templateUrl: './spacer.html'
})
export class MarketingVerticalSpacerComponent extends SitecoreComponent {
    @Input() public rendering: ComponentRendering;

    public pageData: VerticalSpacerPageData;
    public backgroundColorStyles: VerticalSpacerBackground;
    public spacerContainer: string;

    private currentWindowWidth: number = 0;

    constructor(injector: Injector) {
        super(injector);
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<VerticalSpacerPageData>(this.rendering.fields);
        this.spacerContainer = this.pageData.spacerContainer;
        this.currentWindowWidth = window.innerWidth;
        this.setSpacerDetails(this.currentWindowWidth);

    }

    @HostListener('window:resize', [ '$event' ])
    public onResize(event: UIEvent): boolean {

        const w: Window = event.target as Window;

        if (this.currentWindowWidth && this.currentWindowWidth === w.innerWidth) {
            return false;
        }

        this.currentWindowWidth = w.innerWidth;
        this.setSpacerDetails(this.currentWindowWidth);

        return true;

    }

    private setSpacerDetails = (width: number) => {
        let spacerHeight: string;

        const screenWidth: number = width;
        if (screenWidth >= BreakpointWidth.LARGE) {
            spacerHeight = this.pageData.spacerHeightLarge ? this.pageData.spacerHeightLarge : '';
        } else if (screenWidth <= BreakpointWidth.MEDIUM && screenWidth > BreakpointWidth.SMALL) {
            spacerHeight = this.pageData.spacerHeightMedium ? this.pageData.spacerHeightMedium : '';
        } else if (screenWidth <= BreakpointWidth.SMALL) {
            spacerHeight = this.pageData.spacerHeightSmall ? this.pageData.spacerHeightSmall : '';
        }

        this.backgroundColorStyles = {
            backgroundColor: this.pageData.backgroundColor || '#FFFFFF',
            height: spacerHeight ? spacerHeight :  this.pageData.backgroundHeight || '40px'
        };
    }
}
