import { Inject, Injectable, isDevMode } from '@angular/core';
import { CONFIG_TOKEN, StorageToken } from 'core/constants';
import { CimaToken } from './token';
import { Util } from 'src/marketing/core/utils/util';
import { SessionStorage } from '../storage/session';
import { IXMOptions } from 'core/interfaces';
import { WindowReference } from '../window';

@Injectable({
    providedIn: 'root'
})
export class CimaUrl {
    private cimaToken: CimaToken;
    private config: IXMOptions;
    private sessionStorage: SessionStorage;

    constructor(cimaToken: CimaToken, sessionStorage: SessionStorage, @Inject(CONFIG_TOKEN) config: IXMOptions) {
        Object.assign(this, { cimaToken, sessionStorage, config });
    }

    public redirectToLogin(returnState: SavedRouteState, additionalPath: string = ''): void {
        this.sessionStorage.set(StorageToken.SAVED_STATE, JSON.stringify(returnState));

        window.location.assign(this.loginString(additionalPath));
    }

    public loginString(additionalPath: string = ''): string {
        const queryParams: string = Util.createQueryParams({
            client_id: this.config.CLIENT_ID,
            redirect_uri: this.browserRedirectUri(additionalPath),
            response_type: 'code'
        });

        return `${this.config.CIMA_BASE_URL}/oauth/authorize${queryParams}`;
    }

    public redirectToLogout(returnURI: string = ''): void {
        if (!WindowReference.isWindowAvailable) {
            return;
        }

        this.cimaToken.logoutCleanup();

        window.location.assign(this.logoutString(returnURI));
    }

    public logoutString(returnURI: string = ''): string {
        if (!WindowReference.isWindowAvailable) {
            return;
        }

        const params: CimaLogout = {
            client_id: this.config.CLIENT_ID
        };

        // only if coming from an xfinity domain will we set a state
        if (!isDevMode() && /comcast\.com/.test(window.location.origin)) {
            params.state = encodeURIComponent(`${window.location.origin}${this.config.BASE_URL}${this.config.CIMA_PATH_URL}${returnURI}`);
        }

        const queryParams: string = Util.createQueryParams(params);

        return `${this.config.CIMA_BASE_URL}/oauth/sp-logout${queryParams}`;
    }

    public browserRedirectUri(additionalPath: string = ''): string {
        const endUrlPath: string = `${window.location.origin}${this.config.BASE_URL}${this.config.CIMA_PATH_URL}${additionalPath ? additionalPath + '/' : ''}login`;

        if (this.config.CIMA_RETURN_URL) {
            return encodeURIComponent(`${this.config.CIMA_RETURN_URL}?state=${endUrlPath}`);
        }

        return encodeURIComponent(endUrlPath);
    }

    public apiRedirectUri(isPassiveFlow: boolean = false): string {
        if (isPassiveFlow) {
            return `${window.location.origin}${this.config.BASE_URL}${this.config.CIMA_PATH_URL}cima.html`;
        } else if (this.config.CIMA_RETURN_URL) {
            return `${this.config.CIMA_RETURN_URL}?state=${window.location.href.split('?')[0]}`;
        } 
            
        return window.location.href.split('?')[0];
    }
}
