import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiChannel, StorageToken } from 'core/constants';
import { XmHttp } from '../http/http';
import { SessionStorage } from '../storage/session';

interface VisitorData {
    visitorSessionId: string;
}

@Injectable({
    providedIn: 'root'
})

export class VisitorSessionService {
    private xmHttp: XmHttp;
    private sessionStorage: SessionStorage;

    constructor(sessionStorage: SessionStorage, http: HttpClient, xmHttp: XmHttp) {
        Object.assign(this, { sessionStorage, http, xmHttp });
    }

    public init(): void {
        if (!this.sessionStorage.hasKey(StorageToken.VISITOR_SESSION_ID) || this.sessionStorage.get(StorageToken.VISITOR_SESSION_ID) === 'undefined') {
            this.getVisitorSessionID();
        }
    }

    private getVisitorSessionID(): void {
        this.xmHttp.get(ApiChannel.SESSION_API, `businesswebapi/visitorsession`).toPromise()
        .then((result: VisitorData) => {
            this.sessionStorage.set(StorageToken.VISITOR_SESSION_ID, result.visitorSessionId);
        })
        .catch(() => Promise.resolve(undefined));
    }
}
