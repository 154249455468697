import { ChildModel, ModelBase } from 'core/store/model-base';
import { PlanSku, PromotionType } from 'core/constants';
import { Promotion } from './promotion';

export class PriceOption extends ModelBase {
    public id: PlanSku;
    public originalPrice: number;
    public promotionId: string;
    public promotionPrice: number;
    public promotionalType: PromotionType;
    public promotionalValue: number;
    public promotionalValueTotal: number;
    public term: number;
    public downPayment: number;
    public payOffAmount: number;
    public promotions: Promotion[];

    protected static get hasMany(): ChildModel[] {
        return [{
            attrName: 'promotions',
            model: Promotion
        }];
    }

    public get isFinanced(): boolean {
        return this.id === PlanSku.FINANCED;
    }

    public get isFull(): boolean {
        return this.id === PlanSku.PAY_IN_FULL;
    }

    public get total(): number {
        return this.isSubsidyPromo ? this.promotionPrice : (this.promotionPrice || this.originalPrice);
    }

    public get savings(): number {
        return this.promotionalType && !this.isSubsidyPromo ? this.promotionalValue : 0;
    }

    public get type(): string {
        return this.isFinanced ? 'Pay monthly' : 'Pay in full';
    }

    public get isPrepaidPromo(): boolean {
        return this.promotions.some((promotion: Promotion) => promotion.isPrepaid);
    }

    public get isDollarDiscount(): boolean {
        return this.promotions.some((promotion: Promotion) => promotion.isDollarAmount);
    }

    public get isPercentPromo(): boolean {
        return this.promotions.some((promotion: Promotion) => promotion.isPercentage);
    }

    public get isExtendedPromo(): boolean {
        return this.promotions.some((promotion: Promotion) => promotion.isExtendedPromo || promotion.isExtendedPromoPhase2);
    }

    public get extendedPromo(): Promotion {
        return this.promotions.find((promotion: Promotion) => promotion.isExtendedPromo || promotion.isExtendedPromoPhase2);
    }

    public get isSubsidyPromo(): boolean {
        return this.promotions.some((promotion: Promotion) => promotion.isSubsidy);
    }

    public get activePrice(): number {
        return this.isSubsidyPromo ? this.promotionPrice : this.originalPrice;
    }
}
