import { Component, Injector, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Field } from '@sitecore-jss/sitecore-jss';
import { SitecoreComponent } from 'components/shared/sitecore';

@Component({
    selector: 'xm-cms-text',
    styleUrls: [ './text.scss' ],
    templateUrl: './text.html'
})
export class XmCmsTextComponent extends SitecoreComponent implements OnInit {
    @Input() public field: Field;
    @Input() public menuHeader?: boolean;
    @Input() public applyDropdownTextStyle?: boolean;
    @Input() public customClass: string = '';
    @Input() public theme: string = '';

    public afterText: string = '';
    public cmsReplaces: CmsLinkReplace[] = [];

    private sanitizer: DomSanitizer;

    constructor(injector: Injector, sanitizer: DomSanitizer) {
        super(injector);

        Object.assign(this, { sanitizer });
    }

    public ngOnInit(): void {
        // don't process the link if not existing or in edit mode
        if (!this.field || this.field.editable) {
            return;
        }

        this.generatedLinkFromCms((<string> this.field.value));
    }

    public safeUrl(url: string): SafeUrl {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    /**
     * Takes inline text with an action and converts to the respective <span>, <a> or <button>
     *
     * @param text the original text to find and replace with links and/or bold text
     * @param functionName the name of the function to link with (NOTE: only works if original text has one link to replace)
     *
     *
     * Examples:
     *
     * URL LINKS
     *
     * Link Text and Full URL Path:
     * [Click Here|www.google.com]  =>  <a class="xm-btn-link-light" href="www.googe.com" target="_blank">Click Here</a>
     *
     * Link Text, Aria Text and Link Action:
     * [Click Here#Click here to open google|www.google.com]  =>  <a class="xm-btn-link-light" href="www.googe.com" target="_blank" aria-label="Click here to open google">Click Here</a>
     *
     * Link Text and State Name:
     * [Click Here|state.name]  =>  <a class="xm-btn-link-light" uiSref="state.name">Click Here</a>
     *
     * Link Text, State Name, and State Params:
     * [Click Here|state.name|'brand': 'Apple']  =>  <a class="xm-btn-link-light" uiSref="state.name" [uiParams]="{ 'brand': 'Apple' }">Click Here</a>
     *
     * Link Text and State Name LOOKS AS Primary Button:
     * [pri(Click Here|state.name)]  =>  <a class="xm-btn-primary-light" uiSref="state.name">Click Here</a>
     *
     * Link Text and State Name LOOKS AS Secondary Button:
     * [sec(Click Here|state.name)]  =>  <a class="xm-btn-secondary-light" uiSref="state.name">Click Here</a>
     *
     * Link Text LOOKS AS Parent styles:
     * [^Click Here|www.google.com^]  =>  <a class="xm-btn-link-light inline-text-replace" href="www.googe.com" target="_blank">Click Here</a>
     *
     *
     * BUTTONS
     *
     * Link Text and Button:
     * [Click Here]  =>  <button class="xm-btn-link-light" (click)="buttonClick()">Click Here</button>
     *
     * Link Text, Aria Text and Button:
     * [Click Here#Click here to open google]  =>  <button class="xm-btn-link-light" (click)="buttonClick()" aria-label="Click here to open google">Click Here</button>
     *
     * Link Text and Button LOOKS AS Primary Button:
     * [pri(Click Here)]  =>  <button class="xm-btn-primary-light" (click)="buttonClick()">Click Here</button>
     *
     * Link Text and Button LOOKS AS SECONDARY Button:
     * [sec(Click Here)]  =>  <button class="xm-btn-secondary-light" (click)="buttonClick()">Click Here</button>
     *
     * Link Text LOOKS AS Parent styles:
     * [^Click Here^]  =>  <a class="xm-btn-link-light inline-text-repalce" uiSref="state.name">Click Here</a>
     *
     */
    private generatedLinkFromCms(text: string): void {
        if (!text) {
            return;
        }

        // CMS markdown to bold text
        // Ex. bold(Text) -> <span class="bold">Text</span>
        let generatedText: string = text.replace(/bold\((.+?)\)/g, '<span class="bold">$1</span>');

        const matches: RegExpMatchArray = generatedText.match(/\[.+?\]/g);

        if (matches && matches.length > 0) {
            matches.forEach((match: string) => {
                const newCmsReplace: CmsLinkReplace = {};
                // variable to check whether state url to be opened in a new tab or not
                let isNewTab: boolean;
                // split the text by match and get the before text
                newCmsReplace.beforeText = generatedText.split(match, 1)[0];
                // reassign the after text so we can continue to loop over all replacements
                generatedText = generatedText.substring(newCmsReplace.beforeText.length + match.length);
                // determine what type of link this is
                let insideMatch: string = this.findTypeOfLink(match, newCmsReplace);
                // determine if the link should use parent styles
                if (insideMatch.startsWith('^') && insideMatch.endsWith('^')) {
                    newCmsReplace.useParentStyles = true;
                    insideMatch = insideMatch.substr(1, insideMatch.length - 2);
                }
                // determine if the state url link to be opened in a new tab
                if (insideMatch.startsWith('<') && insideMatch.endsWith('>')) {
                    isNewTab = true;
                    insideMatch = insideMatch.substr(1, insideMatch.length - 2);
                }
                // separate the text from the action
                const data: string[] = insideMatch.split('|');
                [ newCmsReplace.linkName, newCmsReplace.linkAction ] = insideMatch.split('|');
                [ newCmsReplace.linkName, newCmsReplace.linkAccessibility ] = newCmsReplace.linkName.split('#');

                if (newCmsReplace.linkAction && (
                        this.absoluteUrl(newCmsReplace.linkAction) ||
                        this.relativeUrl(newCmsReplace.linkAction) ||
                        this.httpProtocol(newCmsReplace.linkAction)
                    )) {
                    newCmsReplace.linkTarget = this.absoluteUrl(newCmsReplace.linkAction) ? '_blank' : '_self';
                    newCmsReplace.externalLink = true;
                } else if (newCmsReplace.linkAction && this.isAppUrl(newCmsReplace.linkAction)) {
                    newCmsReplace.linkAction = newCmsReplace.linkAction.split('app:')[1];
                    newCmsReplace.inAppUrl = true;
                } else if (newCmsReplace.linkAction) {
                    newCmsReplace.internalLink = true;
                    newCmsReplace.linkTarget = isNewTab ? '_blank' : '_self';
                } else {
                    newCmsReplace.buttonLink = true;
                }

                // let's see if they passed UI-Params. If so, attach curly braces
                newCmsReplace.linkParams = data.length === 3 ? JSON.parse((`{${data[2]}}`).replace(/\'/g, '"')) : '';

                this.cmsReplaces.push(newCmsReplace);
            });
        }

        this.afterText = generatedText;
    }

    private findTypeOfLink(match: string, newCmsReplace: CmsLinkReplace): string {
        // remove the starting '[ ' and ending ' ]'
        let insideMatch: string = match.match(/\[(.+?)\]/)[1];

        if (insideMatch.startsWith('pri(') && insideMatch.endsWith(')')) {
            newCmsReplace.displayAsPrimary = true;
            insideMatch = insideMatch.substr(4).substr(0, insideMatch.substr(4).length - 1);
        } else if (insideMatch.startsWith('sec(') && insideMatch.endsWith(')')) {
            newCmsReplace.displayAsSecondary = true;
            insideMatch = insideMatch.substr(4).substr(0, insideMatch.substr(4).length - 1);
        } else if (insideMatch.startsWith('byow(') && insideMatch.endsWith(')')) {
            newCmsReplace.displayByowLink = true;
            insideMatch = insideMatch.substr(5).substr(0, insideMatch.substr(5).length - 1);
        } else if (insideMatch.startsWith('bywb(') && insideMatch.endsWith(')')) {
            newCmsReplace.displayAsPrimary = true;
            newCmsReplace.displayByowButton = true;
            insideMatch = insideMatch.substr(5).substr(0, insideMatch.substr(5).length - 1);
        } else if (insideMatch.startsWith('plde(') && insideMatch.endsWith(')')) {
            newCmsReplace.displayPlanDetailsLink = true;
            insideMatch = insideMatch.substr(5).substr(0, insideMatch.substr(5).length - 1);
        } else if (insideMatch.startsWith('plds(') && insideMatch.endsWith(')')) {
            newCmsReplace.displayPlanDetailsLinkSecondary = true;
            insideMatch = insideMatch.substr(5).substr(0, insideMatch.substr(5).length - 1);
        } else {
            newCmsReplace.displayAsLink = true;
        }

        return insideMatch;
    }

    private absoluteUrl(link: string): boolean {
        return link.startsWith('www.') || link.startsWith('http');
    }

    private relativeUrl(link: string): boolean {
        return link.startsWith('/');
    }

    private isAppUrl(link: string): boolean {
        return link.startsWith('app:');
    }

    private httpProtocol(link: string): boolean {
        return link.startsWith('tel:') || link.startsWith('sms:') || link.startsWith('mailto:');
    }
}
