import { Component, Injector, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ComponentRendering, RouteData } from '@sitecore-jss/sitecore-jss';
import { StateService } from '@uirouter/core';
import { Subscription } from 'rxjs';

import { CmsService, GlobalNav, Seo, SessionStorage, WindowReference } from 'core/services';
import { XmStore, XmStoreUtil } from 'core/services/store';
import { SitecoreComponent } from 'components/shared/sitecore';
import { CAMPAIGN_ID, MARKETING_PARAMS } from 'core/constants';
import { Util } from 'core/utils/util';
import { CmsPageData } from 'core/store/cms/model/cms';

@Component({
    selector: 'marketing-base',
    styleUrls: [ './base.scss' ],
    templateUrl: './base.html'
})
export class MarketingBaseComponent extends SitecoreComponent implements OnDestroy, OnInit {
    public routeData: RouteData;
    public isPopOverEnabled: boolean = false;
    public editorActive: boolean = false;
    public bannerEnabled: boolean = false;
    public paddingTop: string;
    public bodyContainer: string;

    private cms: CmsService;
    private seo: Seo;
    private state: StateService;
    private sessionStorage: SessionStorage;
    private windowRef: WindowReference;

    private subscriptions: Subscription[] = [];
    private xmStore: XmStore;

    private contentElement: HTMLElement;
    private navElement: HTMLElement;
    private mobileMenuElement: HTMLElement;
    private readonly SITECORE_BODY_CONTAINER: string = 'page-not-found';
    private readonly BODY_CONTAINER: string = '404';
    private globalNav: GlobalNav;
    private renderer2: Renderer2;

    constructor(injector: Injector, cms: CmsService, seo: Seo, state: StateService, sessionStorage: SessionStorage, windowRef: WindowReference, xmStore: XmStore, globalNav: GlobalNav, renderer2: Renderer2) {
        super(injector);

        Object.assign(this, { cms, seo, state, sessionStorage, windowRef, xmStore, globalNav, renderer2 });
    }

    public ngOnInit(): void {
        this.subscriptions.push(XmStoreUtil.subscribe(this.xmStore.peek<CmsPageData>(CmsPageData, {
            filters: { pageId: this.state.params.page_id }
        }), (response: CmsPageData) => {
            this.editorActive = this.isExperienceEditorActive();

            this.cms.setActivePageData(response.sitecorePage);
            this.routeData = this.cms.getReplacedTargetData();
            this.seo.updateMetaTags(this.flattenFields<MetaDataDefault>(this.routeData.fields));
            this.bannerEnabled = this.enableBanner();
            if (WindowReference.isWindowAvailable && !this.editorActive) {
                setTimeout(() => {
                    this.getElementsToStyle();
                    this.calculateTopPadding();
                });

                this.subscriptions.push(this.windowRef.windowWidth.subscribe(() => {
                    this.calculateTopPadding();
                }));
            }

            if (this.routeData.name === this.BODY_CONTAINER) {
                this.bodyContainer = 'page-not-found';
            } else if (this.routeData.name === this.SITECORE_BODY_CONTAINER) {
                this.bodyContainer = 'page-not-found';
            }
        }));
        this.globalNav.initGlobalNav(this.renderer2);
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    public enableBanner(): boolean {
        let campaignId: string = this.sessionStorage.get(CAMPAIGN_ID);
        MARKETING_PARAMS.forEach((id: string) => {
            campaignId = campaignId || this.state.params[id];
        });

        const banner: ComponentRendering[] = <ComponentRendering[]> this.routeData.placeholders.sitePromoBanner;
        const bannerData: PromoBannerPageData = banner && banner[0] && this.flattenFields<PromoBannerPageData>(banner[0].fields);
        const csv: string = bannerData && bannerData.validCampaigns && bannerData.validCampaigns.replace(/\s/g, '');
        const validCampaigns: string[] = csv && csv.split(',');
        const hasValidCampaignId: boolean = validCampaigns && validCampaigns.some((id: string) => id === campaignId);

        if (hasValidCampaignId) {
            this.sessionStorage.set(CAMPAIGN_ID, campaignId);
        }

        return hasValidCampaignId;
    }

    private getElementsToStyle(): void {
        this.navElement = <HTMLElement> window.document.getElementsByClassName('navigation')[0];
        this.contentElement = window.document.getElementById('content');
        this.mobileMenuElement = <HTMLElement> window.document.getElementsByClassName('mobile-menu-list')[0];
    }

    private calculateTopPadding(): void {
        let navHeight: string = '0px';

        if (this.navElement) {
            navHeight = `${this.navElement.clientHeight}px`;
        }

        if (this.contentElement) {
            this.contentElement.style.paddingTop = navHeight;
        }

        if (this.mobileMenuElement) {
            this.mobileMenuElement.style.top = navHeight;
            this.mobileMenuElement.style.height = `calc(100% - ${this.mobileMenuElement.style.top})`;
        }
    }
}
