import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as MobileDetect from 'mobile-detect';
import { Product } from 'core/store/catalog/model/product';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'xm-color-picker',
    styleUrls: [ './picker.scss' ],
    templateUrl: './picker.html'
})
export class ColorPickerComponent implements OnInit {
    @Input() public rightAlign: boolean;
    @Input() public asButtons: boolean;
    @Input() public product: Product;
    @Input() public activeColor: ProductColor;
    @Input() public displayColors: ProductColor[];
    @Output() public variantChange: EventEmitter<ProductColor> = new EventEmitter<ProductColor>();
    @Output() public mouseOverColor: EventEmitter<boolean> = new EventEmitter<boolean>();
    public isMobileOrTablet: boolean;
    public productLink: string;

    private HEX_LENGTH: number = 2;
    private HEX_BASE: number = 16;
    private HEX_MAX: number = 255;
    private COLOR_BASE_VALUE: number = this.HEX_MAX + this.HEX_MAX + this.HEX_MAX;
    private LOW_CONTRAST_DELTA: number = 0.9;
    private mobileDetect: MobileDetect = new MobileDetect(navigator.userAgent);

    public ngOnInit(): void {
        this.isMobileOrTablet = Boolean(this.mobileDetect.mobile() || this.mobileDetect.tablet());
        if (this.product instanceof Product) {
            this.productLink = this.product.productStateName;
        }
        if (!this.displayColors) {
            this.displayColors = this.product.colors;
        }
    }

    public isActiveColor(color: ProductColor): boolean {
        if (!this.activeColor) {
            return;
        }

        return this.activeColor.hex === color.hex && this.activeColor.name === color.name;
    }

    public selectVariant(productColor: ProductColor): void {
        // emit event
        this.variantChange.emit(productColor);
    }

    public labelFor(labelName: string): string {
        return `${this.product.slug}-${labelName}`.replace(/\s+/g, '');
    }

    public colorContrast(colorHex: string): boolean {
        if (colorHex) {
            const hexNumber: string = colorHex.replace('#', '');
            const contrastColorValue: number =
                parseInt(hexNumber.substr(0, this.HEX_LENGTH), this.HEX_BASE) +
                parseInt(hexNumber.substr(this.HEX_LENGTH, this.HEX_LENGTH), this.HEX_BASE) +
                parseInt(hexNumber.substr(this.HEX_LENGTH + this.HEX_LENGTH, this.HEX_LENGTH), this.HEX_BASE);

            return (contrastColorValue / this.COLOR_BASE_VALUE) > this.LOW_CONTRAST_DELTA;
        }

        return false;
    }

    public mouseEnter(): void {
        this.mouseOverColor.emit(true);
    }

    public mouseLeave(): void {
        this.mouseOverColor.emit(false);
    }
}
