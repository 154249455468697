import { Component, HostBinding, Injector, Input, OnInit } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';

import { SitecoreComponent } from 'components/shared/sitecore';
import { Cloudinary } from 'services/cloudinary';

@Component({
  selector: 'flexible-card',
  templateUrl: './flexible-card.component.html',
  styleUrls: [ './flexible-card.component.scss' ]
})
export class FlexibleCardComponent extends SitecoreComponent implements OnInit {
  @HostBinding('class.swiper-slide') public hostClass: boolean = true;

  @Input() public rendering: ComponentRendering;
  @Input() public isSmall: boolean;
  @Input() public isMedium: boolean;
  @Input() public isLarge: boolean;

  public image: MediaImageOptions;
  public pageData: MarketingCardPageData;

  constructor(injector: Injector) {
      super(injector);
  }

  public ngOnInit(): void {
      this.pageData = this.flattenFields<MarketingCardPageData>(this.rendering.fields);
      this.image = this.pageData.image && Cloudinary.generateMediaOptionsFromCms(this.pageData.image);
  }
}
