import { Component, Input } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { SearchWindowService } from 'services/search-window';

@Component({
  selector: 'app-nav-mainmenu',
  templateUrl: './nav-mainmenu.component.html',
  styleUrls: [ './nav-mainmenu.component.scss' ]
})
export class NavMainmenuComponent {
  @Input() public rendering: ComponentRendering;
  public sideMenuVisible: boolean = false;
  public sideFirstSubMenuVisible: boolean = false;
  public sideSecondSubMenuVisible: boolean = false;
  public sideThirdSubMenuVisible: boolean = false;
  public showSearch: boolean = false;
  public currentChildIndex: number = 0;
  public currentSubChildIndex: number = 0;

  public searchWindowService: SearchWindowService;

  constructor(searchWindowService: SearchWindowService) {
    Object.assign(this, { searchWindowService });
  }

  public toggleSideMenu(): void {
    this.sideMenuVisible = !this.sideMenuVisible;
  }

  public closeHamburgerMenu(): void {
    this.sideFirstSubMenuVisible = false;
    this.sideSecondSubMenuVisible = false;
    this.sideThirdSubMenuVisible = false;
  }

  public toggleFirstSideSubMenu(): void {
    this.sideFirstSubMenuVisible = !this.sideFirstSubMenuVisible;
  }

  public toggleSecondSideSubMenu(currentIndex: number): void {
    this.currentChildIndex = currentIndex;
    this.sideSecondSubMenuVisible = !this.sideSecondSubMenuVisible;
  }

  public toggleThirdSideSubMenu(currentIndex: number): void {
    this.currentSubChildIndex = currentIndex;
    this.sideThirdSubMenuVisible = !this.sideThirdSubMenuVisible;
  }
}
