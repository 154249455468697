<section #parentRef class="{{theme}} {{ alignment }} {{ positionSection }} {{ sectionContainer }}" [ngStyle]="{ backgroundColor: pageNotFoundWrapper? '': pageData.backgroundColor }" [ngClass]="{ 'page-not-found': pageNotFoundWrapper }">
    <div class="wrapper {{pageData.class}} {{ sectionContainer }}" [ngClass]="{ 'border-style': pageData.showBorder, 'wrapper-page-not-found': pageNotFoundWrapper, 'pagenotfound-border-style': pageNotFoundWrapper }">
        <xm-media-image class="icon" *ngIf="breakpointImages" [imageOptions]="breakpointImages"></xm-media-image>        
        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.title)">
            <div class="title text-spacing" *scRichText="rendering.fields.title" [ngStyle]="{ color: pageData.titleColor }"></div>
        </ng-container>
        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.header)">
            <div class="header text-spacing" *scRichText="rendering.fields.header" [ngStyle]="{ color: pageData.headerColor }"></div>
        </ng-container>
        <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.largeBody)" class="large-body text-spacing {{ lbTextContainer }}" [field]="rendering.fields.largeBody" [ngStyle]="{ color: pageData.largeBodyColor }"></xm-cms-text>
        <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.mediumPlusBody)" class="medium-plus-body text-spacing" [field]="rendering.fields.mediumPlusBody"></xm-cms-text>
        <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.mediumBody)" class="medium-body text-spacing" [field]="rendering.fields.mediumBody" [ngStyle]="{ color: pageData.mediumBodyColor }"></xm-cms-text>
        <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.smallBody)" class="small-body text-spacing {{ sbTextContainer }}" [field]="rendering.fields.smallBody" [ngStyle]="{ color: pageData.smallBodyColor }"></xm-cms-text>
        <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.disclaimerText) && savingsCalculator" class="disclaimer-spacing" [field]="rendering.fields.disclaimerText"></xm-cms-text>
        <xm-popover
            *ngIf="rendering.fields.contentPopover"
            class="popover-button"
            [rendering]="rendering.fields.contentPopover"
            [parentTemplate]="parentRef"
            position="center">
        </xm-popover>
        <div class="actions button-spacing" [ngClass]="{ 'action-page-not-found': pageNotFoundWrapper }"  *ngIf="hasActions">
            <xm-generic-link *ngIf="hasFieldOrEditable(rendering.fields.linkDecorationOne)" class="cta" [field]="rendering.fields.linkDecorationOne" [icon]="linkDecorationOneIcon" [theme]="theme"></xm-generic-link>
            <xm-generic-link *ngIf="hasFieldOrEditable(rendering.fields.linkDecorationTwo)" class="cta" [field]="rendering.fields.linkDecorationTwo" [theme]="theme"></xm-generic-link>
            <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.ctaOne)" class="cta" [field]="rendering.fields.ctaOne"></xm-cms-text>
            <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.ctaTwo)" class="cta" [field]="rendering.fields.ctaTwo"></xm-cms-text>
            <xm-popover
                *ngIf="rendering.fields.popover"
                class="popover-button"
                [rendering]="rendering.fields.popover"
                [parentTemplate]="parentRef"
                position="center">
            </xm-popover>
        </div>
        <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.disclaimerText) && !savingsCalculator" class="disclaimer-text" [field]="rendering.fields.disclaimerText"></xm-cms-text>
    </div>
</section>
