import { Component, Injector, Input } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { SitecoreComponent } from 'components/shared/sitecore';

@Component({
    selector: 'marketing-empty',
    styleUrls: [ './empty.scss' ],
    templateUrl: './empty.html'
})
export class MarketingEmptyComponent extends SitecoreComponent {
    @Input() public rendering: ComponentRendering;

    public showEmpty: boolean;

    constructor(injector: Injector) {
        super(injector);
    }

    public ngOnInit(): void {
        this.showEmpty = this.inEditMode;
    }
}
