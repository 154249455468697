import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';

import { SitecoreComponent } from 'components/shared/sitecore';
import { Cloudinary } from 'services/cloudinary';

@Component({
    selector: 'plan-carousel',
    styleUrls: [ './plan-carousel.scss' ],
    templateUrl: './plan-carousel.html'
})
export class PlanCarouselComponent extends SitecoreComponent implements OnInit {
    @Input() public rendering: ComponentRendering;

    @ViewChild(SwiperDirective) public swiper: SwiperDirective;

    public carouselSettings: SwiperConfigInterface = {
        spaceBetween: 8,
        slidesPerView: 'auto',
        centeredSlides: true,
        allowTouchMove: true,
        navigation: {
            prevEl: '.swiper-navigation-prev',
            nextEl: '.swiper-navigation-next'
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            type: 'bullets',
            bulletElement: 'button'
        },
        breakpoints: {
            768: {
                spaceBetween: 0,
                centeredSlides: false,
                allowTouchMove: true
            },
            960 : {
                allowTouchMove: false
            }
        }
    };

    public pageData: MarketingPlanCarouselPageData;
    public backgroundImages: MarketingPlanVariantPageData[];
    public xmLogo: MediaImageOptions;
    public leftArrow: MediaImageOptions;
    public rightArrow: MediaImageOptions;
    public activeSlideIndex: number = 0;

    constructor(injector: Injector) {
        super(injector);
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<MarketingPlanCarouselPageData>(this.rendering.fields);

        if (this.pageData.xmLogo) {
            this.xmLogo = Cloudinary.generateMediaOptionsFromCms(this.pageData.xmLogo);
        }

        if (this.pageData.leftArrow) {
            this.leftArrow = Cloudinary.generateMediaOptionsFromCms(this.pageData.leftArrow);
        }

        if (this.pageData.rightArrow) {
            this.rightArrow = Cloudinary.generateMediaOptionsFromCms(this.pageData.rightArrow);
        }

        if (this.hasPlaceholder(this.rendering, 'planCarouselVariant')) {
            this.backgroundImages = this.rendering.placeholders.planCarouselVariant.filter((data: ComponentRendering) => data.componentName).map((planVariant: ComponentRendering) => {
                const variant: MarketingPlanVariantPageData = this.flattenFields<MarketingPlanVariantPageData>(planVariant.fields);
                variant.mediaImage = Cloudinary.generateMediaOptionsFromCms(variant.backgroundImage);

                return variant;
            });
        }
    }

    public placeholderLoaded(): void {
        if (this.swiper) {
            this.swiper.update();
        }
    }

    public onIndexChange(index: number): void {
        this.activeSlideIndex = index;
    }
}
