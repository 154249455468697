<section>
    <a class="policy" [ngClass]="{ 'show-border': pageData.showBorder }" role="link" [attr.aria-label]="pageData.header" [href]="pageData.url">
        <div class="policy-text">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.header)">
                <h3 class="header" *scRichText="rendering.fields.header"></h3>
            </ng-container>
            <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.body)" class="body" [field]="rendering.fields.body"></xm-cms-text>
        </div>
        <div class="arrow">
            <xm-media-image [imageOptions]="icon"></xm-media-image>
        </div>
    </a>
</section>
