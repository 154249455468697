<xm-modal-close (onClose)="close('close')"></xm-modal-close>

<section>
    <xm-media-image class="image" [imageOptions]="headerImage"></xm-media-image>

    <p class="title" *scRichText="rendering.fields.title"></p>
    <h2 class="header" *scRichText="rendering.fields.header"></h2>

    <div class="large-breakpoint">
        <div class="swiper-body">
            <span class="swiper-navigation-prev">
                <xm-media-image [imageOptions]="leftArrowIcon" size="height" class="arrow"></xm-media-image>
            </span>

            <div class="swiper-container" [swiper]="carouselConfig" [a11yLabel]="pageData.header">
                <ng-container *ngIf="hasPlaceholder(rendering, 'unlPricingGroups')">
                    <div class="swiper-wrapper" sc-placeholder name="unlPricingGroups" [rendering]="rendering" (loaded)="placeholderLoaded()"></div>
                </ng-container>
            </div>

            <span class="swiper-navigation-next">
                <xm-media-image [imageOptions]="rightArrowIcon" size="height" class="arrow"></xm-media-image>
            </span>
        </div>
        <div #pagination class="swiper-pagination"></div>
    </div>

    <div class="small-breakpoint">
        <div class="table-head">
            <div class="first-column" *scRichText="rendering.fields.tableHeaderFirstColumn"></div>
            <div class="second-column" *scRichText="rendering.fields.tableHeaderSecondColumn"></div>
            <div class="third-column" *scRichText="rendering.fields.tableHeaderThirdColumn"></div>
        </div>
        <ng-container *ngIf="hasPlaceholder(rendering, 'unlPricingGroups')">
            <div class="placeholders" sc-placeholder name="unlPricingGroups" [rendering]="rendering"></div>
        </ng-container>
    </div>
    <button  class="xm-btn-primary-light" (click)="close('primary')" *scRichText="rendering.fields.modalCta"></button>
    <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.bottomDescription)" class="bottom-description" [field]="rendering.fields.bottomDescription"></xm-cms-text>
</section>
