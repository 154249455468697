<section>
    <div class="chat-item">
        <button [attr.aria-label]="pageData.name" type="button" (click)="openChat()" (mouseenter)="mouseHoverEvent($event)" (mouseleave)="mouseHoverEvent($event)">
            <xm-media-image [imageOptions]="displayIcon" class="icon"></xm-media-image>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.name)">
                <span *scRichText="rendering.fields.name" class="chat-name"></span>
            </ng-container>
        </button>
    </div>
</section>
