<section #parentRef>
    <div class="promo-wrapper">
        <xm-media-image class="icon" *ngIf="breakpointImages" [imageOptions]="breakpointImages"></xm-media-image>
        <div class="promo-content">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.body)">
                <span class="body text-spacing" *scRichText="rendering.fields.body"></span>
            </ng-container>
            <xm-popover
                *ngIf="rendering.fields.popover"
                class="popover-button"
                [rendering]="rendering.fields.popover"
                [showByodPromoStyles]="true"
                [parentTemplate]="parentRef"
                position="center">
            </xm-popover>
        </div>
    </div>
</section>
