import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { StateService } from '@uirouter/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { Cloudinary } from 'core/services';
import { FormType } from 'core/constants';
import { FormUtil } from 'core/utils/form';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss';
import { SitecoreComponent } from 'components/shared/sitecore';

const FRAME_SRC: string = '//finder.wifi.xfinity.com?txtQuick=';

@Component({
    selector: 'network-map',
    styleUrls: [ './network-map.scss' ],
    templateUrl: './network-map.html'
})
export class NetworkMapComponent extends SitecoreComponent implements OnInit {
    @Input() public rendering: ComponentRendering;

    public pageData: CmsNetworkMap;
    public zipCode: string;
    public showIframe: boolean = true;
    public frameSrc: SafeUrl;
    public searchIcon: MediaImageOptions;
    public zipForm: UntypedFormGroup;
    public zipInputForm: UntypedFormControl;
    public zipInputOptions: XmInputOptions;
    public hasPromoBanner: boolean = false;
    public zipCodePlaceholder: string;

    private sanitizer: DomSanitizer;
    private state: StateService;
    private document: Document;

    constructor(injector: Injector, sanitizer: DomSanitizer, state: StateService, @Inject(DOCUMENT) document: Document) {
        super(injector);

        Object.assign(this, { sanitizer, state, document });
    }

    public ngOnInit(): void {
        this.hasPromoBanner = Boolean(this.document.getElementsByTagName('promo-banner')[0]);
        this.pageData = this.flattenFields<CmsNetworkMap>(this.rendering.fields);
        this.zipCode = this.state.params.zip;
        this.zipCodePlaceholder = this.pageData.zipPlaceholder || '';
        this.frameSrc = this.sanitizeUrl();

        if (this.pageData.zipcodeSearchIcon) {
            this.searchIcon = this.pageData.zipcodeSearchIcon && Cloudinary.generateMediaOptionsFromCms(this.pageData.zipcodeSearchIcon);
        }

        this.initForm();
    }

    public onSubmit(): void {
        FormUtil.submitForm(this.zipForm, this.zipCodeChange.bind(this));
    }

    public zipCodeListener(val: string): void {
        this.zipCode = val;
    }

    public zipCodeChange(): void {
        if (this.zipInputForm.valid) {
            this.showIframe = false;
            this.frameSrc = this.sanitizeUrl();
            this.state.go(this.state.current.name, { zip: this.zipCode }, { location:  'replace' });

            setTimeout(() => {
                this.showIframe = true;
            });
        }
    }

    private initForm(): void {
        const zipInputErrors: XmInputError[] = [{
            minLength: 5,
            code: 'Xm-Zip-Number-Digits',
            message: this.pageData.zipError
        }];

        this.zipInputForm = new UntypedFormControl(this.zipCode, { validators: FormUtil.validators(zipInputErrors), updateOn: FormType.blur });
        this.zipForm = new UntypedFormGroup({
            zip: this.zipInputForm
        });

        this.zipInputOptions = {
            template: '99999',
            inputId: 'zip',
            header: this.pageData.zipLabel,
            errors: zipInputErrors
        };
    }

    private sanitizeUrl(): SafeUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(FRAME_SRC + this.zipCode);
    }
}
