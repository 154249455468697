<nav class="component-nav-topmenu">
  <div class="component-nav-topmenu-div">
      <a *scLink="rendering.fields.xfinity" class="component-nav-topmenu-item">
      </a>
      <a *scLink="rendering.fields.smallBusiness" class="component-nav-topmenu-item" [ngClass]="{'active': true}">
      </a>
      <a *scLink="rendering.fields.enterprise" class="component-nav-topmenu-item">
      </a>
  </div>
</nav>

