<nav role="navigation" aria-label="Product Menu">
    <div class="links" hidden>
        <div class="left">
            <div *ngFor="let menuItem of rendering.fields.desktopMenuItems" class="desktop-menu-items">
                <xm-nav-menu-items class="xm-nav-menu-items-accss" [rendering]="menuItem" 
                [showChildren]="true" [caretIcon]="dropdownIcon" [caretRightIcon]="dropdownRightIcon" type="button" [expandMessge]="pageData.expandText"></xm-nav-menu-items>
            </div>
        </div>
        <div class="right">
            <div *ngFor="let menuItem of rendering.fields.desktopRightMenuItems" class="desktop-menu-items">
                <xm-nav-menu-items class="xm-nav-menu-items-accss" [rendering]="menuItem" 
                [showChildren]="true" [caretIcon]="dropdownIcon" type="button"></xm-nav-menu-items>
            </div>
        </div>
    </div>
</nav>
