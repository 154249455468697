<section *ngIf="pageData">
    <a class="card" [href]="productLink">
        <div class="preorder">
            <span *ngIf="product.isPreorder">{{ pageData.preorderText }}</span>
        </div>
        <div class="five-g">
            <span *ngIf="product.is5GCapable && pageData.label5g">{{ pageData.label5g }}</span>
        </div>
        <div class="imagery" aria-hidden="true">
            <xm-media-image [imageOptions]="imageOptions" center="auto" size="auto"></xm-media-image>
        </div>
        <div class="detail">
            <div class="brand">{{ product.brand }}</div>
            <div class="name"></div>
            <div class="price">
                <span *ngIf="!product.isAccessory">{{ pageData.financePriceText }}</span>
                <span class="discount" *ngIf="product.extendedPromo2">{{ product.price.promotionPrice | xmCurrency }}<span *ngIf="product.price.isFinanced" aria-hidden="true">{{ pageData.perMonth }}</span></span>
                <span *ngIf="hideStrikeThrough">{{ product.price.activePrice | xmCurrency }}<span *ngIf="product.price.isFinanced" aria-hidden="true">{{ pageData.perMonth }}</span></span>
                <span *ngIf="!hideStrikeThrough" [ngClass]="{ 'strike-through': product.extendedPromo2 }" [xmAriaHidden]="product.extendedPromo2">{{ product.price.activePrice | xmCurrency }}<span *ngIf="product.price.isFinanced" aria-hidden="true">{{ pageData.perMonth }}</span></span>
                <div *ngIf="product.price.isFinanced"><span class="accessibility-hidden">{{ pageData.accessibiltyText }}</span>{{ downPaymentText }}</div>
                <span class="accessibility-hidden" *ngIf="product.extendedPromo2">, {{ pageData.promoAriaText }} {{ product.price.originalPrice | xmCurrency }} {{ pageData.accessibiltyText }}</span>
                <div class="financing" *ngIf="product.price.isFinanced">{{ pageData.financeTermText }}</div>
                <div class="retail-price" *ngIf="product.retailPrice && !product.isAccessory"> {{ pageData.retailPriceText }} {{ product.retailPrice.originalPrice | xmCurrency }}</div>
            </div>
            <div class="promos" *ngIf="promoLabel">
                <div class="promo">
                    <xm-media-image class="promo-icon" [imageOptions]="promoIcon" size="width" center="vert"></xm-media-image>
                    <span class="promo-text">{{ promoLabel }}</span>
                </div>
            </div>
        </div>
    </a>
    <xm-color-picker class="color-picker" *ngIf="product.colors && product.colors.length" [product]="product" [activeColor]="activeColor" [asButtons]="true"></xm-color-picker>
</section>
