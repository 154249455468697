import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { UIRouter } from '@uirouter/core';
import { MARKETING_PARAMS } from 'core/constants';
import { Util } from 'core/utils/util';

@Injectable({
    providedIn: 'root'
})
export class Seo {
    private titleTag: HTMLElement;
    private canonicalTag: HTMLElement;
    private iconTag: HTMLElement;
    private meta: Meta;
    private title: Title;
    private doc: Document;
    private router: UIRouter;

    private defaults: object = {
        title: 'Comcast Business Mobile | Wireless for Small Businesses',
        robots: 'index, follow',
        'og-type': 'website',
        'og-site_name': 'Comcast Business Mobile',
        'og-image': 'https://cbm-buy-static-prod.digital.business.comcast.com/images/cbm-logo-default.png',
        'og-title': 'Comcast Business Mobile | Wireless for Small Businesses',
        'og-url': 'https://business.comcast.com/learn/mobile',
        'og-description': 'Comcast Business Mobile is designed for business with flexible data options, nationwide 5G coverage, and Unlimited Data for $30 per line, per month.',
        'twitter-card': 'summary',
        'twitter-site': '@comcastbusiness',
        'twitter-image': 'https://cbm-buy-static-prod.digital.business.comcast.com/images/cbm-logo-default.png',
        'twitter-title': 'Comcast Business Mobile | Wireless for Small Businesses',
        'twitter-description': 'Comcast Business Mobile is designed for business with flexible data options, nationwide 5G coverage, and Unlimited Data for $30 per line, per month.',
        'twitter-url': 'https://business.comcast.com/learn/mobile'
    };

    constructor(meta: Meta, title: Title, @Inject(DOCUMENT) doc: Document, router: UIRouter) {
        Object.assign(this, { meta, title, doc, router });
    }

    /**
     * Using Prerender.io as our static html provider, we need to set a meta tag to inform when a
     * route cannot be found by our router. This will enable Prerender to send a real 404 status code
     * to web crawlers.
     */
    public addPrerender404Tag(): void {
        this.meta.updateTag({ name: 'prerender-status-code', content: '404' });
    }

    /**
     * Using Prerender.io as our static html provider, we need to set a meta tag to inform when a
     * route has been permanently redirected. This will enable Prerender to send a real 301 status code
     * and the new location to web crawlers.
     */
    public addPrerender301Tag(newLocation: string): void {
        this.meta.updateTag({ name: 'prerender-header', content: `Location: ${newLocation}` });
        this.meta.updateTag({ name: 'prerender-status-code', content: '301' });
    }

    public updateMetaTags(metaData: MetaDataDefault = {}): void {
        const { canonicalAllow, canonicalBlock, ...metaTags }: {
            canonicalAllow?: string[];
            canonicalBlock?: string[];
        } = metaData;

        const url: string = this.doc.URL;
        const computedTags: object = {
            canonical: this.generateCanonical(canonicalAllow, canonicalBlock),
            url,
            'og-url': url,
            'twitter-url': url
        };

        this.processMetaTags(Util.mergeDeep(this.defaults, computedTags, metaTags));
    }

    /**
     * By default it allows all params to be used in the canonical except Marketing Params
     *
     * Using the canonicalAllow/canonicalBlock you can control the params
     */
    private generateCanonical(canonicalAllow: string[] = [], canonicalBlock: string[] = []): string {
        // marketing params do not make a page unique and therefore should always be excluded from the canonical url
        const alwaysBlock: string[] = MARKETING_PARAMS;
        const currentParams: object = this.router.globals.params;

        const normalizedParams: UrlQueries = canonicalAllow.length ?
            canonicalAllow
                .filter((paramKey: string) => !alwaysBlock.includes(paramKey)) // exclude keys that should ALWAYS be blocked, even if 'canonicalAllow' asked for it
                .reduce((newParams: { [key: string]: string }, paramKey: string) => { // return new object with allowed key/value router params
                    newParams[paramKey] = currentParams[paramKey];

                    return newParams;
                }, {})
            : Object.entries(currentParams)
                .filter(([ paramKey, _ ]: [ string, unknown ]) => ![ ...canonicalBlock, ...alwaysBlock ].includes(paramKey)) // exclude keys that are blocked
                .reduce((newParams: { [key: string]: string }, [ paramKey, value ]: [ string, string ]) => { // return new object with allowed key/value router params
                    newParams[paramKey] = value;

                    return newParams;
                }, {});

        // return absolute URL to be set as 'canonical' meta tag
        return this.router.stateService.href(this.router.globals.current.name, normalizedParams, { absolute: true, inherit: false });
    }

    private processMetaTags(metaData: MetaDataDefault): void {
        Object.keys(metaData).forEach((key: string) => {
            const type: string = typeof metaData[key];
            if (type === 'string') {
                const convertedKey: string = key.replace('-', ':');

                if (key === 'title') {
                    // create an element to get HTML Entities to load correctly
                    if (!this.titleTag) {
                        this.titleTag = this.doc.createElement('span');
                    }

                    this.titleTag.innerHTML = metaData[key];
                    this.title.setTitle(this.titleTag.innerText);
                } else if (key.startsWith('og')) {
                    this.meta.updateTag({ property: convertedKey, content: metaData[key] });
                } else if (key === 'canonical') {
                    if (!this.canonicalTag) {
                        this.canonicalTag = this.doc.createElement('link');
                        this.canonicalTag.setAttribute('rel', 'canonical');

                        this.doc.head.appendChild(this.canonicalTag);
                    }

                    this.canonicalTag.setAttribute('href', metaData[key]);
                } else if (key === 'icon') {
                    if (!this.iconTag) {
                        this.iconTag = this.doc.createElement('link');
                        this.iconTag.setAttribute('rel', 'icon');

                        this.doc.head.appendChild(this.iconTag);
                    }

                    this.iconTag.setAttribute('href', metaData[key]);
                } else {
                    this.meta.updateTag({ name: convertedKey, content: metaData[key] });
                }
            }
        });
    }
}
