<div class="healthcheck">
    <h1 class="header">Health Check</h1>
    <table class="table">
        <tbody class="body">           
            <tr >
                <td class="label">Visitor Session Id: </td>
                <td>{{visitorSessionId}}</td>
            </tr>
            <tr>
                <td class="label">Release: </td>
                <td></td>
            </tr>
            <tr>
                <td class="label">Application ID: </td>
                <td></td>
            </tr>
            <tr>
                <td class="label">HTTP_COOKIE: </td>
                <td>
                    <table class="table">
                        <tbody> 
                            <tr *ngFor="let cookie of cookieList; let i = index">
                                <td *ngFor="let item of cookie">
                                  {{ item }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>           
        </tbody>
    </table>
    </div>
    