import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiChannel } from 'core/constants';
import { XmHttp } from 'services/http/http';
import { XmStore } from 'services/store';
import { Catalog } from 'core/store/catalog/model/catalog';
import { ModelBase } from '../model-base';
import { StoreAction } from 'core/store-actions';

@Injectable()
export class CatalogApi {
    public static getCatalog(_xmStore: XmStore, xmHttp: XmHttp, param: StoreFilters): Observable<Catalog> {
        let paramString: string = `?category=${param.category}&limit=${param.limit}`;

        if (param.tps) {
            paramString += `&tps=${param.tps}`;
        }

        return xmHttp.get(ApiChannel.PCAT, `products${paramString}`).pipe(
            map((response: Catalog) => Catalog.create(response))
        );
    }
}

ModelBase.fetchMapping[StoreAction.GET_CATALOG] = CatalogApi.getCatalog;
