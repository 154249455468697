import { ModelBase } from 'core/store/model-base';

export class AccountInfo extends ModelBase {
    public static storeName: string = 'account';

    public firstName: string;
    public lastName: string;
    public displayName: string;

    public static create<T extends ModelBase>(initData?: any): T {
        initData.displayName = initData.firstName && initData.lastName && `${initData.firstName} ${initData.lastName}`;

        return initData;
    }
}
