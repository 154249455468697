import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { Util } from 'core/utils/util';
import { BreakpointWidth } from 'core/constants';

@Injectable({
    providedIn: 'root'
})
export class WindowReference implements OnDestroy {
    private static RESIZE_DEBOUNCE_TIME: number = 300;
    private static SCROLL_DEBOUNCE_TIME: number = 200;
    private static watchingBreakpoint: Breakpoint = {
        currentBreakpoint: '',
        isXlarge: false,
        isSmall: false,
        isLarge: false,
        isMedium: false,
        currentBreakpointMax: 9999,
        currentBreakpointMin: 0
    };

    public breakpoint: Observable<Breakpoint>;
    public windowWidth: Observable<number>;
    public scroll: Observable<Event>;
    public scrollDebounce: Observable<Event>;
    private subscriptions: Subscription[] = [];

    public static replaceCurrentHistory(url: string): void {
        window.history.replaceState(undefined, undefined, url);
    }

    // this can be replace with PlatFormID
    public static get isWindowAvailable(): boolean {
        return typeof window !== 'undefined';
    }

    constructor() {
        const windowRect: BehaviorSubject<Breakpoint> = new BehaviorSubject(this.getBreakpoint());
        this.breakpoint = windowRect.pipe(distinctUntilChanged());

        // hack solution...idk how to fix this
        if (WindowReference.isWindowAvailable) {
            this.subscriptions.push(fromEvent(window, 'resize').pipe(map(this.getBreakpoint)).subscribe(windowRect));
            this.scroll = fromEvent(window, 'scroll').pipe(map((event: Event) => event));

            this.windowWidth = fromEvent(window, 'resize').pipe(
                debounceTime(WindowReference.RESIZE_DEBOUNCE_TIME),
                distinctUntilChanged(),
                map((event: Event) => (<Window> event.target).innerWidth)
            );

            this.scrollDebounce = fromEvent(window, 'scroll').pipe(
                debounceTime(WindowReference.SCROLL_DEBOUNCE_TIME),
                distinctUntilChanged(),
                map((event: Event) => event)
            );
        }
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    public reload(): void {
        window.location.reload();
    }

    public getBreakpoint(): Breakpoint {
        if (!WindowReference.isWindowAvailable || window.innerWidth < BreakpointWidth.MEDIUM &&
            !WindowReference.watchingBreakpoint.isSmall) {
            WindowReference.watchingBreakpoint = { ...WindowReference.defaultBreakpoint, isSmall: true, currentBreakpoint: 'small',
                currentBreakpointMax: BreakpointWidth.MEDIUM - 1, currentBreakpointMin: 0 };
        } else if (window.innerWidth >= BreakpointWidth.MEDIUM &&
                    window.innerWidth < BreakpointWidth.LARGE &&
                    !WindowReference.watchingBreakpoint.isMedium) {
            WindowReference.watchingBreakpoint = { ...WindowReference.defaultBreakpoint, isMedium: true, currentBreakpoint: 'medium',
                currentBreakpointMax: BreakpointWidth.LARGE - 1, currentBreakpointMin: BreakpointWidth.MEDIUM };
        } else if (window.innerWidth >= BreakpointWidth.LARGE &&
                    window.innerWidth < BreakpointWidth.XLARGE &&
                    !WindowReference.watchingBreakpoint.isLarge) {
            WindowReference.watchingBreakpoint = {...WindowReference.defaultBreakpoint, isLarge: true, currentBreakpoint: 'large',
                currentBreakpointMax: BreakpointWidth.XLARGE - 1, currentBreakpointMin: BreakpointWidth.LARGE };
        } else if (window.innerWidth >= BreakpointWidth.XLARGE &&
                    !WindowReference.watchingBreakpoint.isXlarge) {
            WindowReference.watchingBreakpoint = { ...WindowReference.defaultBreakpoint, isXlarge: true, currentBreakpoint: 'xlarge',
                currentBreakpointMax: 9999, currentBreakpointMin: BreakpointWidth.XLARGE };
        }

        return WindowReference.watchingBreakpoint;
    }

    private static get defaultBreakpoint(): Breakpoint {
        return {
            currentBreakpoint: '',
            isXlarge: false,
            isSmall: false,
            isLarge: false,
            isMedium: false,
            currentBreakpointMax: 9999,
            currentBreakpointMin: 0
        };
    }
}
