<section>
    <div class="coverage-container">
        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.header)">
            <p class="header" *scRichText="rendering.fields.header"></p>
        </ng-container>
        <div class="map-container">
            <xm-media-image *ngIf="mapImage" [imageOptions]="mapImage"></xm-media-image>
        </div>    
        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.subHeader)">
            <p class="sub-header" *scRichText="rendering.fields.subHeader"></p>
        </ng-container>            
        <div class="image-container">
            <div class="image-wrapper">
                <div class="circle"></div>
                <div [@toGrow]="formSubmitted && coverage.serviceAvailable()" class="anim-circle"></div>
                <xm-media-image *ngIf="image" class="hotspot-icon" [imageOptions]="image"></xm-media-image>
            </div>
        </div>
        <div class="right">
            <div class="form-container">
                <ng-container *ngIf="hasFieldOrEditable(rendering.fields.title)">
                    <p class="title mobile" *scRichText="rendering.fields.title"></p>
                </ng-container>
                <ng-container *ngIf="hasFieldOrEditable(rendering.fields.title)">
                    <p class="title desktop" *scRichText="rendering.fields.title"></p>
                </ng-container>
                <div *ngIf="inEditModeOr(!formSubmitted)">
                    <form [formGroup]="zipFormGroup" class="zip-form">
                        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.zipcodeLabel)">
                            <label for="zip" class="zip-label" *scRichText="rendering.fields.zipcodeLabel" [attr.aria-label]="rendering.fields.zipcodeLabel"></label>
                        </ng-container>
                        <input xmMask class="zip-input" type="tel" id="zip" formControlName="zip" template="99999" [ngClass]="{ 'input-error': zipFormControl.errors && zipFormControl.dirty && zipFormControl.touched }" [placeholder]="zipCodeText" tabindex="0" [attr.aria-label]="zipCodeAriaText"/>
                        <ng-container *ngIf="inEditModeOr(zipFormControl.errors && zipFormControl.dirty && zipFormControl.touched)">
                            <div role="alert">
                                <p class="error" *scRichText="rendering.fields.zipcodeErrorMsg"></p>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="inEditModeOr(formSubmitted) && inEditModeOr(!coverage.serviceAvailable())">
                            <div role="alert">
                                <p class="error" *scRichText="rendering.fields.serviceUnavailableBody"></p>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.zipcodeSubmitButton)">
                            <button class="xm-btn-primary-light promise-btn submit" (click)="submit()" [promiseBtn]="coverageSubscription" tabindex="0">
                                <span *scRichText="rendering.fields.zipcodeSubmitButton"></span>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="inEditModeOr(apiError)">
                            <div role="alert">
                                <p class="error" *scRichText="rendering.fields.zipcodeApiFailureMsg"></p>
                            </div>
                        </ng-container>
                    </form>
                </div>
                <div *ngIf="inEditModeOr(formSubmitted) && inEditModeOr(!coverage.serviceAvailable())">
                    <form [formGroup]="zipFormGroup" class="zip-form">
                        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.zipcodeLabel)">
                            <label for="zip" class="zip-label" *scRichText="rendering.fields.zipcodeLabel" [attr.aria-label]="rendering.fields.zipcodeLabel"></label>
                        </ng-container>
                        <input xmMask class="zip-input" type="tel" id="zip" formControlName="zip" template="99999" [ngClass]="{ 'input-error': (zipFormControl.errors && zipFormControl.dirty && zipFormControl.touched) || inEditModeOr(!coverage.serviceAvailable()) }" [placeholder]="zipCodeText" tabindex="0" [attr.aria-label]="zipCodeAriaText"/>
                        <ng-container *ngIf="inEditModeOr(zipFormControl.errors && zipFormControl.dirty && zipFormControl.touched)">
                            <div role="alert">
                                <p class="error" *scRichText="rendering.fields.zipcodeErrorMsg"></p>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!inEditModeOr(zipFormControl.errors && zipFormControl.dirty && zipFormControl.touched) && inEditModeOr(!coverage.serviceAvailable())">
                            <div role="alert">
                                <p class="error" *scRichText="rendering.fields.serviceUnavailableBody"></p>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.zipcodeSubmitButton)">
                            <button class="xm-btn-primary-light promise-btn submit" (click)="submit()" [promiseBtn]="coverageSubscription" tabindex="0">
                                <span *scRichText="rendering.fields.zipcodeSubmitButton"></span>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="inEditModeOr(apiError)">
                            <div role="alert">
                                <p class="error" *scRichText="rendering.fields.zipcodeApiFailureMsg"></p>
                            </div>
                        </ng-container>
                    </form>
                </div>
            </div>
            <div *ngIf="inEditModeOr(formSubmitted)">
                <ng-container *ngIf="inEditModeOr(coverage.serviceAvailable())">
                    <p class="availability-text" [attr.aria-label]="ariaAvailableMsg" *scRichText="rendering.fields.serviceAvailableBody"></p>
                </ng-container>              
                <div class="results-container" *ngIf="inEditModeOr(coverage.serviceAvailable())">
                    <div class="results" *ngIf="inEditModeOr(coverage.fivegAvailable)">
                        <xm-media-image [imageOptions]="resultsIcon" class="results-icon"></xm-media-image>
                        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.fiveGBody)">
                            <p class="result-text" *scRichText="rendering.fields.fiveGBody"></p>
                        </ng-container>
                    </div>
                    <div class="results" *ngIf="inEditModeOr(coverage.lteAvailable)">
                        <xm-media-image [imageOptions]="resultsIcon" class="results-icon"></xm-media-image>
                        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.lteBody)">
                            <p class="result-text" *scRichText="rendering.fields.lteBody"></p>
                        </ng-container>
                    </div>
                    <div class="results" *ngIf="inEditModeOr(coverage.hotspotAvailable())">
                        <xm-media-image [imageOptions]="resultsIcon" class="results-icon"></xm-media-image>
                        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.wifiBody)">
                            <p class="result-text" *scRichText="rendering.fields.wifiBody"></p>
                        </ng-container>
                    </div>
                </div>
                <div class="hostsoptcta">
                    <ng-container *ngIf="inEditModeOr(coverage.hotspotAvailable())">
                        <a rel="noopener" target="_blank" class="xm-btn-primary-light results-cta" [href]="hotspotLink" *scRichText="rendering.fields.hotspotCta"></a>
                    </ng-container>
                    <ng-container *ngIf="inEditModeOr(!coverage.hotspotAvailable()) && hasFieldOrEditable(rendering.fields.checkAnotherButton) && inEditModeOr(coverage.serviceAvailable())">
                        <button class="xm-btn-primary-light check-another-cta" *scRichText="rendering.fields.checkAnotherButton" (click)="resetForm()"></button>
                    </ng-container>
                    <ng-container *ngIf="inEditModeOr(coverage.hotspotAvailable()) && hasFieldOrEditable(rendering.fields.checkAnotherButton) && inEditModeOr(coverage.serviceAvailable())">
                        <button class="xm-btn-link-light check-another-cta" *scRichText="rendering.fields.checkAnotherButton" (click)="resetForm()"></button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</section>
